import React from "react";

interface EditButtonProps {
  onClick: () => void;
  title?: string;
}

const EditButton: React.FC<EditButtonProps> = ({ onClick, title }) => (
  <div>
    <button
      className={`btn btn-sm ${
        title === "Add" ? "btn-success" : "btn-primary"
      }`}
      onClick={onClick}
    >
      {title ? title : "Edit"}
    </button>
  </div>
);

export default EditButton;
