import React from "react";
import { Modal, Table } from "react-bootstrap"; // Ensure you have Bootstrap installed
import CustomButton from "./Button/CustomButton";

// Define the interface for the props
interface MultipleArchiveModalProps {
  showModal: boolean;
  isArchive: boolean;
  setShowModal: (value: boolean) => void;
  selectedRows: {
    id: number;
    company_name: string;
    company_type: string;
    created_at: string;
    updated_at: string;
    email: string;
  }[];
  handleMultipleArchive: (
    rows: MultipleArchiveModalProps["selectedRows"],
  ) => void;
}

const MultipleArchiveModal: React.FC<MultipleArchiveModalProps> = ({
  showModal,
  isArchive,
  setShowModal,
  selectedRows,
  handleMultipleArchive,
}) => {
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
      <Modal.Header className="border-0">
        <h5>
          Are you sure you want to {isArchive ? "Restore" : "Archive"} these
          Buyers & Suppliers?
        </h5>
      </Modal.Header>
      <Modal.Body>
        <Table bordered hover>
          <thead className="text-center">
            <tr>
              <th>ID</th>
              <th>Company Name</th>
              <th>Type</th>
              <th>Created Date</th>
              <th>Updated Date</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {selectedRows.map((row) => (
              <tr key={row.id}>
                <td>{row.id}</td>
                <td>{row.company_name}</td>
                <td>{row.company_type}</td>
                <td>{row.created_at}</td>
                <td>{row.updated_at}</td>
                <td>{row.email}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <CustomButton
          label="Cancel"
          variant="secondary"
          onClick={() => setShowModal(false)}
          customeClass="mx-2"
        />
        <CustomButton
          label={isArchive ? "Restore" : "Archive"}
          variant={isArchive ? "success" : "danger"}
          onClick={() => handleMultipleArchive(selectedRows)}
          customeClass="mx-2"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default MultipleArchiveModal;
