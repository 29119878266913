import { RouteObject } from "react-router-dom";
import List from "../../Pages/SalesAndPurchase/TaxInvoice/List";
import AddUpdate from "../../Pages/SalesAndPurchase/TaxInvoice/AddUpdate";
import View from "../../Pages/SalesAndPurchase/TaxInvoice/View";
import {
  ADD_TAX_INVOICE,
  DELETE_TAX_INVOICE,
  UPDATE_TAX_INVOICE,
  VIEW_SALES_RETURN,
  VIEW_TAX_INVOICE,
} from "../../Util/PermissionList";
import AuthorizedRoute from "../../Util/AuthorizedRoute";

export const TAX_INVOICE_TITLE = "Tax Invoice";
export const TAX_INVOICE_LIST = "/tax-invoice";
export const TAX_INVOICE_DASHBOARD_LIST = "/tax-invoice?isfordashboard";
export const TAX_INVOICE_VIEW = "/tax-invoice/:id/show";
export const TAX_INVOICE_ADD = "/tax-invoice/create-invoice";
export const TAX_INVOICE_EDIT = "/tax-invoice/:updateid/edit-invoice";

const TaxInvoice: RouteObject[] = [
  {
    path: TAX_INVOICE_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_TAX_INVOICE}
        exception={true}
        checkPermisson={{
          addPermission: ADD_TAX_INVOICE,
          updatePermission: UPDATE_TAX_INVOICE,
          deletePermission: DELETE_TAX_INVOICE,
          viewPermission: VIEW_TAX_INVOICE,
          VIEW_SALES_RETURN,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: TAX_INVOICE_DASHBOARD_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_TAX_INVOICE}
        exception={true}
        checkPermisson={{
          addPermission: ADD_TAX_INVOICE,
          updatePermission: UPDATE_TAX_INVOICE,
          deletePermission: DELETE_TAX_INVOICE,
          viewPermission: VIEW_TAX_INVOICE,
          VIEW_SALES_RETURN,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: TAX_INVOICE_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={ADD_TAX_INVOICE}
        checkPermisson={{
          addPermission: ADD_TAX_INVOICE,
          updatePermission: UPDATE_TAX_INVOICE,
          deletePermission: DELETE_TAX_INVOICE,
          viewPermission: VIEW_TAX_INVOICE,
        }}
      />
    ),
  },
  {
    path: TAX_INVOICE_VIEW,
    element: (
      <AuthorizedRoute
        element={<View />}
        permission={VIEW_TAX_INVOICE}
        checkPermisson={{
          addPermission: ADD_TAX_INVOICE,
          updatePermission: UPDATE_TAX_INVOICE,
          deletePermission: DELETE_TAX_INVOICE,
          viewPermission: VIEW_TAX_INVOICE,
        }}
      />
    ),
  },
  {
    path: TAX_INVOICE_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={ADD_TAX_INVOICE}
        checkPermisson={{
          addPermission: ADD_TAX_INVOICE,
          updatePermission: UPDATE_TAX_INVOICE,
          deletePermission: DELETE_TAX_INVOICE,
          viewPermission: VIEW_TAX_INVOICE,
        }}
      />
    ),
  },
];

export default TaxInvoice;
