import React, { useEffect, useRef, useState } from "react";
import { setToast } from "../Util/Toast";
import { Form } from "react-bootstrap";
import CustomEditButton from "./Button/CustomEditButton";
import { Trash3, Download } from "react-bootstrap-icons";
import ConfirmationModal from "./ConfirmationModel";
import { Signature } from "../Model/Setting/Signature";
import CustomViewButton from "./Button/CustomViewButton";

function SignatureForDocuments({
  fieldType,
  moduleType,
  id,
  customSubmit,
  setSelectedSignatureFile,
  attachmentSignatureError,
  setIsSignatureExist,
}: any) {
  const fileInputRef: any = useRef(null); // Create a ref for the file input element
  const [selectedFile, setSelectedFile] = useState<any>("");
  const [signatureData, setSignatureData] = useState<any>([]);
  const [showConfirmation, setShowConfirmation] = useState<any>(false);
  const [isDisabled, setIsDisabled] = useState<any>(false);
  const [actionId, setActionId] = useState<any>("");
  const [showFullScreen, setShowFullScreen] = useState<boolean>(false);
  const [clickedImage, setClickedImage] = useState<string>("");
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status

  useEffect(() => {
    if (customSubmit) {
      saveSignature();
    }
    // eslint-disable-next-line
  }, [customSubmit, attachmentSignatureError]);

  useEffect(() => {
    if (id) {
      getSignatureData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const handleImageClick = (imageUrl: string) => {
    setClickedImage(imageUrl);
    setShowFullScreen(true);
  };
  const getSignatureData = async (itemId: string) => {
    try {
      const response: any = await Signature.$query()
        .getHttpClient()
        .get(`/search?field=${fieldType}&operator==&value=${itemId}`);
      setSignatureData(response.data.data);
      if (response.data.data.length > 0) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
      if (response && response.data?.data?.length > 0) {
        setIsSignatureExist(true);
      }
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };
  const handleFileChange = (event: any) => {
    const selectedFile: any = event.target.files[0];

    // Check if the file is an image
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      const img = new Image();
      img.onload = () => {
        // Check image dimensions
        if (img.width > 300 || img.height > 150) {
          setToast("error", "Image dimensions should not exceed 300x150 px.");
          // Reset the file input and state
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          setSelectedFile("");
          setSelectedSignatureFile(null);
        } else {
          // Proceed with file selection
          setSelectedFile(selectedFile);
          setSelectedSignatureFile(selectedFile);
        }
      };
      img.src = URL.createObjectURL(selectedFile);
    } else {
      setToast("error", "Please select a valid image file.");
    }
  };

  const saveSignature = async (e?: React.FormEvent) => {
    try {
      e?.preventDefault();
      if (selectedFile && fieldType && id && moduleType) {
        setCustomSubmitting(true);
        const formData: any = new FormData();
        selectedFile && formData.append("file_path", selectedFile);
        formData.append(fieldType, id);
        formData.append("type", moduleType);

        if (actionId) {
          await Signature.$query()
            .getHttpClient()
            .post("/" + actionId, formData);
          getSignatureData(id);

          setActionId("");
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        } else {
          await Signature.$query().getHttpClient().post("/", formData);
          getSignatureData(id);
          setActionId("");
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  function handleDelete(index: any) {
    setActionId(index);
    setShowConfirmation(true);
  }
  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const handleConfirmDelete = async () => {
    try {
      setCustomSubmitting(true);
      await Signature.$query()
        .getHttpClient()
        .delete("/" + actionId);
      getSignatureData(id);
      setToast("success", `Signature Successfully Deleted`);
      setActionId("");
      setShowConfirmation(false);
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };
  const handleEditSignatureButtonClick = () => {
    const formBottom = document.getElementById("signature");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleDownload = async (filePath: string) => {
    try {
      const response = await fetch(`${filePath}?${Date.now()}`);
      const blob = await response.blob();
      const fileName = filePath.substring(filePath.lastIndexOf("/") + 1);

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.target = "_blank";
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };

  const FullScreenImage = () => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <button
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
          color: "#fff",
          fontSize: "18px",
        }}
        onClick={() => setShowFullScreen(false)}
      >
        &#x2715;
      </button>
      <img
        src={clickedImage}
        alt="Signature"
        style={{ maxWidth: "90%", maxHeight: "90%" }}
      />
    </div>
  );

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div>
        <Form encType="multipart/form-data">
          <Form.Label className="mt-2">
            {isDisabled || !id || signatureData.length === 0
              ? "Choose file to import:*"
              : "Choose file for update:*"}
          </Form.Label>
          <Form.Control
            id="signature"
            type="file"
            onChange={handleFileChange}
            ref={fileInputRef}
            disabled={isDisabled}
            accept="image/*" // Accept only image files
          />
          {selectedFile && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Selected Signature"
                style={{
                  maxWidth: "100%",
                  maxHeight: "200px",
                  marginTop: "12px",
                }}
              />
            </div>
          )}
        </Form>
        {attachmentSignatureError && (
          <div
            className="error-message text-danger"
            style={{ fontSize: "small" }}
          >
            {attachmentSignatureError}
          </div>
        )}

        {id && (!signatureData || signatureData.length === 0) && (
          <div
            style={{ textAlign: "center", color: "gray", marginTop: "20px" }}
          >
            No signature available
          </div>
        )}
        {signatureData &&
          signatureData.map((data: any) => (
            <span key={data.id}>
              <div
                className="d-flex flex-wrap align-items-center justify-content-between mb-0"
                style={{
                  borderRadius: "5px",
                  backgroundColor: "rgb(255, 255, 255)",
                  margin: "0.8rem 0",
                  padding: "10px",
                  boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div
                  className="text-center"
                  style={{ flexBasis: "13%", flexShrink: 0 }}
                >
                  <img
                    src={`${data?.file_path}?${Date.now()}`}
                    alt={data?.title}
                    className="uploaded-image"
                    style={{
                      width: "65px",
                      height: "35px",
                      borderRadius: "3px",
                      cursor: "pointer",
                      boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                </div>
                <div
                  className="d-flex justify-content-end"
                  style={{ flexBasis: "12%", flexShrink: 0 }}
                >
                  <CustomViewButton
                    id={data?.id}
                    onClick={() =>
                      handleImageClick(`${data?.file_path}?${Date.now()}`)
                    }
                  />
                  <CustomEditButton
                    id={data?.id}
                    // style={{ marginRight: "-5px" }}
                    onClick={() => {
                      setIsDisabled(false);
                      setActionId(data.id);
                      handleEditSignatureButtonClick();
                    }}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => handleDelete(data?.id)}
                  >
                    <Trash3 style={{ fill: "red" }}></Trash3>
                  </button>
                  <button
                    className="btn btn-success btn-sm border-0 bg-transparent"
                    onClick={() => handleDownload(data?.file_path)}
                  >
                    <Download style={{ fill: "green" }}></Download>
                  </button>
                </div>
              </div>
              {showFullScreen && <FullScreenImage />}
            </span>
          ))}

        <ConfirmationModal
          show={showConfirmation}
          onHide={handleCancelDelete}
          title="Delete Signature"
          message={`Are you sure you want to delete this signature?`}
          onConfirm={handleConfirmDelete}
          confirmButtonColor="danger"
          spinnerStatus={customSubmitting ? customSubmitting : false}
        />
      </div>
    </div>
  );
}

export default SignatureForDocuments;
