import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Badge, Col, Row } from "react-bootstrap";
import CustomFilter, { InputValues } from "../../../Components/CustomFilter";
import CustomButton from "../../../Components/Button/CustomButton";
import {
  PURCHASE_ADD,
  PURCHASE_EDIT,
  PURCHASE_TITLE,
  PURCHASE_VIEW,
} from "../../../Routes/SalesAndPurchase/Purchase";
import { setToast } from "../../../Util/Toast";
import { fetchData } from "../../../Util/OrionList";
import CustomDataTable from "../../../Components/CustomDataTable";
import { TableColumn } from "react-data-table-component";
import { PurchaseOrder } from "../../../Model/SalesAndPurchase/PurchaseOrder/PurchaseOrder";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import CustomViewButton from "../../../Components/Button/CustomViewButton";
import CustomEditButton from "../../../Components/Button/CustomEditButton";
import CustomDownloadButton from "../../../Components/Button/CustomDownloadButton";
import { getCookie } from "typescript-cookie";
import { INWARD_DOCUMENT_FOR_PURCHASE_ORDER } from "../../../Routes/SalesAndPurchase/InwardDocument";
import { Plus } from "react-bootstrap-icons";
import { VIEW_INWARD_DOCUMENT } from "../../../Util/PermissionList";
import { CapitalizeAndRemoveUnderscore } from "../../../Util/CapitalizeAndRemoveUnderscore";
import pluralize from "pluralize";
interface PurchaseOrderItem {
  id: number;
  title: string;
  status: string;
  description: string;
  days: number;
  delivery_date: string;
  created_at: string;
  document_number: string;
  counter_party_company: any;
  manufacture: any;
}

function List({ permissions }: any) {
  const location = useLocation();
  const { date } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState<PurchaseOrderItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const params = date ? new URLSearchParams(date) : null;
  const fromDate = params?.get("fromDate");
  const [isArchive] = useState<boolean>(false);
  const [includeWith] = useState(["counterPartyCompany", "manufacture"]);
  const isForDashboard = new URLSearchParams(useLocation().search).has(
    "isfordashboard",
  );
  const handleRowCreateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(PURCHASE_ADD);
  };

  const handleRowViewButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(PURCHASE_VIEW.replace(":id", id.toString()));
  };

  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);

  const fetchPurchaseOrder = useCallback(async () => {
    try {
      setLoading(true);
      const filters = {
        ...(fromDate
          ? { created_at: { filter_operation: ">=", value: fromDate } }
          : {}),
        ...(inputValues ? inputValues : {}),
      };

      await fetchData(
        page,
        limit,
        filters,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new PurchaseOrder(),
        sortField,
        sortOrder,
        isArchive,
        includeWith,
      );
    } catch (error: any) {
      setToast("error", error.response.data.message);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, page, limit, inputValues, sortField, sortOrder, navigate]);
  useEffect(() => {
    fetchPurchaseOrder();
  }, [fetchPurchaseOrder]);

  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };
  useEffect(() => {
    fetchPurchaseOrder();
  }, [limit, page]); // eslint-disable-line

  const columns: TableColumn<PurchaseOrderItem>[] = [
    {
      name: "Id",
      cell: (row) => row.id,
      maxWidth: "1%",
    },
    {
      name: "Document Number",
      cell: (row) => row.document_number,
      minWidth: "10%",
    },
    {
      name: "Suppliers Name",
      cell: (row) => row.counter_party_company?.company_name,
      minWidth: "10%",
    },
    {
      name: "Manufacturer",
      minWidth: "10%",
      cell: (row) => row.manufacture?.company_name,
    },
    {
      name: "Title",
      cell: (row) => row.title,
      minWidth: "10%",
    },
    {
      name: "Status",
      minWidth: "10%",
      cell: (row) => (
        <Badge pill className={`badge-${row.status.replace("_", "-")}`}>
          {CapitalizeAndRemoveUnderscore(row.status)}
        </Badge>
      ),
    },
    {
      name: "Delivery Date",
      cell: (row: any) => row.delivery_date,
      minWidth: "10%",
    },
    {
      name: "Created At",
      cell: (row: any) => row.created_at,
      minWidth: "10%",
    },
    {
      name: "Updated At",
      minWidth: "10%",
      cell: (row: any) => row.updated_at,
    },
    {
      name: "Download",
      button: true,
      maxWidth: "1%",
      cell: (row: any) => (
        <div>
          <CustomDownloadButton
            id={row.id}
            onClick={handlePDFDownloadButtonClick}
          />
        </div>
      ),
    },
    {
      name: "Actions",
      wrap: true,
      button: true,
      minWidth: "13%",
      style: {
        justifyContent: "end",
        position: "sticky", // Fix the column
        right: 0, // Stick to the right
        backgroundColor: "#fff",
      },
      ignoreRowClick: true, // Prevent triggering row click events
      allowOverflow: true, // Prevent clipping in scrollable table
      omit: !permissions.update && !permissions.delete,
      cell: (row: any) => (
        <div className="d-flex align-items-center">
          {permissions[`${VIEW_INWARD_DOCUMENT}`] &&
            row.status === "delivered" && (
              <button
                className="btn btn-sm btn-success d-flex"
                onClick={(e: any) => {
                  handleOpenInwardDocument(e, row);
                }}
              >
                Inward
                <span style={{ marginTop: "-1px" }}>
                  <Plus />
                </span>
              </button>
            )}
          <CustomViewButton
            id={row.id}
            onClick={(e) => {
              return handleRowViewButtonClick(e, row.id);
            }}
          />
          {permissions.update && (
            <CustomEditButton
              isDisabled={row.status !== "pending" && true}
              id={row.id}
              onClick={handleRowEditButtonClick}
            />
          )}
        </div>
      ),
    },
  ];
  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "document_number",
      value: "document_number",
      label: "Document Number",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "counterPartyCompany.company_name",
      value: "counterPartyCompany.company_name",
      label: "Supplier Name",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "manufacture.company_name",
      value: "manufacture.company_name",
      label: "Manufacturer",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "title",
      value: "title",
      label: "Title",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "delivery_date",
      value: "delivery_date",
      label: "Delivery Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
    {
      id: "status",
      value: "status",
      label: "Status",
      fieldType: "select",
      fieldOption: {
        All: "",
        Pending: "pending",
        Delivered: "delivered",
      },
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
    {
      id: "updated_at",
      value: "updated_at",
      label: "Updated Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
  ];

  const handleRowEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(PURCHASE_EDIT.replace(":updateid", id.toString()));
  };

  const handleOpenInwardDocument = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: any,
  ) => {
    e.preventDefault();
    navigate(
      INWARD_DOCUMENT_FOR_PURCHASE_ORDER.replace(":purchase_order_id", row.id),
    );
  };

  const authToken = getCookie("authToken");
  const token = authToken ? authToken.replace(/["']/g, "") : "";

  const handlePDFDownloadButtonClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL + `api/purchase-order/pdf/`;
      const response = await fetch(apiUrl + id, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        const anchor = document.createElement("a");
        anchor.href = responseData.data;
        anchor.download = "purchase order";
        anchor.rel = "noreferrer";
        anchor.target = "_blank";
        anchor.click();
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } catch (error) {
      setToast("error", "An error occurred while processing your request");
    }
  };
  const handleGotoDashboard = () => {
    navigate("/");
  };
  return (
    <>
      <Row className="align-items-center">
        <Col className="col-10">
          <div className="d-flex justify-content-start">
            <div style={{ display: "inline-block" }}>
              <h6 className="mt-1">{pluralize(PURCHASE_TITLE)} </h6>
            </div>
            <CustomFilter
              options={filterOptions}
              inputValues={inputValues}
              setInputValues={setInputValues}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              getData={fetchPurchaseOrder}
              setPage={setPage}
            />
          </div>
        </Col>
        <Col className="col-2 text-xxl-end text-xl-end text-lg-end text-md-start text-sm-start text-xs-start">
          {permissions.add && (
            <CustomButton
              label={`Add`}
              variant="success"
              onClick={handleRowCreateButtonClick}
            />
          )}
          {isForDashboard && (
            <button
              type="button"
              className="btn btn-secondary btn-sm ms-2"
              onClick={handleGotoDashboard}
            >
              Back
            </button>
          )}
        </Col>
      </Row>
      <CustomDataTable
        columns={columns}
        data={data}
        progressPending={loading}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        handleRowCreateButtonClick={handleRowCreateButtonClick}
        currentPages={page}
        currentRowsPerPage={limit}
        module={"PurchaseOrder"} // Pass optional fields to CustomDataTable
      />
    </>
  );
}

export default List;
