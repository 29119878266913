import React, { useState, useRef, useEffect } from "react";
import { Col, Collapse, Form, Row } from "react-bootstrap";
import Select, { components } from "react-select";
import CustomButton from "./Button/CustomButton";

export type InputValues = { [key: string]: {} };

interface CustomFilterProps {
  options: any[];
  inputValues: InputValues;
  setInputValues: Function;
  selectedOptions: any[];
  setSelectedOptions: Function;
  getData: Function;
  inputInitialValues?: any;
  setPage?: any;
}

export default function CustomFilter({
  options,
  inputValues,
  setInputValues,
  selectedOptions,
  setSelectedOptions,
  getData,
  inputInitialValues,
  setPage,
}: CustomFilterProps) {
  const [open, setOpen] = useState(false);
  const [filterOperation, setFilterOperation] = useState("");
  const searchButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleSearch = () => {
    if (
      typeof inputValues === "object" &&
      Object.keys(inputValues).length > 0
    ) {
      getData();
    } else if (typeof inputValues === "string") {
      getData();
    }
  };

  const handleInputChange = (
    optionId: string,
    inputKey: string,
    inputValue: any,
  ) => {
    if (optionId === "active" || optionId === "Active") {
      inputValue = inputValue.toLowerCase();
      setInputValues((prevInputValues: { [x: string]: any }) => ({
        ...prevInputValues,
        [optionId]: {
          ...prevInputValues[optionId],
          [inputKey]:
            inputValue === "inactive"
              ? "false"
              : inputValue === "active"
              ? true
              : inputKey === "filter_operation"
              ? inputValue
              : inputValue,
        },
      }));
      if (inputValue === "") {
        setInputValues({});
      }
      return;
    }
    if (inputValue.length <= 0 && inputInitialValues) {
      setInputValues(inputInitialValues);
      return;
    }

    if (inputValue.length <= 0) {
      setInputValues(inputValue);
      return;
    }
    setPage(1);
    setInputValues((prevInputValues: { [key: string]: any }) => {
      const updatedInputValues = { ...prevInputValues };
      if (!inputValue || inputValue.trim() === "") {
        const updatedFilter = {
          ...updatedInputValues[optionId],
        };
        delete updatedFilter[inputKey];
        if (!updatedFilter.value) {
          delete updatedInputValues[optionId];
        }
        return updatedInputValues;
      }
      if (
        !updatedInputValues[optionId] ||
        !updatedInputValues[optionId].filter_operation
      ) {
        updatedInputValues[optionId] = {
          ...updatedInputValues[optionId],
          filter_operation: filterOperation,
        };
      }

      updatedInputValues[optionId] = {
        ...updatedInputValues[optionId],
        [inputKey]: inputValue,
      };

      return updatedInputValues;
    });
    if (inputValue) {
      return;
    }
    setPage(1);
    setInputValues((prevInputValues: { [key: string]: any }) => {
      const updatedInputValues = { ...prevInputValues };
      delete updatedInputValues[optionId];
      return updatedInputValues;
    });
    if (searchButtonRef.current) {
      searchButtonRef.current.click();
    }
  };

  const Option = (props: any) => (
    <div className="p-0">
      <components.Option {...props}>
        <div>
          <p>{props.data.label}</p>
        </div>
      </components.Option>
    </div>
  );

  const handleOptionChange = (selected: any) => {
    setSelectedOptions(selected);
    const unselected = selectedOptions.filter(
      (prevOption) =>
        !selected.some(
          (currentOption: { value: any }) =>
            currentOption.value === prevOption.value,
        ),
    );

    unselected.forEach((option) => {
      setInputValues((prevInputValues: { [x: string]: any }) => {
        const updatedInputValues = { ...prevInputValues };
        delete updatedInputValues[option.id];
        return updatedInputValues;
      });
    });
    getData();
  };

  useEffect(() => {
    if (searchButtonRef.current) {
      searchButtonRef.current.click();
    }
  }, [inputValues]);

  useEffect(() => {
    if (selectedOptions.length > 0) {
      selectedOptions.forEach((option) => {
        const initialFilterOperation =
          option.allowedFilter && Object.values(option.allowedFilter)[0];
        if (initialFilterOperation) {
          setFilterOperation(initialFilterOperation);
          console.log(
            "Initially selected filter operation:",
            initialFilterOperation,
          );
        }
      });
    }
  }, [selectedOptions, setSelectedOptions]);

  return (
    <div className="col-10">
      <CustomButton
        label={"Search / Filter"}
        onClick={() => setOpen(!open)}
        variant="primary"
        customeClass="btn-sm"
      />
      <Collapse in={open} className="mt-3">
        <Row>
          <Col>
            <div
              className="d-flex align-items-center"
              style={{ width: "100%" }}
            >
              <div
                style={{ width: "100%", marginRight: "10px" }}
                className="p-0"
              >
                <Select
                  isMulti
                  options={options}
                  isSearchable
                  placeholder="Select options..."
                  components={{ Option }}
                  onChange={handleOptionChange}
                  value={selectedOptions}
                  className="p-0"
                />
              </div>
              <button
                className="btn btn-outline-success btn-sm"
                onClick={handleSearch}
                ref={searchButtonRef}
              >
                Search
              </button>
            </div>
            <Row>
              {selectedOptions.map((option: any) => (
                <Col sm={10} className="m-2">
                  <Row style={{ alignItems: "center" }}>
                    <Col>
                      <span
                        className="align-middle py-1"
                        id="addon-wrapping"
                        style={{ maxWidth: "max-content" }}
                      >
                        {option.label}
                      </span>
                    </Col>
                    <Col>
                      <div>
                        <Form.Select
                          className="py-1"
                          aria-label="Filter Operation"
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            setFilterOperation(selectedValue);

                            handleInputChange(
                              option.id,
                              "filter_operation",
                              selectedValue,
                            );
                          }}
                          value={filterOperation}
                        >
                          {Object.entries(option.allowedFilter).map(
                            ([key, valueOpt]) => (
                              <option
                                key={valueOpt as string}
                                value={valueOpt as string}
                              >
                                {key}
                              </option>
                            ),
                          )}
                        </Form.Select>
                      </div>
                    </Col>
                    <Col>
                      {option.fieldType === "select" ? (
                        <Form.Select
                          className="py-1"
                          aria-label="Filter Operation"
                          onChange={(e) => {
                            handleInputChange(
                              option.id,
                              "value",
                              e.target.value,
                            );
                          }}
                        >
                          {Object.entries(option.fieldOption).map(
                            ([key, valueOpt]) => (
                              <option value={valueOpt as string}>{key}</option>
                            ),
                          )}
                        </Form.Select>
                      ) : (
                        <input
                          type={option.fieldType}
                          max={
                            option.fieldType === "date"
                              ? "9999-12-31"
                              : undefined
                          }
                          className="form-control rounded py-1"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          onChange={(e) => {
                            handleInputChange(
                              option.id,
                              "value",
                              e.target.value,
                            );
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Collapse>
    </div>
  );
}
