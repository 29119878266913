import React from "react";
import { ButtonGroup } from "react-bootstrap";
import { Download } from "react-bootstrap-icons";

interface CustomDownloadButtonProps {
  onClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number,
    option?: any,
    isDisable?: boolean,
  ) => void;
  id: number;
  isDisabled?: boolean;
  style?: any;
  title?: string;
}

const CustomDownloadButton: React.FC<CustomDownloadButtonProps> = ({
  onClick,
  id,
  isDisabled,
  style,
  title,
}) => {
  return (
    <ButtonGroup>
      <button
        className="btn btn-xs border-0"
        disabled={isDisabled}
        onClick={(e) => onClick(e, id)}
        title={title ? title : "Downlad"}
        style={style && style}
      >
        <Download className="ml-4" style={{ fill: "#0D6EFD" }} />
      </button>
    </ButtonGroup>
  );
};

export default CustomDownloadButton;
