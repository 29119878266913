import React from "react";
import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/User/List";
import AddUpdate from "../../Pages/Setting/User/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_USER,
  DELETE_USER,
  RESTORE_USER,
  UPDATE_USER,
  VIEW_USER,
} from "../../Util/PermissionList";

export const USER_TITLE = "User";
export const USER_LIST = "/users";
export const USER_DASHBOARD_LIST = "/users?isfordashboard";
export const USER_ADD = "/users/add";
export const USER_EDIT = "/users/:id/edit";
export const USER_ARCHIVE = "/users/archive";

const User: RouteObject[] = [
  {
    path: USER_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_USER}
        checkPermisson={{
          addPermission: ADD_USER,
          updatePermission: UPDATE_USER,
          deletePermission: DELETE_USER,
          restorePermission: RESTORE_USER,
          viewPermission: VIEW_USER,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: USER_DASHBOARD_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_USER}
        checkPermisson={{
          addPermission: ADD_USER,
          updatePermission: UPDATE_USER,
          deletePermission: DELETE_USER,
          restorePermission: RESTORE_USER,
          viewPermission: VIEW_USER,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: USER_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={ADD_USER}
        checkPermisson={{
          addPermission: ADD_USER,
          updatePermission: UPDATE_USER,
          deletePermission: DELETE_USER,
          restorePermission: RESTORE_USER,
          viewPermission: VIEW_USER,
        }}
      />
    ),
  },
  {
    path: USER_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={UPDATE_USER}
        checkPermisson={{
          addPermission: ADD_USER,
          updatePermission: UPDATE_USER,
          deletePermission: DELETE_USER,
          restorePermission: RESTORE_USER,
          viewPermission: VIEW_USER,
        }}
      />
    ),
  },
  {
    path: USER_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          addPermission: ADD_USER,
          updatePermission: UPDATE_USER,
          deletePermission: DELETE_USER,
          restorePermission: RESTORE_USER,
          viewPermission: VIEW_USER,
        }}
        permission={DELETE_USER}
      />
    ),
  },
];

export default User;
