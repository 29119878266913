export function CapitalizeAndRemoveUnderscore(text: string) {
  return text
    ?.split("_")
    .map((word: string) => {
      return word
        .split("-")
        .map(
          (part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase(),
        )
        .join("-");
    })
    .join(" ");
}

export const capitalize = (str: string) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : "";
