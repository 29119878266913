import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import { Attributes } from "../Model/Setting/Attributes";
import { Material } from "../Model/Inventory/Material";
import { Product } from "../Model/Inventory/Product";
import { BuyersAndSuppliers } from "../Model/BuyersAndSuppliers/BuyersAndSuppliers";

export async function DynamicFormFields(formFields: any, entityType: any) {
  try {
    let query: any = Attributes.$query()
      .with(["options"])
      .filter("entity_type", FilterOperator.Equal, entityType);
    const modelData: any = await query.search();
    const updatedFormFields: any = updateFormFields(
      modelData[0]?.$response?.data.data,
      formFields,
    );

    return updatedFormFields;
  } catch (error) {
    return formFields;
  }
}

const updateFormFields = (dynamicFormFields: any, formFields: any) => {
  dynamicFormFields?.forEach((attribute: any) => {
    const labelWithAsterisk = attribute.is_required
      ? `${attribute.name}*`
      : attribute.name;

    if (attribute.type === "select" || attribute.type === "multiselect") {
      const optionsArray = attribute.options.map((option: any) => ({
        label: option.name,
        value: option.id,
      }));
      const newFormField: any = {
        attribute_id: attribute.id,
        id: `input${attribute.name}`,
        label: labelWithAsterisk, // Updated label
        type: attribute.type,
        col: 6,
        name: attribute.code,
        placeholder: `Enter ${attribute.name}`,
        isDynamic: true,
        isMultiSelect: attribute.type === "multiselect" && true,
        value: optionsArray.value,
        options: optionsArray,
        validate: attribute.is_required && [
          {
            rule: (value: any) => !!value,
            message: `${attribute.name} is required`,
          },
        ],
      };

      const isDuplicate = formFields?.some((field: any) => {
        return field.name === newFormField.name;
      });

      if (!isDuplicate) {
        formFields.push(newFormField);
      }
    } else if (attribute.type === "lookup") {
      let model;
      let searchField;
      if (attribute.lookup_type === "products") {
        model = Product;
        searchField = "product_name";
      } else if (attribute.lookup_type === "materials") {
        model = Material;
        searchField = "material_name";
      } else if (attribute.lookup_type === "counter_party_companies") {
        model = BuyersAndSuppliers;
        searchField = "company_name";
      }
      const newFormField: any = {
        attribute_id: attribute.id,
        id: `input${attribute.name}`,
        label: labelWithAsterisk, // Updated label
        type: attribute.type,
        col: 6,
        name: attribute.code,
        placeholder: `Enter ${attribute.name}`,
        isDynamic: true,
        searchField: searchField,
        model: model,
        validate: attribute.is_required && [
          {
            rule: (value: any) => !!value,
            message: `${attribute.name} is required`,
          },
        ],
      };

      const isDuplicate = formFields?.some((field: any) => {
        return field.name === newFormField.name;
      });

      if (!isDuplicate) {
        formFields?.push(newFormField);
      }
    } else if (attribute.type !== "checkbox") {
      const newFormField: any = {
        attribute_id: attribute.id,
        id: `input${attribute.name}`,
        label: labelWithAsterisk, // Updated label
        type: attribute.type,
        col: 6,
        name: attribute.code,
        placeholder: `Enter ${attribute.name}`,
        isDynamic: true,
        value: attribute.value,
        validate: attribute.is_required && [
          {
            rule: (value: any) => !!value,
            message: `${attribute.name} is required`,
          },
        ],
      };

      const isDuplicate = formFields.some((field: any) => {
        return field.name === newFormField.name;
      });

      if (!isDuplicate) {
        formFields.push(newFormField);
      }
    } else {
      attribute.options.forEach((option: any) => {
        const optionFormField: any = {
          id: `inputOption${option.name}`,
          attribute_id: attribute.id,
          optionId: option.id,
          label: option.name,
          type: attribute.type,
          col: 6,
          name: option.name,
          value: option.name,
          isDynamic: true,
          checkboxName: attribute.name,
        };

        const isOptionDuplicate = formFields.some((field: any) => {
          return field.name === optionFormField.name;
        });

        if (!isOptionDuplicate) {
          formFields.push(optionFormField);
        }
      });
    }
  });

  return formFields;
};
