import React from "react";
import { RouteObject } from "react-router-dom";
import List from "../../Pages/Inventory/StockInventory/List";
import View from "../../Pages/Inventory/StockInventory/View";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  VIEW_STOCK_INVENTORY,
  DELETE_STOCK_INVENTORY,
  ADD_STOCK_INVENTORY,
  RESTORE_STOCK_INVENTORY,
  UPDATE_STOCK_INVENTORY,
} from "../../Util/PermissionList";
import AddUpdate from "../../Pages/Inventory/StockInventory/AddUpdate";
import StockApproval from "../../Pages/Inventory/StockInventory/StockApproval";

export const STOCK_INVENTORY_TITLE = "Stock Inventory";
export const STOCK_INVENTORY_LIST = "/stock-inventory";
export const STOCK_INVENTORY_ID_ADD = "/stock-inventory/add";
export const STOCK_INVENTORY_ID_VIEW = "/stock-inventory/:document_number/view";
export const STOCK_INVENTORY_ID_STOCK_APPROVAL =
  "/stock-inventory/:document_number/stock-approval";
export const STOCK_INVENTORY_ARCHIVE = "/stock-inventory/archive";

const StockInventory: RouteObject[] = [
  {
    path: STOCK_INVENTORY_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_STOCK_INVENTORY}
        checkPermisson={{
          viewPermission: VIEW_STOCK_INVENTORY,
          addPermission: ADD_STOCK_INVENTORY,
          updatePermission: UPDATE_STOCK_INVENTORY,
          deletePermission: DELETE_STOCK_INVENTORY,
          restorePermission: RESTORE_STOCK_INVENTORY,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: STOCK_INVENTORY_ID_VIEW,
    element: (
      <AuthorizedRoute permission={VIEW_STOCK_INVENTORY} element={<View />} />
    ),
  },
  {
    path: STOCK_INVENTORY_ID_STOCK_APPROVAL,
    element: (
      <AuthorizedRoute
        permission={STOCK_INVENTORY_ID_STOCK_APPROVAL}
        element={<StockApproval />}
      />
    ),
  },
  {
    path: STOCK_INVENTORY_ID_ADD,
    element: (
      <AuthorizedRoute
        permission={ADD_STOCK_INVENTORY}
        element={<AddUpdate />}
      />
    ),
  },
  {
    path: STOCK_INVENTORY_ARCHIVE,
    element: (
      <AuthorizedRoute permission={DELETE_STOCK_INVENTORY} element={<List />} />
    ),
  },
];

export default StockInventory;
