import "bootstrap/dist/css/bootstrap.min.css";
import { APPROVALS_TITLE } from "../../../Routes/Setting/Approvals";
import { Organization } from "../../../Model/Setting/Organization";
import { Approvals } from "../../../Model/Setting/Approvals";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { setToast } from "../../../Util/Toast";
import { useApi } from "../../../Controller/ApiController";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import FieldDescription from "../../../Components/FieldDescription";
import Notes from "../../../Components/Notes";
import ActivityLogs from "../../../Components/ActivityLogs";
import CustomSkeleton from "../../../Components/CustomSkeleton";
import CustomButton from "../../../Components/Button/CustomButton";
import { getUser, setUser } from "../../../Util/Auth";

const AddUpdate = () => {
  let title = APPROVALS_TITLE;
  const user = getUser();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<any>({});
  const { apiGet, apiPut } = useApi();
  const [formError, setFormError] = useState<string | null>(null);
  const [isShows, setIsShows] = useState<boolean>(false);
  const [id, setId] = useState("");
  const [activeTab, setActiveTab] = useState("notes");
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status
  const navigate = useNavigate();
  const [globalSetting, setGlobalSetting] = useState(
    user.organization.is_global_setting === 1,
  );
  const [tempGlobalSetting, setTempGlobalSetting] = useState(globalSetting);

  const [allApproval, setAllApproval] = useState(
    user.organization.is_manual_adjustment === 1,
  );
  const [tempAllApproval, setTempAllApproval] = useState(allApproval);

  useEffect(() => {
    setGlobalSetting(user.organization.is_global_setting === 1);
    setTempGlobalSetting(user.organization.is_global_setting === 1);

    setAllApproval(user.organization.is_manual_adjustment === 1);
    setTempAllApproval(user.organization.is_manual_adjustment === 1);
  }, [
    user.organization.is_global_setting,
    user.organization.is_manual_adjustment,
  ]);

  const handleSave = async () => {
    const Data = {
      is_global_setting: tempGlobalSetting ? 1 : 0,
      is_manual_adjustment: tempAllApproval ? 1 : 0,
    };

    try {
      const response = await apiPut("edit-organization", Data);
      if (response) {
        const updatedUser = {
          ...user,
          organization: {
            ...user.organization,
            ...response.data,
          },
        };
        setUser(updatedUser, true);

        setGlobalSetting(response.data.is_global_setting === 1);
        setTempGlobalSetting(response.data.is_global_setting === 1);

        setAllApproval(response.data.is_all_approval === 1);
        setTempAllApproval(response.data.is_all_approval === 1);

        setToast("success", `Profile Organization Updated`);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const formFields = [
    {
      id: "inputinvoice",
      label: "Invoice",
      type: "switch",
      col: 6,
      name: "invoice",
    },
    {
      id: "inputinward_purchase_order",
      label: "inward_purchase_order",
      type: "switch",
      col: 6,
      name: "inward_purchase_order",
    },
    {
      id: "inputmanual_adjustment",
      label: "manual_adjustment",
      type: "switch",
      col: 6,
      name: "manual_adjustment",
    },
    {
      id: "inputsales_return",
      label: "sales_return",
      type: "switch",
      col: 6,
      name: "sales_return",
    },
    // {
    //   id: "inputgrn_qir_sub_contact",
    //   label: "grn_qir_sub_contact",
    //   type: "switch",
    //   col: 6,
    //   name: "grn_qir_sub_contact",
    // },
    // {
    //   id: "inputchallan_order_confirmation",
    //   label: "challan_order_confirmation",
    //   type: "switch",
    //   col: 6,
    //   name: "challan_order_confirmation",
    // },
    // {
    //   id: "inputchallan_so_sc",
    //   label: "challan_so_sc",
    //   type: "switch",
    //   col: 6,
    //   name: "challan_so_sc",
    // },
    // {
    //   id: "inputb2c_invoice",
    //   label: "b2c_invoice",
    //   type: "switch",
    //   col: 6,
    //   name: "b2c_invoice",
    // },
    // {
    //   id: "inputgrn_qir_purchase_order",
    //   label: "grn_qir_purchase_order",
    //   type: "switch",
    //   col: 6,
    //   name: "grn_qir_purchase_order",
    // },
    // {
    //   id: "inputinward_so_sc",
    //   label: "inward_so_sc",
    //   type: "switch",
    //   col: 6,
    //   name: "inward_so_sc",
    // },
    // {
    //   id: "inputchallan_sub_contract",
    //   label: "challan_sub_contract",
    //   type: "switch",
    //   col: 6,
    //   name: "challan_sub_contract",
    // },
    // {
    //   id: "inputpurchase_return_challan",
    //   label: "purchase_return_challan",
    //   type: "switch",
    //   col: 6,
    //   name: "purchase_return_challan",
    // },
    // {
    //   id: "inputstock_transfer",
    //   label: "stock_transfer",
    //   type: "switch",
    //   col: 6,
    //   name: "stock_transfer",
    // },
    // {
    //   id: "inputprocess",
    //   label: "process",
    //   type: "switch",
    //   col: 6,
    //   name: "process",
    // },
  ];

  const handleCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    const {
      is_manual_adjustment,
      inward_purchase_order,
      manual_adjustment,
      invoice,
      sales_return,
      // grn_qir_sub_contact,
      // challan_order_confirmation,
      // challan_so_sc,
      // b2c_invoice,
      // physical_stock_reconciliation,
      // grn_qir_purchase_order,
      // inward_so_sc,
      // challan_sub_contract,
      // purchase_return_challan,
      // stock_transfer,
      // process,
    } = formData;

    const inputData = {
      inward_purchase_order:
        inward_purchase_order === undefined
          ? "manual"
          : inward_purchase_order === true
          ? "auto"
          : "manual",
      invoice:
        invoice === undefined ? "manual" : invoice === true ? "auto" : "manual",
      manual_adjustment:
        manual_adjustment === undefined
          ? "manual"
          : manual_adjustment === true
          ? "auto"
          : "manual",
      sales_return:
        sales_return === undefined
          ? "manual"
          : sales_return === true
          ? "auto"
          : "manual",
      // grn_qir_sub_contact:
      //   grn_qir_sub_contact === undefined
      //     ? "manual"
      //     : grn_qir_sub_contact === true
      //     ? "auto"
      //     : "manual",
      // challan_order_confirmation:
      //   challan_order_confirmation === undefined
      //     ? "manual"
      //     : challan_order_confirmation === true
      //     ? "auto"
      //     : "manual",
      // challan_so_sc:
      //   challan_so_sc === undefined
      //     ? "manual"
      //     : challan_so_sc === true
      //     ? "auto"
      //     : "manual",
      // b2c_invoice:
      //   b2c_invoice === undefined
      //     ? "manual"
      //     : b2c_invoice === true
      //     ? "auto"
      //     : "manual",
      // physical_stock_reconciliation:
      //   physical_stock_reconciliation === undefined
      //     ? "manual"
      //     : physical_stock_reconciliation === true
      //     ? "auto"
      //     : "manual",
      // grn_qir_purchase_order:
      //   grn_qir_purchase_order === undefined
      //     ? "manual"
      //     : grn_qir_purchase_order === true
      //     ? "auto"
      //     : "manual",
      // inward_so_sc:
      //   inward_so_sc === undefined
      //     ? "manual"
      //     : inward_so_sc === true
      //     ? "auto"
      //     : "manual",
      // challan_sub_contract:
      //   challan_sub_contract === undefined
      //     ? "manual"
      //     : challan_sub_contract === true
      //     ? "auto"
      //     : "manual",
      // purchase_return_challan:
      //   purchase_return_challan === undefined
      //     ? "manual"
      //     : purchase_return_challan === true
      //     ? "auto"
      //     : "manual",
      // stock_transfer:
      //   stock_transfer === undefined
      //     ? "manual"
      //     : stock_transfer === true
      //     ? "auto"
      //     : "manual",
      // process:
      //   process === undefined ? "manual" : process === true ? "auto" : "manual",
    };
    const organizationData = {
      is_manual_adjustment:
        is_manual_adjustment === undefined
          ? 0
          : is_manual_adjustment === true
          ? 1
          : 0,
    };
    try {
      await apiPut(`approvals-updates`, inputData);
      await apiPut(`edit-organization`, organizationData);
      navigate(`/${APPROVALS_TITLE.toLowerCase().replace(" ", "-") + "s"}`);
      setToast("success", `Approvals Successfully Submitted`);
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let URL = new Approvals().$resource();
        const itemData = await apiGet(`${URL}`);
        let data = itemData.data;
        data.is_manual_adjustment = false;
        setId(data.id);
        data["invoice"] = data["invoice"] === "auto";
        data["inward_purchase_order"] =
          data["inward_purchase_order"] === "auto";
        data["manual_adjustment"] = data["manual_adjustment"] === "auto";
        data["sales_return"] = data["sales_return"] === "auto";

        let OrganizationURL = new Organization().$resource();
        const organizationItemData = await apiGet(`${OrganizationURL}`);
        let organizationData = organizationItemData.data;
        data["is_manual_adjustment"] =
          organizationData[0]["is_manual_adjustment"] === 1;
        // data["b2c_invoice"] = data["b2c_invoice"] === "auto";
        // data["challan_order_confirmation"] = data["challan_order_confirmation"] === "auto";
        // data["challan_so_sc"] = data["challan_so_sc"] === "auto";
        // data["challan_sub_contract"] = data["challan_sub_contract"] === "auto";
        // data["grn_qir_purchase_order"] = data["grn_qir_purchase_order"] === "auto";
        // data["grn_qir_so_sc"] = data["grn_qir_so_sc"] === "auto";
        // data["grn_qir_sub_contact"] = data["grn_qir_sub_contact"] === "auto";
        // data["inward_so_sc"] = data["inward_so_sc"] === "auto";
        // data["physical_stock_reconciliation"] = data["physical_stock_reconciliation"] === "auto";
        // data["process"] = data["process"] === "auto";
        // data["purchase_return_challan"] = data["purchase_return_challan"] === "auto";
        // data["stock_transfer"] = data["stock_transfer"] === "auto";

        setFormData(data);
        setLoading(false);
      } catch (error: any) {
        setToast("error", error.response.data.message);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  const formFieldsForFieldDescription: any = [
    {
      label: "Approval",
      description:
        "Use the toggle button to swiftly approve or disapprove request.<br/> -ON:Indicates approval is granted. <br/>-OFF:Indicates approval is not granted.",
    },
  ];

  function CustomFormSkeleton() {
    const skeletonStyle = { marginBottom: "10px" };

    return (
      <div
        className="pt-2 pb-2"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ width: "48%" }}>
          {[...Array(4)].map((_, index) => (
            <div key={index} style={{ marginBottom: "40px" }}>
              <CustomSkeleton
                count={1}
                height={20}
                customOuterStyle={{ ...skeletonStyle, width: "70%" }}
              />
            </div>
          ))}
        </div>
        <div style={{ width: "48%" }}>
          {[...Array(4)].map((_, index) => (
            <div key={index} style={{ marginBottom: "40px" }}>
              <CustomSkeleton
                count={1}
                height={20}
                customOuterStyle={{ ...skeletonStyle, width: "70%" }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <div>
        <Card className="shadow-sm border-0 mb-4">
          <Card.Header>
            <h5 className="fw-bold">Global Settings</h5>
          </Card.Header>
          <Card.Body className="p-3">
            <Row className="justify-content-between">
              <Col>
                <div>
                  <Row className="justidy-content-between">
                    <Col>
                      <div className="align-items-center">
                        <div className="d-flex align-items-center">
                          <span className="fw-bold text-secondary me-3">
                            Global Approval
                          </span>
                          <span
                            className={`text-muted ${
                              !tempGlobalSetting ? "fw-bold" : ""
                            }`}
                          >
                            off
                          </span>
                          <Form className="mx-2">
                            <div className="form-check form-switch m-0">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                role="switch"
                                id="globalApprovalSwitch"
                                checked={tempGlobalSetting}
                                onChange={() =>
                                  setTempGlobalSetting(!tempGlobalSetting)
                                }
                                style={{
                                  cursor: "pointer",
                                  width: "50px",
                                  height: "25px",
                                }}
                              />
                            </div>
                          </Form>
                          <span
                            className={`text-muted ${
                              tempGlobalSetting ? "fw-bold" : ""
                            }`}
                          >
                            on
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="align-items-center">
                        <div className="d-flex align-items-center">
                          <span className="fw-bold text-secondary me-3">
                            All Approval
                          </span>
                          <span
                            className={`text-muted ${
                              !tempAllApproval ? "fw-bold" : ""
                            }`}
                          >
                            manual
                          </span>
                          <Form className="mx-2">
                            <div className="form-check form-switch m-0">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                role="switch"
                                id="allApprovalSwitch"
                                checked={tempAllApproval}
                                onChange={() =>
                                  setTempAllApproval(!tempAllApproval)
                                }
                                style={{
                                  cursor: "pointer",
                                  width: "50px",
                                  height: "25px",
                                }}
                              />
                            </div>
                          </Form>
                          <span
                            className={`text-muted ${
                              tempAllApproval ? "fw-bold" : ""
                            }`}
                          >
                            auto
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col>
                <div className="text-end">
                  <CustomButton
                    label="Back"
                    customeClass="me-2"
                    variant="secondary"
                    onClick={() => navigate(-1)}
                  />
                  <Button
                    variant="primary"
                    className="btn-sm"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <div
        style={{
          pointerEvents: globalSetting ? "none" : "auto",
          opacity: globalSetting ? "0.6" : "1",
        }}
      >
        <div>
          <span style={{ float: "inline-end" }}>
            <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
              Need Help?
            </Button>
          </span>
          <AddUpdateForm
            formFields={formFields}
            model={Approvals}
            title={title}
            customTitle={title}
            customFormSubmit={handleCustomSubmit}
            dynamicFormData={formData}
            dynamicFormError={formError}
            customSubmitting={customSubmitting}
            loadingStatus={loading}
            CustomFormSkeleton={CustomFormSkeleton}
          />
        </div>
      </div>
      {id && (
        <div className="card mt-4" style={{ marginBottom: "30px" }}>
          <div className="card-header d-flex">
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "notes" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("notes")}
            >
              Notes
            </h5>
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "activityLogs" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("activityLogs")}
            >
              Activity Logs
            </h5>
          </div>

          <div
            className="card-body"
            style={{
              height: "auto",
              overflow: "auto",
              backgroundColor: "#e4e4e4",
            }}
          >
            {activeTab === "notes" && <Notes id={id} model={Approvals} />}
            {activeTab === "activityLogs" && (
              <ActivityLogs auditable_type={"APPROVALS"} id={id} />
            )}
          </div>
        </div>
      )}
      <div id="form-bottom">
        <FieldDescription
          title={title}
          formFields={formFieldsForFieldDescription}
          isShows={isShows}
        />
      </div>
    </>
  );
};

export default AddUpdate;
