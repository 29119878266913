import { TableColumn } from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchData } from "../../../Util/OrionList";
import CustomDataTable from "../../../Components/CustomDataTable";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import { Badge, Col, Row } from "react-bootstrap";
import CustomFilter, { InputValues } from "../../../Components/CustomFilter";
import { setToast } from "../../../Util/Toast";
import CustomButton from "../../../Components/Button/CustomButton";
import pluralize from "pluralize";
import { StockInventory } from "../../../Model/Inventory/StockInventory";
import {
  STOCK_INVENTORY_TITLE,
  STOCK_INVENTORY_ID_VIEW,
  STOCK_INVENTORY_ID_STOCK_APPROVAL,
} from "../../../Routes/Inventory/StockInventory";
import { ProductItem } from "../Product/List";
import { BatchManagementItem } from "../../SalesAndPurchase/BatchManagement/List";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import AddUpdateStockModal from "../StockInventory/AddUpdate";
import { getUser } from "../../../Util/Auth";
import { CapitalizeAndRemoveUnderscore } from "../../../Util/CapitalizeAndRemoveUnderscore";
import CustomViewButton from "../../../Components/Button/CustomViewButton";

export interface StockInventoryItem {
  id: number;
  organization_id: number;
  products_id: number;
  batches_id: number;
  document_number: string;
  stock_action: string;
  change_quantity_by_product: string;
  new_quantity_by_product: number;
  old_quantity_by_batch: number;
  change_quantity_by_batch: string;
  new_quantity_by_batch: number;
  old_quantity_by_product: number;
  unit_of_measurement_id: number;
  document_id: number;
  document_type: string;
  document_number_format_type: string;
  product: ProductItem;
  batch: BatchManagementItem;
  store: any;
  approve_status: any;
  approved_by: any;
  comment: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}
function List({ permissions }: any) {
  const location = useLocation();
  const user = getUser();
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState<StockInventoryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalRows, setTotalRows] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [isArchive] = useState<boolean>(false);
  const [includeWith] = useState(["product", "batch", "store"]);

  const handleRowViewButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    document_number: string,
  ) => {
    e.preventDefault();
    window.open(
      STOCK_INVENTORY_ID_VIEW.replace(
        ":document_number",
        document_number.toString(),
      ),
      "_blank",
    );
  };
  const handleAprroveButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    document_number: string,
  ) => {
    e.preventDefault();
    navigate(
      STOCK_INVENTORY_ID_STOCK_APPROVAL.replace(
        ":document_number",
        document_number.toString(),
      ),
    );
  };
  const columns: TableColumn<StockInventoryItem>[] = [
    {
      name: "Document Number",
      cell: (row) => (
        <CustomButton
          label={
            <>
              {row.document_number}{" "}
              <FontAwesomeIcon icon={faUpRightFromSquare} />
            </>
          }
          variant="link"
          customeClass="text-decoration-none p-0 m-0"
          onClick={(e) =>
            handleRowViewButtonClick(e, row?.document_number?.toString())
          }
        />
      ),
    },
    {
      name: "Document Type",
      cell: (row) => <span> {row.document_type}</span>,
    },
    {
      name: "Status",
      cell: (row) => (
        <Badge pill className={`badge-${row.approve_status}`}>
          {CapitalizeAndRemoveUnderscore(row.approve_status)}
        </Badge>
      ),
    },
    {
      name: "Approved / Rejected By",
      cell: (row) => <span> {row.approved_by}</span>,
      width: "7.2%",
    },
    {
      name: "Product",
      cell: (row) => <span> {row.product.product_name}</span>,
    },
    {
      name: "Product Id",
      cell: (row) => <span> {row.product.product_id}</span>,
    },
    {
      name: "Batch Number",
      cell: (row) => row.batch.batch_number,
    },
    {
      name: "Batch Expiry Date",
      cell: (row) => row.batch.expiry_date,
    },
    {
      name: "Previous Quantity By Product",
      cell: (row) => row.old_quantity_by_product,
    },
    {
      name: "Previous Quantity By Batch",
      cell: (row) => row.old_quantity_by_batch,
    },
    {
      name: "Change Quantity By Product",
      cell: (row: any) => (
        <span
          style={{ fontWeight: "500" }}
          className={
            row.change_quantity_by_product < 0 || row.stock_action === "0"
              ? "text-danger"
              : "text-success"
          }
        >
          {row.change_quantity_by_product}
        </span>
      ),
    },
    {
      name: "Change Quantity By Batch",
      cell: (row: any) => (
        <span
          style={{ fontWeight: "500" }}
          className={
            row.change_quantity_by_batch < 0 || row.stock_action === "0"
              ? "text-danger"
              : "text-success"
          }
        >
          {row.change_quantity_by_batch}
        </span>
      ),
    },
    {
      name: "New Quantity By Product",
      cell: (row) => row.new_quantity_by_product,
    },
    {
      name: "New Quantity By Batch",
      cell: (row) => row.new_quantity_by_batch,
    },
    {
      name: "Store",
      cell: (row) => row.store?.name,
    },
    {
      name: "Created At",
      cell: (row) => row.created_at,
    },
    {
      name: "Actions",
      button: true,
      style: {
        justifyContent: "end",
        position: "sticky",
        right: 0,
        backgroundColor: "#fff",
      },
      ignoreRowClick: true,
      allowOverflow: true,

      omit: !permissions.update && !permissions.delete,
      cell: (row: any) => (
        <div
          className="d-flex justify-content-center w-100"
          style={{ background: "#f8f9fa" }}
        >
          <div>
            <CustomViewButton
              onClick={(e) =>
                handleAprroveButtonClick(e, row?.document_number?.toString())
              }
              id={row.id}
            />
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);

  // @ts-ignore
  const fetchProduct = async () => {
    try {
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new StockInventory(),
        sortField,
        sortOrder,
        isArchive,
        includeWith,
      );
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };

  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };
  useEffect(() => {
    !showModal && fetchProduct();
  }, [limit, page, showModal]); // eslint-disable-line

  const filterOptions: any = [
    {
      id: "document_number",
      value: "document_number",
      label: "Document Number",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "document_type",
      value: "document_type",
      label: "Document Type",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "approve_status",
      value: "approve_status",
      label: "Approval Status",
      fieldType: "select",
      fieldOption: {
        All: "",
        Approved: "approved",
        Rejected: "rejected",
        "Auto Approved": "auto_approved",
        Pending: "pending",
      },
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
    {
      id: "approved_by",
      value: "approved_by",
      label: "Approved / Rejected By",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "product.product_name",
      value: "product.product_name",
      label: "Product Name",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "batch.batch_number",
      value: "batch.batch_number",
      label: "Batch Number",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "batch.expiry_date",
      value: "batch.expiry_date",
      label: "Batch Expiry Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
    {
      id: "store.name",
      value: "store.name",
      label: "Store",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
  ];

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <Row className="align-items-center">
        <Col className="col-10">
          <div className="d-flex justify-content-start">
            <div style={{ display: "inline-block" }}>
              <h6 className="mt-1">{pluralize(STOCK_INVENTORY_TITLE)} </h6>
            </div>
            <CustomFilter
              options={filterOptions}
              inputValues={inputValues}
              setInputValues={setInputValues}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              getData={fetchProduct}
              setPage={setPage}
            />
          </div>
        </Col>
        <Col className="col-2 text-xxl-end text-xl-end text-lg-end text-md-start text-sm-start text-xs-start">
          {permissions.add &&
            user?.organization?.is_manual_adjustment === 0 && (
              <CustomButton
                label="Add"
                variant="success"
                onClick={handleShow}
                // onClick={handleRowCreateButtonClick}
                customeClass="ms-2"
              />
            )}

          <AddUpdateStockModal show={showModal} handleClose={handleClose} />
        </Col>
      </Row>
      <CustomDataTable
        columns={columns}
        data={data}
        progressPending={loading}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        handleRowCreateButtonClick={() => {}}
        currentPages={page}
        currentRowsPerPage={limit}
        module={"StockInventory"}
      />
    </>
  );
}

export default List;
