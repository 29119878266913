import React, { useEffect, useState } from "react";
import {
  PROJECTS_LIST,
  PROJECTS_MANUFACTURER_VIEW,
} from "../../Routes/Project/Project";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Form, Table } from "react-bootstrap";
import CustomAsyncSelect from "../../Components/AsyncSelect";
import { BuyersAndSuppliers } from "../../Model/BuyersAndSuppliers/BuyersAndSuppliers";
import { XCircle } from "react-bootstrap-icons";
import { SubUser } from "../../Model/Setting/SubUser";
import CustomButton from "../../Components/Button/CustomButton";
import { Projects } from "../../Model/Projects/Projects";
import { useDateFormat } from "../../Util/InputDateFormate";
import { DateFormat, DateString } from "../../Util/DateFormat";
import { setToast } from "../../Util/Toast";
import { getCookie } from "typescript-cookie";
import CustomViewButton from "../../Components/Button/CustomViewButton";
import { VIEW_PROJECT_MANUFACTURES_QUOTATION } from "../../Util/PermissionList";
import CreateRow from "../../Components/CreateRow";
import CustomSkeleton from "../../Components/CustomSkeleton";
import DatePicker from "react-datepicker";
import moment from "moment";
import { showErrorsInToasts } from "../../Util/ErrorMessageToast";

type ProductDetails = {
  id: string;
  position: number | null;
  ingredients: string;
  dosage_form: string;
  filling: string;
  type_of_filling: string;
  concentration: string;
  packaging_material: string;
};
const AddUpdate = ({ permissions }: any) => {
  const navigate = useNavigate();
  const { id }: any = useParams();
  const dateFormat = useDateFormat();
  const [formData, setFormData] = useState<any>({});
  // const [items, setItems] = useState<any>([{ id: 1 }]);
  const [selectedManufacturers, setSelectedManufacturers] = useState<any>([]);
  const [disabledDraftRows, setDisabledDraftRows] = useState<boolean[]>([]);
  const [formErrors, setFormErrors] = useState<any>({});
  const [manufacturerError, setManufacturerError] = useState<any>(false);
  const [loading, setLoading] = useState<any>(true);
  const [loadingForSelectedManufacturers, setLoadingForSelectedManufacturers] =
    useState<any>(true);
  const [submitting, setSubmitting] = useState(false);

  // Start DND
  const [products, setProducts] = useState<ProductDetails[]>([]);
  const [productFormErrors, setProductFormErrors] = useState<any>({});
  const [isUpdated, setIsUpdated] = useState(false);
  const [productDetails] = useState<ProductDetails>({
    id: "",
    position: null,
    ingredients: "",
    dosage_form: "",
    filling: "",
    type_of_filling: "",
    concentration: "",
    packaging_material: "",
  });
  const productTableFields = [
    {
      type: "text",
      label: "ingredients",
      headerLabel: "Ingredients*",
      fieldName: "ingredients",
      submitFieldName: "ingredients",
    },
    {
      type: "text",
      label: "dosage_form",
      headerLabel: "Dosage Form*",
      fieldName: "dosage_form",
      submitFieldName: "dosage_form",
    },
    {
      type: "text",
      label: "filling",
      headerLabel: "Filling*",
      fieldName: "filling",
      submitFieldName: "filling",
    },
    {
      type: "text",
      label: "type_of_filling",
      headerLabel: "Type of Filling*",
      fieldName: "type_of_filling",
      submitFieldName: "type_of_filling",
    },
    {
      type: "text",
      label: "concentration",
      headerLabel: "Concentration*",
      fieldName: "concentration",
      submitFieldName: "concentration",
    },
    {
      type: "file",
      label: "packaging_material",
      headerLabel: "Packaging material*",
      fieldName: "packaging_material",
      submitFieldName: "packaging_material",
    },
  ];

  // End DND
  const getFormData = async (itemId: any) => {
    const item: any = await Projects.$query()
      .with([
        "buyer",
        "productManufactures",
        "products",
        "productManufactures.supplier",
        "organization",
        "salesOwner",
      ])
      .find(itemId);
    return item.$attributes;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const itemData = await getFormData(id);
        itemData &&
          setFormData({
            title: itemData.title,
            description: itemData.description,
            buyer_id: {
              label: itemData.buyer.company_name,
              value: itemData.buyer.id,
            },
            sales_owner_id: {
              label: itemData.sales_owner.first_name,
              value: itemData.sales_owner.id,
            },
            project_status: {
              label: itemData.project_status
                .split("_")
                .map(
                  (word: any) => word.charAt(0).toUpperCase() + word.slice(1),
                )
                .join(" "),
              value: itemData.project_status,
            },
            project_value: itemData.project_value,
            expected_closed_date: DateString(
              itemData.expected_closed_date,
              dateFormat?.replace("dd", "DD"),
            ),
            productManufacturesArray: itemData.product_manufactures,
          });
        setProducts((prevState: any) => {
          const uniqueItems = new Set(prevState.map((item: any) => item.id)); // Extract unique ids
          const returnProduct = [
            ...prevState,
            ...itemData?.products
              .filter((item: any) => !uniqueItems.has(item?.id)) // Filter out duplicates
              .map((item: any, index: number) => ({
                ...item,
                position: item.position ?? index,
              })),
          ];

          return returnProduct.sort((a, b) => a.position - b.position);
        });
        setIsUpdated(true);

        const selectedManufacturers = itemData.product_manufactures.map(
          (manufacturer: any) => ({
            alreadyExist: true,
            id: manufacturer.id,
            mid: manufacturer.id,
            project_manufactures_status: {
              label: manufacturer.project_manufactures_status
                .split("_")
                .map(
                  (word: any) => word.charAt(0).toUpperCase() + word.slice(1),
                )
                .join(" "),
              value: manufacturer.project_manufactures_status,
            },
            value: manufacturer.supplier.id,
            label: manufacturer.supplier.company_name,
          }),
        );
        setSelectedManufacturers(selectedManufacturers);
      } catch (error: any) {
        if (error.response?.data?.errors) {
          setToast("error", error.response.data.errors);
        } else if (error.response?.data?.message) {
          setToast("error", error.response.data.message);
        } else {
          setToast("error", "An error occurred while processing your request");
        }
      } finally {
        setLoadingForSelectedManufacturers(false);
        setLoading(false);
      }
    };
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dateFormat]);

  useEffect(() => {
    if (!id && products?.length === 0) {
      setProducts([{ ...productDetails, position: 0 }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customTheme = (theme: any) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 30,
      baseUnit: 2,
    },
  });

  const handleManufacturerChange: any = (
    selectedOption: any,
    index: any,
    newAdd: any,
  ) => {
    if (!newAdd) {
      const updatedManufacturers = [...selectedManufacturers];
      updatedManufacturers[index] = {
        ...selectedManufacturers[index], // Preserve other properties
        project_manufactures_status: selectedOption,
      };

      setSelectedManufacturers(updatedManufacturers);
    } else {
      const newManufacturer = {
        ...selectedOption[index],
        project_manufactures_status: { label: "Draft", value: "draft" },
        newAdded: true,
      };

      setSelectedManufacturers((prevManufacturers: any) => {
        const updatedManufacturers = [...prevManufacturers, newManufacturer];
        const updatedDisabledRows: any = updatedManufacturers.map(
          (manufacturer: any) => {
            return manufacturer.alreadyExist ? null : true;
          },
        );

        setDisabledDraftRows(updatedDisabledRows);

        const filteredManufacturers = updatedManufacturers.filter(
          (manufacturer: any) => {
            return (
              manufacturer.label &&
              manufacturer.value &&
              selectedOption.some(
                (option: any) => option.value === manufacturer.value,
              )
            );
          },
        );
        return filteredManufacturers;
      });
    }
  };

  const GoBack = () => {
    navigate(PROJECTS_LIST);
  };
  const cleanObject = (obj: any, fileds: any) => {
    for (var propName in obj) {
      if (
        !fileds.includes(propName) &&
        (obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === "")
      ) {
        delete obj[propName];
      } else if (typeof obj[propName] === "object") {
        cleanObject(obj[propName], fileds);
      }
    }
    return obj;
  };

  async function handleSubmit(e: any) {
    try {
      setSubmitting(true);
      let inputData: any = Object.assign({}, formData);
      let productErrors: any = {};
      inputData.buyer_id = inputData.buyer_id?.value;
      inputData.sales_owner_id = inputData.sales_owner_id?.value;
      inputData.project_status = inputData.project_status?.value;
      const rowDataArray = products.map((item) => {
        const newItem: any = { ...item };
        let modifyPackagingMaterial =
          typeof item[`packaging_material`] === "object"
            ? item[`packaging_material`]
            : item[`packaging_material`]?.split("/").pop();
        newItem.packaging_material = modifyPackagingMaterial;
        const fields = [
          "ingredients",
          "dosage_form",
          "filling",
          "type_of_filling",
          "concentration",
        ];
        return cleanObject(newItem, fields);
      });
      rowDataArray.every((item: any, index: number) => {
        Object.entries(item).forEach(([key, value]: [string, any]) => {
          if (!(value || key === "comments" || key === "position")) {
            let errorKey = key
              .replace("id", "")
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");
            const errorMessage = `${errorKey} is required`;
            productErrors[index] = {
              ...productErrors[index],
              [key]: errorMessage,
            };
          }
        });
        return item;
      });
      setProductFormErrors(productErrors);
      const supplierIds = selectedManufacturers.map((manufacturer: any) => ({
        id: manufacturer.id,
        supplier_id: manufacturer.value,
        project_manufactures_status:
          manufacturer.project_manufactures_status.value, // Extracting only the value
      }));
      inputData.product_manufactures = supplierIds;
      inputData.expected_closed_date =
        inputData.expected_closed_date &&
        DateFormat(
          inputData.expected_closed_date,
          dateFormat.replace("dd", "DD"),
        );

      const formdata: any = new FormData();
      rowDataArray.forEach((item, index) => {
        Object.entries(item).forEach(([key, value]) => {
          // Check if the value is an object or a string (assuming URLs are strings)
          if (key !== "updated_at" && key !== "created_at") {
            formdata.append(`products[${index}][${key}]`, value);
          }
        });
      });
      formdata.append("title", inputData.title);
      formdata.append("description", inputData.description);
      formdata.append("buyer_id", inputData.buyer_id);
      formdata.append("sales_owner_id", inputData.sales_owner_id);
      formdata.append("project_value", inputData.project_value);
      formdata.append("expected_closed_date", inputData.expected_closed_date);
      if (id) {
        formdata.append("project_status", inputData.project_status);
      } else {
        formdata.append("project_status", "draft");
      }
      selectedManufacturers.forEach((manufacturer: any, index: number) => {
        if (manufacturer.id) {
          formdata.append(
            `product_manufactures[${index}][id]`,
            manufacturer.id,
          );
        }
        formdata.append(
          `product_manufactures[${index}][supplier_id]`,
          manufacturer.value,
        );
        formdata.append(
          `product_manufactures[${index}][project_manufactures_status]`,
          manufacturer.project_manufactures_status.value,
        );
      });
      const errors: any = {};
      if (!inputData.title) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        errors.title = "Title is required";
      }
      if (!inputData.description) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        errors.description = "Description is required";
      }
      if (!inputData.sales_owner_id) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        errors.sales_owner_id = "Sales owner  is required";
      }
      if (!inputData.project_status && id) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        errors.project_status = "Project status is required";
      }
      if (selectedManufacturers.length === 0) {
        setManufacturerError(true);
      } else {
        setManufacturerError(false);
      }

      const manufacturersValidations = selectedManufacturers.map(
        (manufacturer: any, index: number) => {
          const manufacturerErrors: any = {};

          if (!manufacturer.project_manufactures_status) {
            manufacturerErrors[
              `selectedManufacturers[${index}].project_manufactures_status`
            ] = "Status is required";
          }
          // Add similar validation for other manufacturer fields here

          return manufacturerErrors;
        },
      );

      // Merge errors from main form fields, product fields, and Select Manufacturers fields
      const allErrors = {
        ...errors,
        ...manufacturersValidations.reduce(
          (acc: any, curr: any) => ({ ...acc, ...curr }),
          {},
        ),
      };

      // Set form errors
      setFormErrors(allErrors);

      // Check if there are any errors
      if (Object.keys(allErrors).length > 0) {
        setToast("error", "Validation failed. Please check the form fields");
        return;
      }

      if (id) {
        formdata.append("_method", "put");
        const apiUrl = process.env.REACT_APP_API_URL as string;
        const BASE_URL = apiUrl + "api/";
        const token = getCookie("authToken");
        const headers = {
          Authorization: `Bearer ${token ? token.replace(/"/g, "") : ""}`,
        };

        const response = await fetch(`${BASE_URL}projects/${id}`, {
          method: "POST",
          headers: headers,
          body: formdata,
        });

        if (!response.ok) {
          const errorResponse = await response.json();
          throw new Error(errorResponse.message);
        }
        setToast("success", `Successfully Updated`);
      } else {
        await Projects.$query().store(formdata);
        setToast("success", `Successfully Added`);
      }
      navigate(PROJECTS_LIST);
    } catch (error: any) {
      if (error.response && error.response.data) {
        showErrorsInToasts(error.response.data.errors);
      } else {
        const errorMessage =
          error instanceof Error
            ? error.message
            : "An error occurred while processing your request";
        setToast("error", errorMessage);
      }
    } finally {
      setSubmitting(false);
    }
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const removeManufacturer = (index: number) => {
    const updatedManufacturers = [...selectedManufacturers];
    updatedManufacturers.splice(index, 1);
    setSelectedManufacturers(updatedManufacturers);
  };
  const handleRowViewButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    mid: number,
    sid: number,
  ) => {
    e.preventDefault();
    navigate(
      PROJECTS_MANUFACTURER_VIEW.replace(":id", id.toString())
        .replace(":mid", mid.toString())
        .replace(":sid", sid.toString()),
    );
  };
  const CustomFormSkeleton = () => (
    <div className="row p-2">
      <div className="col-12 py-2">
        <div className="skeleton skeleton-label">
          <CustomSkeleton
            count={1}
            height={20}
            width={"10%"}
            customOuterStyle={{ padding: "0" }}
          />
        </div>
        <div className="skeleton skeleton-input">
          <CustomSkeleton
            count={1}
            height={40}
            width={"100%"}
            customOuterStyle={{ padding: "0" }}
          />
        </div>
      </div>
      <div className="col-12 py-2">
        <div className="skeleton skeleton-label">
          <CustomSkeleton
            count={1}
            height={20}
            width={"10%"}
            customOuterStyle={{ padding: "0" }}
          />
        </div>
        <div className="skeleton skeleton-input">
          <CustomSkeleton
            count={1}
            height={40}
            width={"100%"}
            customOuterStyle={{ padding: "0" }}
          />
        </div>
      </div>
      <div className="col-12 py-2">
        <div className="row d-flex justify-content-between">
          <div className="col-2">
            <div className="skeleton skeleton-label">
              <CustomSkeleton
                count={1}
                height={20}
                width={"60%"}
                customOuterStyle={{ padding: "0" }}
              />
            </div>
            <div className="skeleton skeleton-select">
              <CustomSkeleton
                count={1}
                height={30}
                width={"100%"}
                customOuterStyle={{ padding: "0" }}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="skeleton skeleton-label">
              <CustomSkeleton
                count={1}
                height={20}
                width={"60%"}
                customOuterStyle={{ padding: "0" }}
              />
            </div>
            <div className="skeleton skeleton-select">
              <CustomSkeleton
                count={1}
                height={30}
                width={"100%"}
                customOuterStyle={{ padding: "0" }}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="skeleton skeleton-label">
              <CustomSkeleton
                count={1}
                height={20}
                width={"60%"}
                customOuterStyle={{ padding: "0" }}
              />
            </div>
            <div className="skeleton skeleton-select">
              <CustomSkeleton
                count={1}
                height={30}
                width={"100%"}
                customOuterStyle={{ padding: "0" }}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="skeleton skeleton-label">
              <CustomSkeleton
                count={1}
                height={20}
                width={"60%"}
                customOuterStyle={{ padding: "0" }}
              />
            </div>
            <div className="skeleton skeleton-select">
              <CustomSkeleton
                count={1}
                height={30}
                width={"100%"}
                customOuterStyle={{ padding: "0" }}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="skeleton skeleton-label">
              <CustomSkeleton
                count={1}
                height={20}
                width={"60%"}
                customOuterStyle={{ padding: "0" }}
              />
            </div>
            <div className="skeleton skeleton-select">
              <CustomSkeleton
                count={1}
                height={30}
                width={"100%"}
                customOuterStyle={{ padding: "0" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const ManufacturerSkeleton = () => (
    <tr>
      <td colSpan={5} className="p-0">
        <CustomSkeleton
          height={50}
          customOuterStyle={{ padding: "0", height: "50px" }}
          containerClassName={"d-grid"}
          customInnerStyle={{ borderRadius: "0" }}
        />
      </td>
    </tr>
  );
  return (
    <>
      <div className="d-flex justify-content-between">
        <h2> {!id ? "Add Project" : "Edit Project"}</h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <CustomButton label={`Back`} variant="secondary" onClick={GoBack} />
        </div>
      </div>
      {id && loading ? (
        <CustomFormSkeleton />
      ) : (
        <div className="row p-2">
          <Form onSubmit={() => {}}>
            <div className="col-12 py-2">
              <Form.Label>Title*</Form.Label>
              <Form.Control
                type="text"
                className="py-1"
                onChange={handleInputChange}
                name={"title"}
                value={formData.title}
                required
                placeholder="Enter Title"
              />
              {formErrors.title && (
                <p className="text-danger">{formErrors.title}</p>
              )}
            </div>
            <div className="col-12 py-2">
              <Form.Label>Description*</Form.Label>
              <Form.Control
                type="text"
                className="py-1"
                onChange={handleInputChange}
                name={"description"}
                value={formData.description}
                required
                placeholder="Enter Description"
              />
              {formErrors.description && (
                <p className="text-danger">{formErrors.description}</p>
              )}
            </div>
            <div className="col-12 py-2">
              <div className="row d-flex justify-content-between">
                <div className="col-2">
                  <label htmlFor="company_name">Buyer/Supplier</label>
                  <CustomAsyncSelect
                    customTheme={customTheme}
                    model={BuyersAndSuppliers}
                    label="Project Title"
                    inputfield={"company_name"}
                    fieldName={"company_name"}
                    isMultiSelect={false}
                    addNewBtnStatus={false}
                    formData={"company_name"}
                    customValue={id && formData.buyer_id}
                    onChange={(selectedOption: any) => {
                      setFormData({ ...formData, buyer_id: selectedOption });
                    }}
                  />
                </div>
                <div className="col-2">
                  <label htmlFor="company_name">Sales Owner*</label>
                  <CustomAsyncSelect
                    customTheme={customTheme}
                    model={SubUser}
                    label="Project Title"
                    inputfield={"first_name"}
                    fieldName={"company_name"}
                    isMultiSelect={false}
                    formData={"company_name"}
                    customValue={id && formData.sales_owner_id}
                    addNewBtnStatus={false}
                    onChange={(selectedOption: any) => {
                      setFormData({
                        ...formData,
                        sales_owner_id: selectedOption,
                      });
                      setFormErrors({ ...formErrors, sales_owner_id: "" });
                    }}
                  />
                  {formErrors.sales_owner_id && (
                    <p className="text-danger">{formErrors.sales_owner_id}</p>
                  )}
                </div>

                <div className="col-2">
                  <label htmlFor="company_name">Status*</label>
                  <CustomAsyncSelect
                    customTheme={customTheme}
                    model={BuyersAndSuppliers}
                    addNewBtnStatus={false}
                    defaultOptions={
                      id
                        ? [
                            { label: "Draft", value: "draft" },
                            {
                              label: "Ready To Send For Estimate",
                              value: "ready_to_send_for_estimate",
                            },
                            { label: "Close ", value: "close" },
                          ]
                        : [{ label: "Draft", value: "draft" }]
                    }
                    label="Project Title"
                    inputfield={"company_name"}
                    fieldName={"company_name"}
                    isMultiSelect={false}
                    formData={"company_name"}
                    customValue={
                      id
                        ? formData.project_status
                        : { label: "Draft", value: "draft" }
                    }
                    // isdisabled={!id && true}
                    onChange={(selectedOption: any) => {
                      setFormData({
                        ...formData,
                        project_status: selectedOption,
                      });
                      setFormErrors({ ...formErrors, project_status: "" });
                    }}
                  />
                  {formErrors.project_status && (
                    <p className="text-danger">{formErrors.project_status}</p>
                  )}
                </div>
                <div className="col-2">
                  <label htmlFor="lead_value">Project Value*</label>
                  <Form.Control
                    type="text"
                    className="py-1"
                    onChange={handleInputChange}
                    required
                    name={"project_value"}
                    placeholder="Enter Project Value"
                    value={formData.project_value}
                  />
                  {/* {formErrors.project_value && (
                    <p className="text-danger">{formErrors.project_value}</p>
                  )} */}
                </div>
                <div className="col-2">
                  <label htmlFor="expected_close_date">
                    Expected Close Date*
                  </label>
                  <DatePicker
                    wrapperClassName="d-flex"
                    selected={
                      new Date(formData.expected_closed_date) instanceof Date &&
                      !isNaN(new Date(formData.expected_closed_date).getTime())
                        ? formData.expected_closed_date
                        : ""
                    }
                    showYearDropdown
                    placeholderText={
                      dateFormat.replace("dd", "DD").split(" ")[0]
                    }
                    onChange={(date) => {
                      setFormData({ ...formData, expected_closed_date: date });
                    }}
                    dateFormat={
                      dateFormat?.replace("YYYY", "yyyy").split(" ")[0]
                    }
                    className="form-control"
                    onBlur={(e: any) => {
                      let enteredDate = e.target.value;
                      const dateFormatWithoutTime = dateFormat
                        .replace("dd", "DD")
                        .split(" ")[0];
                      enteredDate = enteredDate.replace(/\b(\d{1})\b/g, "0$1");
                      const isValid = moment(
                        enteredDate,
                        dateFormatWithoutTime,
                      ).isValid();
                      if (isValid) {
                        setFormErrors({
                          ...formErrors,
                          expected_closed_date: "",
                        });
                      } else {
                        setFormData({ ...formData, expected_closed_date: "" });
                        if (enteredDate.length > 0) {
                          setFormErrors({
                            ...formErrors,
                            expected_closed_date: `Please select a date in the correct format (${
                              dateFormat.replace("dd", "DD").split(" ")[0]
                            }).`,
                          });
                        } else {
                          setFormErrors({
                            ...formErrors,
                            expected_closed_date: "",
                          });
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </Form>
        </div>
      )}
      <CreateRow
        FormTableFields={productTableFields}
        setCardsData={setProducts}
        cardsData={products}
        initializedProduct={productDetails}
        formErrors={productFormErrors}
        updateStatus={isUpdated}
        cardShowStatus={true}
        cardName="Products"
        setFormErrors={setProductFormErrors}
      />
      <div className="row py-4">
        <h2>{id ? "Select Manufacturers" : "Add Manufacturers"}</h2>
        <Card>
          <Card.Body>
            {id && (
              <Table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Status</th>
                    {permissions[VIEW_PROJECT_MANUFACTURES_QUOTATION] && (
                      <th>
                        <span style={{ marginLeft: "100px" }}></span>
                        Actions
                      </th>
                    )}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {loadingForSelectedManufacturers ? (
                    <ManufacturerSkeleton />
                  ) : (
                    selectedManufacturers.map(
                      (manufacturer: any, index: number) => (
                        <tr key={index}>
                          <td style={{ width: "20%" }}>{index + 1}</td>
                          <td style={{ width: "20%" }}>{manufacturer.label}</td>
                          <td
                            style={{ width: "40%" }}
                            className="form-control-bottom-border"
                          >
                            <CustomAsyncSelect
                              customTheme={customTheme}
                              model={BuyersAndSuppliers}
                              addNewBtnStatus={false}
                              defaultOptions={[
                                { label: "Draft", value: "draft" },
                                { label: "Approved", value: "approved" },
                                { label: "Rejected ", value: "rejected" },
                                {
                                  label: "Requested For Quotation",
                                  value: "requested_for_quotation",
                                },
                              ]}
                              label="Project Title"
                              inputfield={"company_name"}
                              fieldName={"company_name"}
                              isMultiSelect={false}
                              formData={"company_name"}
                              customValue={
                                id && manufacturer.project_manufactures_status
                              }
                              onChange={(selectedOption: any) => {
                                handleManufacturerChange(
                                  selectedOption,
                                  index,
                                  false,
                                );
                              }}
                              isdisabled={disabledDraftRows[index]}
                            />
                          </td>
                          {permissions[VIEW_PROJECT_MANUFACTURES_QUOTATION] && (
                            <td style={{ width: "20%" }}>
                              <div style={{ marginLeft: "100px" }}>
                                <CustomViewButton
                                  id={index}
                                  onClick={(e) => {
                                    return handleRowViewButtonClick(
                                      e,
                                      manufacturer.id,
                                      manufacturer.value,
                                    );
                                  }}
                                  customFill={"green"}
                                />
                              </div>
                            </td>
                          )}
                          <td>
                            <span
                              onClick={() => removeManufacturer(index)}
                              style={{
                                cursor: "pointer",
                                color: "red",
                                marginRight: "50px",
                              }}
                            >
                              <XCircle />
                            </span>
                          </td>
                        </tr>
                      ),
                    )
                  )}
                </tbody>
              </Table>
            )}
            {id && <h5 className="my-3">Add Manufacturer</h5>}
            <div className="form-control-bottom-border">
              {id && loadingForSelectedManufacturers ? (
                <CustomSkeleton
                  height={40}
                  customOuterStyle={{ padding: "0" }}
                />
              ) : (
                <CustomAsyncSelect
                  customTheme={customTheme}
                  model={BuyersAndSuppliers}
                  addNewBtnStatus={false}
                  label="Project Title"
                  inputfield={"company_name"}
                  fieldName={"company_name"}
                  isMultiSelect={true}
                  formData={"company_name"}
                  customFilter={[
                    {
                      field: "company_type",
                      operator: "in",
                      value: ["supplier", "both"],
                    },
                  ]}
                  onChange={(selectedOption: any) =>
                    handleManufacturerChange(
                      selectedOption,
                      selectedManufacturers.length,
                      true,
                    )
                  }
                  customValue={id && selectedManufacturers}
                  // isClearable={false}
                />
              )}
            </div>
          </Card.Body>
        </Card>
        {manufacturerError && (
          <span className="text-danger">Manufacturers is required</span>
        )}
      </div>
      <div className="mb-4 d-flex justify-content-end mt-3">
        <CustomButton
          type="submit"
          label={"Submit"}
          variant="primary"
          onClick={handleSubmit}
          size="sm"
          disableStatus={submitting}
          spinnerStatus={submitting}
        />
      </div>
    </>
  );
};

export default AddUpdate;
