import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { BuyersAndSuppliers } from "../../Model/BuyersAndSuppliers/BuyersAndSuppliers";
import Card from "react-bootstrap/Card";
import { ProjectManufacturesQuotations } from "../../Model/Projects/ProjectManufacturesQuotations";
import CustomDataTable from "../../Components/CustomDataTable";
import { TableColumn } from "react-data-table-component";
import { fetchData } from "../../Util/OrionList";
import { useLocation, useNavigate } from "react-router-dom";
import { setToast } from "../../Util/Toast";
import CustomViewButton from "../../Components/Button/CustomViewButton";
import {
  PROJECTS_EDIT,
  PROJECTS_MANUFACTURER_QUOTATION_REQUESTS_VIEW,
} from "../../Routes/Project/Project";
import ConfirmationModal from "../../Components/ConfirmationModel";
import { CapitalizeAndRemoveUnderscore } from "../../Util/CapitalizeAndRemoveUnderscore";
import CustomButton from "../../Components/Button/CustomButton";

const ViewManufacturers = ({ permissions }: any) => {
  const { id, mid, sid }: any = useParams();
  const location = useLocation(); // Use useLocation hook here
  const searchParams = new URLSearchParams(location.search);
  const [manufacturerDetails, setManufacturerDetails] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [isArchive, setIsArchive] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedQuotationStatus, setSelectedQuotationStatus] = useState<any>(
    {},
  );

  const getManufacturer = async (itemId: any) => {
    const item = await BuyersAndSuppliers.$query()
      .with(["taxType"])
      .find(itemId);
    return item.$attributes;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data: any = await getManufacturer(sid);
      setManufacturerDetails(data);
    };
    fetchData();
    //eslint-disable-next-line
  }, [mid]);

  const handleCreateRow = async () => {
    const formdata: any = {
      project_id: id,
      project_manufactures_id: mid,
      status: "draft",
    };
    await ProjectManufacturesQuotations.$query().store(formdata);
    fetchQuotationRequests();
  };

  const sendQuotation = async (Id: any, status: any) => {
    try {
      setCustomSubmitting(true);
      const formdata: any = {
        id: Id,
        status,
      };
      let item: any = await ProjectManufacturesQuotations.$query().find(Id);
      item.$attributes = item.$attributes.id;
      item.$attributes = {
        ...item.$attributes.id,
        ...formdata,
      };
      item = await item.$save();
      await fetchQuotationRequests();
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  const copyLink = (data: any) => {
    const link = `${process.env.REACT_APP_URL}project-quotation-public/${data.token}`;
    navigator.clipboard.writeText(link);
    setToast("success", "Link copied to clipboard");
  };
  const openViewPage = (qid: any) => {
    navigate(
      PROJECTS_MANUFACTURER_QUOTATION_REQUESTS_VIEW.replace(
        ":id",
        id.toString(),
      )
        .replace(":qid", qid.toString())
        .replace(":mid", mid.toString()),
    );
  };
  const handleQuotationStatusChange = (quotationId: any, status: any) => {
    if (quotationId && status) {
      setSelectedQuotationStatus({ quotationId, status });
      setShowConfirmationModal(true);
    }
  };
  const handleConfirmation = async (status: any) => {
    await sendQuotation(status.quotationId, status.status);
    setShowConfirmationModal(false);
  };

  const columns: TableColumn<any>[] = [
    {
      name: "Id",
      width: "10%",
      cell: (row: any) => row.id,
    },
    {
      name: "Status",
      width: "10%",
      cell: (row: any) => CapitalizeAndRemoveUnderscore(row.status),
    },
    {
      name: "Quotation",
      width: "20%",
      cell: (row) => (
        <div className="">
          <select
            style={{ boxShadow: "none" }}
            className="form-select border-0"
            value={row.status} // Assuming row.status holds the current status
            onChange={(e) =>
              handleQuotationStatusChange(row.id, e.target.value)
            }
          >
            <option value="">Select Option</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>
      ),
    },
    {
      name: "Created At",
      width: "10%",
      cell: (row: any) => row.created_at,
    },
    {
      button: false,
      width: "42%",
      cell: (row) => (
        <div className="d-flex justify-content-end align-items-center">
          <button
            type="button"
            // className="btn btn-danger me-3"
            style={{ width: "85px", height: "max-content" }}
            className={`btn-sm me-3
            ${row.status === "draft" ? "btn btn-danger" : "btn btn-warning"}`}
            onClick={() => {
              sendQuotation(row.id, "sent");
            }}
          >
            {row.status === "draft" ? "Send" : "Resend"}
          </button>
          <button
            type="button"
            style={{ width: "130px", height: "max-content" }}
            className={`btn-sm me-3
              ${
                row.status === "sent" ? "btn btn-primary" : "btn btn-secondary"
              }`}
            onClick={() => {
              sendQuotation(row.id, "revoke_access");
            }}
            disabled={row.status === "sent" ? false : true}
          >
            Revoke Access
          </button>
          <button
            type="button"
            style={{ width: "auto", height: "max-content" }}
            // className="btn btn-success me-5 btn-sm"
            className={`btn btn-sm me-3
              ${row.status === "sent" ? "btn-success" : "btn btn-secondary"}`}
            onClick={() => {
              copyLink(row);
            }}
            disabled={
              row.status === "revoke_access" ||
              row.status === "submitted" ||
              row.status !== "sent"
                ? true
                : false
            }
          >
            Copy Link
          </button>
          {/* {row.status !== "draft" && row.status !== "sent" && (
            <CustomViewButton
              id={row.id}
              onClick={(e) => {
                openViewPage(row.id);
              }}
            />
          )} */}
        </div>
      ),
    },
    {
      name: "Actions",
      button: false,
      width: "8%",
      cell: (row) => (
        <div className="d-flex justify-content-end">
          {row.status !== "draft" && row.status !== "sent" && (
            <CustomViewButton
              id={row.id}
              onClick={(e) => {
                openViewPage(row.id);
              }}
              customFill={"green"}
            />
          )}
        </div>
      ),
    },
  ];
  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };
  const handleRowCreateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
  };

  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
    setIsArchive(false);
    fetchQuotationRequests();
  }, [isArchive, limit, page]); // eslint-disable-line
  const fetchQuotationRequests = async () => {
    setLoading(true);
    try {
      setLoading(true);
      await fetchData(
        page,
        limit,
        { project_id: { value: id }, project_manufactures_id: { value: mid } },
        // inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new ProjectManufacturesQuotations(),
        sortField,
        sortOrder,
        isArchive,
      );
      setLimit(limit);
      setLoading(false);
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };

  const GoBack = () => {
    // navigate(-1);
    navigate(PROJECTS_EDIT.replace(":id", id.toString()));
  };
  return (
    <>
      <div className="d-flex justify-content-between my-2">
        <h2 style={{ lineHeight: "0.9" }}>Project Manufacturer</h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <CustomButton label={`Back`} variant="secondary" onClick={GoBack} />
        </div>
      </div>
      <Card style={{ fontSize: "small" }}>
        <Card.Body className="p-0">
          <Card.Header style={{ fontSize: "20px", fontWeight: 500 }}>
            Manufacturer Details
          </Card.Header>
          {manufacturerDetails && (
            <div className="d-flex justify-content-between p-3">
              <span style={{ fontSize: "25px" }}>
                <Card.Text>
                  <strong>{manufacturerDetails.company_name}</strong>
                </Card.Text>
              </span>
              <span className="d-flex">
                <span style={{ marginRight: "50px" }}>
                  <Card.Text style={{ marginBottom: "3px" }}>
                    Company Type:{" "}
                    <strong>
                      {manufacturerDetails.company_type
                        .charAt(0)
                        .toUpperCase() +
                        manufacturerDetails.company_type.slice(1)}
                    </strong>
                  </Card.Text>
                  <Card.Text style={{ marginBottom: "3px" }}>
                    Tax Type:{" "}
                    <strong>{manufacturerDetails.tax_type.title}</strong>
                  </Card.Text>
                </span>
                <span>
                  <Card.Text style={{ marginBottom: "3px" }}>
                    Tax: <strong>{manufacturerDetails.tax_number}</strong>
                  </Card.Text>
                </span>
              </span>
              <span style={{ marginRight: "100px" }}>
                <Card.Title>Contact Details</Card.Title>
                <Card.Text>
                  <span className="me-3">{manufacturerDetails.email}</span>
                  <span>{manufacturerDetails.phone_no}</span>
                  {/* <strong>Email:</strong> {manufacturerDetails.email} <br />
                  <strong>Contact:</strong> {manufacturerDetails.phone_no} */}
                </Card.Text>
              </span>
            </div>
          )}
        </Card.Body>
      </Card>

      <div className="row py-4">
        <h2>Quotation Requests</h2>
        <Card>
          <Card.Body>
            <CustomDataTable
              columns={columns}
              data={data}
              progressPending={loading}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              handleRowCreateButtonClick={handleRowCreateButtonClick}
              currentPages={page}
              currentRowsPerPage={limit}
              customSkeletonCount={5}
            />
            {permissions.add && !loading && (
              <button className="btn btn-primary" onClick={handleCreateRow}>
                Create
              </button>
            )}
          </Card.Body>
        </Card>
      </div>

      <ConfirmationModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        title="Confirmation"
        message={`Are you sure you want to ${
          selectedQuotationStatus.status === "approved"
            ? "approve"
            : selectedQuotationStatus.status === "rejected"
            ? "reject"
            : ""
        } this quotation?`}
        onConfirm={() => handleConfirmation(selectedQuotationStatus)}
        confirmButtonColor={
          selectedQuotationStatus.status === "approved"
            ? "success"
            : selectedQuotationStatus.status === "rejected"
            ? "danger"
            : "primary"
        }
        spinnerStatus={customSubmitting ? customSubmitting : false}
      />
    </>
  );
};

export default ViewManufacturers;
