import axios from "axios";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import { Model } from "@tailflow/laravel-orion/lib/model";
import { LOGOUT } from "../Routes/AppRoutes";
import { ERROR_HANDLER } from "../Routes/Errorhandler/ErrorHandler";

export async function fetchData(
  page: any,
  limit: any,
  inputValues: any,
  setLoading: any,
  setData: any,
  setTotalRows: any,
  navigate: any,
  Model: Model,
  sortField?: any,
  sortOrder?: any,
  isArchive?: any,
  includeWith?: any,
  selectedOrganizationId?: any,
) {
  try {
    setLoading(true);
    let query: any = Model.$query();

    for (const key in inputValues) {
      if (inputValues.hasOwnProperty(key)) {
        const nestedObject = inputValues[key] as { [key: string]: string };
        if (nestedObject) {
          // @ts-ignore
          // const { value, filter_operation } = nestedObject[subKey];
          const { value, filter_operation } = nestedObject;
          let filterValue = value;
          if (!filterValue) {
            return;
          }
          if (filter_operation === FilterOperator.Like) {
            filterValue = "%" + value + "%";
          }
          query = query.filter(key, nestedObject.filter_operation, filterValue);
        }
      }
    }
    if (sortField && sortOrder) {
      sortField = sortField.toString().trim();

      const idMatch = sortField.match(/row\s*=>\s*row\.(\w+)/);
      if (idMatch && idMatch.length === 2) {
        sortField = idMatch[1];
      }
      query = query.sortBy(sortField, sortOrder);
    }
    query.httpClient.baseUrl = selectedOrganizationId
      ? (await query.httpClient.baseUrl) +
        "/search?organization_id=" +
        selectedOrganizationId
      : query.httpClient.baseUrl;
    if (isArchive) {
      query.onlyTrashed();
    }
    if (includeWith?.length > 0) {
      query.with(includeWith);
    }
    const modelData: any = await query.search(limit, page);
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set("page", String(page));
    searchParams.set("perPage", String(limit));

    navigate(`?${searchParams.toString()}`);

    setData(modelData[0]?.$response?.data?.data);
    setTotalRows(modelData[0]?.$response?.data?.meta?.total);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          navigate(LOGOUT);
        } else if (statusCode === 500 || statusCode === 404) {
          navigate(
            ERROR_HANDLER.replace(":code", statusCode.toString()).replace(
              ":msg",
              error.response.data.message.toString(),
            ),
          );
        }
      }
    }
  } finally {
    setLoading(false);
  }
}
