import { RouteObject } from "react-router-dom";
import List from "../../Pages/Payment/List";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_PAYMENT,
  DELETE_PAYMENT,
  RESTORE_PAYMENT,
  UPDATE_PAYMENT,
  VIEW_PAYMENT,
} from "../../Util/PermissionList";
import AddUpdateForTexInvoice from "../../Pages/Payment/AddUpdateForTexInvoice";
import AddUpdateForPurchaseOrder from "../../Pages/Payment/AddUpdateForPurchaseOrder";

export const PAYMENT_TITLE = "Payment";
export const PAYMENT_LIST = "/Payment";
export const PAYMENT_ADD = "/Payment/add";
export const PAYMENT_ADD_FOR_TAX_INVOICE = "/Payment/add/tax-invoice";
export const PAYMENT_ADD_FOR_PURCHASE_ORDER = "/Payment/add/purchase-order";
export const PAYMENT_WTH_INVOICE = "/Payment/add/tax-invoice/:invoiceid";
export const PAYMENT_WTH_PURCHASE_ORDER =
  "/Payment/add/purchase-order/:purchaseid";
export const PAYMENT_EDIT_FOR_TAX_INVOICE = "/Payment/:id/tax-invoice/edit";
export const PAYMENT_EDIT_FOR_PURCHASE_ORDER =
  "/Payment/:id/purchase-order/edit";
export const PAYMENT_VIEW = "/Payment/:id/view";
export const PAYMENT_ARCHIVE = "/Payment/archive";

const Payment: RouteObject[] = [
  {
    path: PAYMENT_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_PAYMENT}
        checkPermisson={{
          addPermission: ADD_PAYMENT,
          updatePermission: UPDATE_PAYMENT,
          deletePermission: DELETE_PAYMENT,
          viewPermission: VIEW_PAYMENT,
          restorePermission: RESTORE_PAYMENT,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: PAYMENT_ADD_FOR_TAX_INVOICE,
    element: (
      <AuthorizedRoute
        element={<AddUpdateForTexInvoice />}
        permission={ADD_PAYMENT}
        checkPermisson={{
          addPermission: ADD_PAYMENT,
          updatePermission: UPDATE_PAYMENT,
          deletePermission: DELETE_PAYMENT,
          viewPermission: VIEW_PAYMENT,
          restorePermission: RESTORE_PAYMENT,
        }}
      />
    ),
  },
  {
    path: PAYMENT_ADD_FOR_PURCHASE_ORDER,
    element: (
      <AuthorizedRoute
        element={<AddUpdateForPurchaseOrder />}
        permission={ADD_PAYMENT}
        checkPermisson={{
          addPermission: ADD_PAYMENT,
          updatePermission: UPDATE_PAYMENT,
          deletePermission: DELETE_PAYMENT,
          viewPermission: VIEW_PAYMENT,
          restorePermission: RESTORE_PAYMENT,
        }}
      />
    ),
  },
  {
    path: PAYMENT_WTH_PURCHASE_ORDER,
    element: (
      <AuthorizedRoute
        element={<AddUpdateForPurchaseOrder />}
        permission={ADD_PAYMENT}
        checkPermisson={{
          addPermission: ADD_PAYMENT,
          updatePermission: UPDATE_PAYMENT,
          deletePermission: DELETE_PAYMENT,
          viewPermission: VIEW_PAYMENT,
          restorePermission: RESTORE_PAYMENT,
        }}
      />
    ),
  },
  {
    path: PAYMENT_WTH_INVOICE,
    element: (
      <AuthorizedRoute
        element={<AddUpdateForTexInvoice />}
        permission={ADD_PAYMENT}
        checkPermisson={{
          addPermission: ADD_PAYMENT,
          updatePermission: UPDATE_PAYMENT,
          deletePermission: DELETE_PAYMENT,
          viewPermission: VIEW_PAYMENT,
          restorePermission: RESTORE_PAYMENT,
        }}
      />
    ),
  },
  {
    path: PAYMENT_EDIT_FOR_PURCHASE_ORDER,
    element: (
      <AuthorizedRoute
        element={<AddUpdateForPurchaseOrder />}
        exception={true}
        checkPermisson={{
          addPermission: ADD_PAYMENT,
          updatePermission: UPDATE_PAYMENT,
          deletePermission: DELETE_PAYMENT,
          viewPermission: VIEW_PAYMENT,
          restorePermission: RESTORE_PAYMENT,
        }}
        permission={UPDATE_PAYMENT}
      />
    ),
  },
  {
    path: PAYMENT_EDIT_FOR_TAX_INVOICE,
    element: (
      <AuthorizedRoute
        element={<AddUpdateForTexInvoice />}
        exception={true}
        checkPermisson={{
          addPermission: ADD_PAYMENT,
          updatePermission: UPDATE_PAYMENT,
          deletePermission: DELETE_PAYMENT,
          viewPermission: VIEW_PAYMENT,
          restorePermission: RESTORE_PAYMENT,
        }}
        permission={UPDATE_PAYMENT}
      />
    ),
  },
  {
    path: PAYMENT_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          addPermission: ADD_PAYMENT,
          updatePermission: UPDATE_PAYMENT,
          deletePermission: DELETE_PAYMENT,
          viewPermission: VIEW_PAYMENT,
          restorePermission: RESTORE_PAYMENT,
        }}
        permission={DELETE_PAYMENT}
      />
    ),
  },
  // {
  //   path: PAYMENT_VIEW,
  //   element: (
  //     <AuthorizedRoute
  //       element={<ViewProject />}
  //       exception={true}
  //       checkPermisson={{
  //         addPermission: ADD_PAYMENT,
  //         updatePermission: UPDATE_PAYMENT,
  //         deletePermission: DELETE_PAYMENT,
  //         viewPermission: VIEW_PAYMENT,
  //         restorePermission: RESTORE_PAYMENT,
  //       }}
  //       permission={VIEW_PAYMENT}
  //     />
  //   ),
  // },
];

export default Payment;
