import React from "react";
import { Button } from "react-bootstrap";
import CustomSpinner from "../../Util/Spinner";

interface CustomButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label: React.ReactNode;
  variant?: string;
  style?: React.CSSProperties;
  width?: string;
  size?: "sm" | "lg" | undefined;
  type?: "button" | "submit" | "reset";
  customeClass?: string;
  customeStyle?: React.CSSProperties;
  disableStatus?: boolean;
  spinnerStatus?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  onClick,
  label,
  variant,
  type = "button", // Default type
  width,
  size,
  customeClass = "",
  customeStyle,
  style,
  disableStatus,
  spinnerStatus,
}) => {
  const buttonStyle = {
    height: "37px",
    borderRadius: "4px",
    width: width || "auto", // Use auto width if no width is provided
    backgroundColor: variant ? undefined : "#1F79B4",
    ...style, // Include custom style prop
  };

  return (
    <Button
      variant={variant}
      type={type}
      onClick={onClick}
      style={customeStyle ? customeStyle : !variant ? buttonStyle : {}}
      className={`btn btn-sm ${customeClass} ms-2`}
      size={size}
      disabled={disableStatus}
    >
      {spinnerStatus ? (
        <>
          {label} <CustomSpinner />
        </>
      ) : (
        label
      )}
    </Button>
  );
};

export default CustomButton;
