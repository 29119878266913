import { RouteObject } from "react-router-dom";
import AddUpdate from "../../Pages/Setting/Approvals/AddUpdate";

export const APPROVALS_TITLE = "Approval";
export const APPROVALS_LIST = "/approvals";

const Approvals: RouteObject[] = [
  { path: APPROVALS_LIST, element: <AddUpdate /> },
];

export default Approvals;
