import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge, Button, Col, Modal, Row } from "react-bootstrap";
import CustomFilter, { InputValues } from "../../../Components/CustomFilter";
import { Country } from "../../../Model/Setting/Country";
import { TaxType } from "../../../Model/Setting/TaxType";
import CustomButton from "../../../Components/Button/CustomButton";
import AddUpdateModel from "../../BuyersAndSuppliers/Components/Modal";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import CustomAsyncSelect from "../../../Components/AsyncSelect";
import { BuyersAndSuppliers } from "../../../Model/BuyersAndSuppliers/BuyersAndSuppliers";
import { setToast } from "../../../Util/Toast";
import { fetchData } from "../../../Util/OrionList";
import CustomDataTable from "../../../Components/CustomDataTable";
import { TableColumn } from "react-data-table-component";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import {
  PROFORMAINVOICES_ADD,
  PROFORMAINVOICES_TITLE,
  PROFORMAINVOICES_EDIT,
  PROFORMAINVOICES_VIEW,
} from "../../../Routes/SalesAndPurchase/ProformaInvoices";
import { ProformaInvoice } from "../../../Model/SalesAndPurchase/ProformaInvoices/ProformaInvoice";
import CustomEditButton from "../../../Components/Button/CustomEditButton";
import CustomViewButton from "../../../Components/Button/CustomViewButton";
import CustomDownloadButton from "../../../Components/Button/CustomDownloadButton";
import { getCookie } from "typescript-cookie";
import { CapitalizeAndRemoveUnderscore } from "../../../Util/CapitalizeAndRemoveUnderscore";
import pluralize from "pluralize";
interface ProformaInvoicesItem {
  id: number;
  document_number: string;
  description: string;
  status: string;
  days: number;
  delivery_date: string;
  created_at: string;
  counter_party_company: any;
  manufacture: any;
}

function List({ permissions }: any) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState<ProformaInvoicesItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status

  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showAddUpdateModel, setShowAddUpdateModel] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [includeWith] = useState(["counterPartyCompany", "manufacture"]);
  const [isArchive] = useState<boolean>(false);

  const handleRowViewButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(PROFORMAINVOICES_VIEW.replace(":id", id.toString()));
  };

  const handleRowCreateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(PROFORMAINVOICES_ADD);
  };

  const handleCompanyDetailsClose = () => setShowCompanyModal(false);
  const handleAddCompanyDetailsClose = () => setShowAddUpdateModel(false);

  const companyFormFields = [
    {
      id: "inputCompanyType",
      label: "Company Type*",
      type: "select",
      col: 4,
      name: "company_type",
      addNewBtnStatus: false,
      options: [
        { label: "Buyer", value: "buyer" },
        { label: "Supplier", value: "supplier" },
        { label: "Both", value: "both" },
      ],
      placeholder: "Select an Company Type",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Company Type is Required",
        },
      ],
      description:
        "Select the type of business entity: Buyer, Supplier, or Both.",
    },
    {
      id: "inputCompanyName",
      label: "Company Name*",
      type: "text",
      col: 4,
      name: "company_name",
      placeholder: "Enter Your Company Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Company Name is Required",
        },
      ],
      description:
        "Enter the name of the company, ensuring accuracy and consistency.",
    },
    {
      id: "inputEmail",
      label: "Email*",
      type: "text",
      col: 4,
      name: "email",
      placeholder: "Enter Your Email",
      validate: [
        {
          rule: (value: any) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
          message: "Enter a valid email address",
        },
        {
          rule: (value: any) => !!value,
          message: "Email is Required",
        },
      ],
      description:
        "Provide the email address associated with the company for communication.",
    },
    {
      id: "inputTaxNumber",
      label: "Tax Number*",
      type: "text",
      col: 4,
      name: "tax_number",
      placeholder: "Enter Your Tax Number",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Tax Number is Required",
        },
      ],
      description:
        "Include the Value Added Tax (VAT) number for taxation purposes.",
    },
    {
      id: "inputaddress_line1",
      label: "Address Line 1*",
      type: "text",
      col: 4,
      name: "address_line1",
      placeholder: "Enter Your Address Line 1",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Address Line 1 is Required",
        },
      ],
      description: "Specify the physical address of the company.",
    },
    {
      id: "inputaddress_line2",
      label: "Address Line 2",
      type: "text",
      col: 4,
      name: "address_line2",
      placeholder: "Enter Your Address Line 2",
    },
    {
      id: "inputPinCode",
      label: "Zip Code*",
      type: "text",
      col: 4,
      name: "zip_code",
      placeholder: "Enter Your Zip Code",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Zip Code is Required",
        },
      ],
      description:
        "Enter the postal code or Zip code associated with the company's location.",
    },
    {
      id: "inputCity",
      label: "City*",
      type: "text",
      col: 4,
      name: "city",
      placeholder: "Enter Your City",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "City is Required",
        },
      ],
      description: "Provide the city in which the company is situated.",
    },
    {
      id: "inputState",
      label: "State*",
      type: "text",
      col: 4,
      name: "state",
      placeholder: "Enter Your State",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "State is Required",
        },
      ],
      description: "Specify the state or region of the company's location.",
    },
    {
      id: "inputcompany_tcs_setting",
      label: "Company Tax Collection At Source Setting*",
      type: "select",
      col: 4,
      name: "company_tcs_setting",
      addNewBtnStatus: false,
      placeholder: "Select an Company Tax Collection At Source Setting",
      options: [
        { label: "None", value: "none" },
        { label: "Purchase", value: "purchase" },
        { label: "Sales ", value: "sales" },
        { label: "Both", value: "both" },
      ],
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Company Tax Collection At Source Setting is Required",
        },
      ],
      description:
        "Select the Tax Collection at Source (TCS) setting for the company.",
    },
    {
      id: "inputcountry_id",
      label: "Country*",
      type: "select",
      col: 4,
      name: "country_id",
      placeholder: "Select an Country",
      searchField: "name",
      model: Country,
      addNewBtnStatus: false,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Country is Required",
        },
      ],
      description: "Choose the country in which the company is located.",
    },
    {
      id: "inputtax_types_id",
      label: "Tax Type*",
      type: "select",
      col: 4,
      name: "tax_type_id",
      placeholder: "Select an Tax Type",
      searchField: "title",
      model: TaxType,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Tax Type is Required",
        },
      ],
      description:
        "Select the applicable tax type for the company, such as GST, VAT, etc.",
    },
  ];

  const handleCompanyCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    //  Creating a new object with the updated values
    const updatedData = {
      ...formData,
      tax_type_id: parseInt(formData.tax_type_id.value),
      country_id: parseInt(formData.country_id.value),
      company_type: formData.company_type.value,
      company_tcs_setting: formData.company_tcs_setting.value,
    };
    try {
      const response = await BuyersAndSuppliers.$query().store(updatedData);
      setToast("success", `Company Successfully Added`);
      const Id: number = response.$attributes.id as number;
      navigate(PROFORMAINVOICES_ADD.replace(":id", Id.toString()));
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);
  const handleRowEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(PROFORMAINVOICES_EDIT.replace(":updateid", id.toString()));
  };

  const columns: TableColumn<ProformaInvoicesItem>[] = [
    {
      name: "Id",
      cell: (row) => row.id,
    },
    {
      name: "Document Number",
      cell: (row) => row.document_number,
    },
    {
      name: "Manufacturer",
      cell: (row) => row.manufacture?.company_name,
    },
    {
      name: "Buyer Name",
      cell: (row) => row.counter_party_company?.company_name,
    },
    {
      name: "Status",
      cell: (row) => (
        <Badge pill bg={row.status === "draft" ? "secondary" : "primary"}>
          {CapitalizeAndRemoveUnderscore(row.status)}
        </Badge>
      ),
    },
    {
      name: "Created At",
      cell: (row: any) => row.created_at,
    },
    {
      name: "Updated At",
      cell: (row: any) => row.updated_at,
    },
    {
      name: "Download",
      button: true,
      cell: (row: any) => (
        <div>
          <CustomDownloadButton
            id={row.id}
            onClick={handlePDFDownloadButtonClick}
          />
        </div>
      ),
    },
    {
      name: "Actions",
      button: true,
      cell: (row: any) => (
        <div>
          <CustomViewButton
            id={row.id}
            onClick={(e) => {
              return handleRowViewButtonClick(e, row.id);
            }}
          />
          {permissions.update && (
            <CustomEditButton
              id={row.id}
              isDisabled={row.status === "sent" && true}
              onClick={handleRowEditButtonClick}
            />
          )}
        </div>
      ),
    },
  ];
  const authToken = getCookie("authToken");
  const token = authToken ? authToken.replace(/["']/g, "") : "";
  const handlePDFDownloadButtonClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    try {
      const apiUrl =
        process.env.REACT_APP_API_URL + `api/proforma-invoice/pdf/`;
      const response = await fetch(apiUrl + id, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        const anchor = document.createElement("a");
        anchor.href = responseData.data;
        anchor.download = "purchase order";
        anchor.rel = "noreferrer";
        anchor.target = "_blank";
        anchor.click();
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } catch (error) {
      setToast("error", "An error occurred while processing your request");
    }
  };
  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "document_number",
      value: "document_number",
      label: "Document Number",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "manufacture.company_name",
      value: "manufacture.company_name",
      label: "Manufacturer",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "counterPartyCompany.company_name",
      value: "counterPartyCompany.company_name",
      label: "Buyer Name",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "status",
      value: "status",
      label: "Status",
      fieldType: "select",
      fieldOption: {
        All: "",
        Draft: "draft",
        Sent: "sent",
      },
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
    {
      id: "updated_at",
      value: "updated_at",
      label: "Updated Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
  ];
  const fetchSalesQuotation = async () => {
    try {
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new ProformaInvoice(),
        sortField,
        sortOrder,
        isArchive,
        includeWith,
      );
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };
  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  useEffect(() => {
    fetchSalesQuotation();
  }, [limit, page]); // eslint-disable-line

  return (
    <>
      <Row className="align-items-center">
        <Col className="col-10">
          <div className="d-flex justify-content-start">
            <div style={{ display: "inline-block" }}>
              <h6 className="mt-1">{pluralize(PROFORMAINVOICES_TITLE)} </h6>
            </div>
            <CustomFilter
              options={filterOptions}
              inputValues={inputValues}
              setInputValues={setInputValues}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              getData={fetchSalesQuotation}
              setPage={setPage}
            />
          </div>
        </Col>
        <Col className="col-2 text-xxl-end text-xl-end text-lg-end text-md-start text-sm-start text-xs-start">
          {permissions.add && (
            <CustomButton
              label={`Add`}
              variant="success"
              onClick={handleRowCreateButtonClick}
            />
          )}
        </Col>
      </Row>
      <div className="pb-4">
        <CustomDataTable
          columns={columns}
          data={data}
          progressPending={loading}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          handleRowCreateButtonClick={handleRowCreateButtonClick}
          currentPages={page}
          currentRowsPerPage={limit}
          module={"ProformaInvoice"} // Pass optional fields to CustomDataTable
        />
      </div>
      <Modal
        dialogClassName="custom-modal"
        className="mt-3"
        size="lg"
        show={showCompanyModal}
        onHide={handleCompanyDetailsClose}
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title>Select Company</Modal.Title>
          <Button
            className="btn btn-secondary"
            onClick={() => setShowAddUpdateModel(true)}
          >
            Add New Company
          </Button>
        </Modal.Header>

        <Modal.Body>
          <CustomAsyncSelect
            model={BuyersAndSuppliers}
            inputfield={"company_name"}
            fieldName={"company_name"}
            isMultiSelect={false}
            formData={"company_name"}
            label={"Select Company"}
            addNewBtnStatus={false}
            onChange={(selectedOption) => {
              navigate(
                PROFORMAINVOICES_ADD.replace(
                  ":id",
                  selectedOption.value.toString(),
                ),
              );
            }}
          />
        </Modal.Body>
      </Modal>
      <AddUpdateModel
        title={`Add Company`}
        show={showAddUpdateModel}
        size={"xl"}
        handleClose={() => {
          handleAddCompanyDetailsClose();
          setFormError(null);
        }}
        formFields={companyFormFields}
        widget={
          <AddUpdateForm
            formFields={companyFormFields}
            model={BuyersAndSuppliers}
            title="Buyers&Suppliers"
            customTitle=" "
            customFormSubmit={handleCompanyCustomSubmit}
            isBackBtnDisable={true}
            dynamicFormError={formError}
            customSubmitting={customSubmitting}
          />
        }
      />
    </>
  );
}

export default List;
