import "bootstrap/dist/css/bootstrap.min.css";
import "../Style/Navbar.css";
import { Navbar, Nav, Modal } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import NavLink from "react-bootstrap/NavLink";
import NavItem from "react-bootstrap/NavItem";
import Image from "react-bootstrap/Image";
import { getUser, removeToken } from "../Util/Auth";
import { USER_TYPE_TITLE, USER_TYPE_LIST } from "../Routes/Setting/UserType";
import { TAX_TYPE_TITLE, TAX_TYPE_LIST } from "../Routes/Setting/TaxType";
import { STORE_TITLE, STORE_LIST } from "../Routes/Setting/Store";
import { ROLE_TITLE, ROLE_LIST } from "../Routes/Setting/Role";
import {
  PAYMENTTERMS_TITLE,
  PAYMENTTERMS_LIST,
} from "../Routes/Setting/PaymentTerms";
import {
  BANK_DETAILS_TITLE,
  BANK_DETAILS_LIST,
} from "../Routes/Setting/BankDetails";
import { ATTRIBUTE_TITLE, ATTRIBUTE_LIST } from "../Routes/Setting/Attributes";
import { USER_LIST, USER_TITLE } from "../Routes/Setting/User";
import {
  LOGISTICDETAILS_LIST,
  LOGISTICDETAILS_TITLE,
} from "../Routes/Setting/LogisticDetail";
import {
  TERMSANDCONDITION_LIST,
  TERMSANDCONDITION_TITLE,
} from "../Routes/Setting/TermsAndCondition";
import { APPROVALS_LIST, APPROVALS_TITLE } from "../Routes/Setting/Approvals";
import { PRODUCT_LIST, PRODUCT_TITLE } from "../Routes/Inventory/Product";
import {
  UNIT_OF_MEASUREMENT_LIST,
  UNIT_OF_MEASUREMENT_TITLE,
} from "../Routes/Setting/UnitOfMeasurement";
import { CATEGORY_LIST, CATEGORY_TITLE } from "../Routes/Inventory/Category";
import { TAX_LIST, TAX_TITLE } from "../Routes/Setting/Tax";
// import { MATERIAL_LIST, MATERIAL_TITLE } from "../Routes/Inventory/Material";
import { PROFILE } from "../Routes/Profile/Profile";
import {
  BUYERS_AND_SUPPLIERS_LIST,
  BUYERS_AND_SUPPLIERS_TITLE,
} from "../Routes/BuyersAndSuppliers/BuyersAndSuppliers";
import {
  DOCUMENT_NUMBER_FORMAT_LIST,
  DOCUMENT_NUMBER_FORMAT_TITLE,
} from "../Routes/Setting/DocumentNumberFormat";
import SearchField from "./SearchField";
import {
  COUNTER_PARTY_TAG_LIST,
  COUNTER_PARTY_TAG_TITLE,
} from "../Routes/Setting/CounterPartyTag";
import { useState } from "react";
// import { DOCUMENT_LIST, DOCUMENT_TITLE } from "../Routes/Setting/Document";
import {
  BATCH_MANAGEMENT_LIST,
  BATCH_MANAGEMENT_TITLE,
} from "../Routes/SalesAndPurchase/BatchManagement/BatchManagement";
import {
  PROFORMAINVOICES_LIST,
  PROFORMAINVOICES_TITLE,
} from "../Routes/SalesAndPurchase/ProformaInvoices";
import {
  TAX_INVOICE_LIST,
  TAX_INVOICE_TITLE,
} from "../Routes/SalesAndPurchase/TaxInvoice";
import {
  PURCHASE_LIST,
  PURCHASE_TITLE,
} from "../Routes/SalesAndPurchase/Purchase";
import {
  INWARD_DOCUMENT_LIST,
  INWARD_DOCUMENT_TITLE,
} from "../Routes/SalesAndPurchase/InwardDocument";

import {
  SALES_QUOTATION_LIST,
  SALES_QUOTATION_TITLE,
} from "../Routes/SalesAndPurchase/SalesQuotation";
import { ADDRESS_LIST, ADDRESS_TITLE } from "../Routes/Setting/Address";
import {
  ORGANIZATION_TITLE,
  ORGANIZATION_VIEW,
} from "../Routes/Organization/Organization";
import { COUNTRIES_LIST, COUNTRIES_TITLE } from "../Routes/Setting/Country";
import { CURRENCY_LIST, CURRENCY_TITLE } from "../Routes/Setting/Currency";
import { PROJECTS_LIST, PROJECTS_TITLE } from "../Routes/Project/Project";
import {
  STOCK_INVENTORY_LIST,
  STOCK_INVENTORY_TITLE,
} from "../Routes/Inventory/StockInventory";
import { USER_GROUP_LIST, USER_GROUP_TITLE } from "../Routes/Setting/UserGroup";
import AddUpdateBuyesANDSuppliers from "../Pages/BuyersAndSuppliers/Components/AddUpdateBuyesANDSuppliers";
import { clearLocalStorage } from "../Util/LocalStorage";
import {
  SALES_RETURN_LIST,
  SALES_RETURN_TITLE,
} from "../Routes/SalesAndPurchase/SalesReturn";
import { PAYMENT_LIST, PAYMENT_TITLE } from "../Routes/Payment/Payment";
import {
  REPORTS_PURCHASE,
  REPORTS_PURCHASE_TITLE,
  REPORTS_SALES,
  REPORTS_SALES_TITLE,
  REPORTS_TITLE,
} from "../Routes/Reports/Reports";
import {
  INGREDIENTS_LIST,
  INGREDIENTS_TITLE,
} from "../Routes/Setting/Ingredients";
function NavBarLayout() {
  const navigate = useNavigate();
  const user: any = getUser();

  const [modalStatus, setModalStatus] = useState<any>({
    buyersAndSupplierStatus: false,
  });
  const [showSearchModal, setShowSearchModal] = useState(false);
  const handleSearchClick = () => {
    setShowSearchModal(true);
  };
  const handleCloseSearchModal = () => {
    setShowSearchModal(false);
  };
  const logout = () => {
    navigate("/login");
    removeToken();
    clearLocalStorage();
  };

  return (
    <>
      <Navbar
        bg="light"
        expand="lg"
        className="text-nowrap py-1 align-items-center py-2"
      >
        <Navbar.Brand as={Link} to="/" style={{ paddingLeft: "40px" }}>
          <img
            src="/images/svg/ic_logo.svg"
            width="130"
            height="30"
            className="d-inline-block align-top align-self-center"
            alt="Logo"
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="ml-auto fw-bolder" style={{ paddingRight: "40px" }}>
            <Nav.Link as={Link} to={"/"}>
              Dashboard
            </Nav.Link>
            <Nav.Link onClick={handleSearchClick}>Shortcuts</Nav.Link>
            <Nav.Link as={Link} to={BUYERS_AND_SUPPLIERS_LIST}>
              {BUYERS_AND_SUPPLIERS_TITLE}
            </Nav.Link>
            <Nav.Link as={Link} to={PROJECTS_LIST}>
              {PROJECTS_TITLE}
            </Nav.Link>
            <NavDropdown title="Sales & Purchase" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to={BATCH_MANAGEMENT_LIST}>
                {BATCH_MANAGEMENT_TITLE}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={PROFORMAINVOICES_LIST}>
                {PROFORMAINVOICES_TITLE}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={TAX_INVOICE_LIST}>
                {TAX_INVOICE_TITLE}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={SALES_QUOTATION_LIST}>
                {SALES_QUOTATION_TITLE}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={PURCHASE_LIST}>
                {PURCHASE_TITLE}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={INWARD_DOCUMENT_LIST}>
                {INWARD_DOCUMENT_TITLE}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={SALES_RETURN_LIST}>
                {SALES_RETURN_TITLE}
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Inventory" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to={PRODUCT_LIST}>
                {PRODUCT_TITLE}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={CATEGORY_LIST}>
                {CATEGORY_TITLE}
              </NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to={MATERIAL_LIST}>
                {MATERIAL_TITLE}
              </NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to={STOCK_INVENTORY_LIST}>
                {STOCK_INVENTORY_TITLE}
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={REPORTS_TITLE} id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to={REPORTS_SALES}>
                {REPORTS_SALES_TITLE}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={REPORTS_PURCHASE}>
                {REPORTS_PURCHASE_TITLE}
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to={PAYMENT_LIST}>
              {PAYMENT_TITLE}
            </Nav.Link>
            <NavDropdown
              title="Settings"
              id="basic-nav-dropdown"
              className="setting-mega-menu-outer"
            >
              <div className="setting-mega-menu-inner">
                <div>
                  <h6 className="mx-3 mb-0">
                    <strong> Access Control </strong>
                  </h6>
                  <NavDropdown.Item as={Link} to={USER_LIST}>
                    {USER_TITLE}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={ROLE_LIST}>
                    {ROLE_TITLE}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={USER_TYPE_LIST}>
                    {USER_TYPE_TITLE}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={ATTRIBUTE_LIST}>
                    {ATTRIBUTE_TITLE}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={COUNTRIES_LIST}>
                    {COUNTRIES_TITLE}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={CURRENCY_LIST}>
                    {CURRENCY_TITLE}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={USER_GROUP_LIST}>
                    {USER_GROUP_TITLE}
                  </NavDropdown.Item>
                </div>
                <div>
                  <h6 className="mx-3 mb-0">
                    {" "}
                    <strong> Account </strong>
                  </h6>
                  <NavDropdown.Item as={Link} to={PAYMENTTERMS_LIST}>
                    {PAYMENTTERMS_TITLE}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={COUNTER_PARTY_TAG_LIST}>
                    {COUNTER_PARTY_TAG_TITLE}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={TAX_LIST}>
                    {TAX_TITLE}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={TAX_TYPE_LIST}>
                    {TAX_TYPE_TITLE}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={BANK_DETAILS_LIST}>
                    {BANK_DETAILS_TITLE}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={ADDRESS_LIST}>
                    {ADDRESS_TITLE}
                  </NavDropdown.Item>
                </div>
                <div>
                  <h6 className="mx-3 mb-0">
                    {" "}
                    <strong> Inventory </strong>
                  </h6>
                  <NavDropdown.Item as={Link} to={STORE_LIST}>
                    {STORE_TITLE}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={UNIT_OF_MEASUREMENT_LIST}>
                    {UNIT_OF_MEASUREMENT_TITLE}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={APPROVALS_LIST}>
                    {APPROVALS_TITLE}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={INGREDIENTS_LIST}>
                    {INGREDIENTS_TITLE}
                  </NavDropdown.Item>
                </div>
                <div>
                  <h6 className="mx-3 mb-0">
                    {" "}
                    <strong> Document </strong>
                  </h6>
                  {/* <NavDropdown.Item as={Link} to={DOCUMENT_LIST}>
                    {DOCUMENT_TITLE}
                  </NavDropdown.Item> */}
                  <NavDropdown.Item as={Link} to={LOGISTICDETAILS_LIST}>
                    {LOGISTICDETAILS_TITLE}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={TERMSANDCONDITION_LIST}>
                    {TERMSANDCONDITION_TITLE}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={DOCUMENT_NUMBER_FORMAT_LIST}>
                    {DOCUMENT_NUMBER_FORMAT_TITLE}
                  </NavDropdown.Item>
                </div>
              </div>
            </NavDropdown>

            <Dropdown as={NavItem} align={{ lg: "end" }}>
              <Dropdown.Toggle as={NavLink} className="p-0 no-arrow">
                <span style={{ display: "inline-block", borderRadius: "50%" }}>
                  <Image
                    width={40}
                    height={40}
                    className="object-fit-contain"
                    src={
                      user?.profile_pic
                        ? user?.profile_pic
                        : `https://eu.ui-avatars.com/api/?name=${user.first_name}%20${user.last_name}&size=250`
                    }
                    roundedCircle
                    onError={(e: any) => {
                      e.target.onerror = null; // Prevent infinite loop in case the fallback image also fails
                    }}
                    style={{
                      marginTop: "-3px",
                    }} // Ensure the image maintains its aspect ratio
                  />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={PROFILE}>
                  Profile
                </Dropdown.Item>
                <NavDropdown.Divider />
                <Dropdown.Item as={Link} to={ORGANIZATION_VIEW}>
                  {ORGANIZATION_TITLE}
                </Dropdown.Item>
                <NavDropdown.Divider />
                <Dropdown.Item
                  onClick={() => {
                    logout();
                  }}
                >
                  Log Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Modal
        show={showSearchModal}
        onHide={handleCloseSearchModal}
        dialogClassName="custom-modal" // Add a custom class for styling
        className="mt-3"
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>Search Shortcuts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchField
            handleCloseSearchModal={handleCloseSearchModal}
            setModalStatus={setModalStatus}
          />
        </Modal.Body>
      </Modal>
      {modalStatus?.buyersAndSupplierStatus && (
        <AddUpdateBuyesANDSuppliers
          setModalStaus={setModalStatus}
          modalStatus={modalStatus?.buyersAndSupplierStatus}
        />
      )}
    </>
  );
}

export default NavBarLayout;
