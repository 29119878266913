import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/Ingredients/List";
import AddUpdate from "../../Pages/Setting/Ingredients/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_INGREDIENTS,
  DELETE_INGREDIENTS,
  RESTORE_INGREDIENTS,
  UPDATE_INGREDIENTS,
  VIEW_INGREDIENTS,
} from "../../Util/PermissionList";

export const INGREDIENTS_TITLE = "Ingredients";
export const INGREDIENTS_LIST = "/ingredients";
export const INGREDIENTS_ADD = "/ingredients/add";
export const INGREDIENTS_EDIT = "/ingredients/:id/edit";
export const INGREDIENTS_ARCHIVE = "/ingredients/archive";

const Ingredients: RouteObject[] = [
  {
    path: INGREDIENTS_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_INGREDIENTS}
        checkPermisson={{
          addPermission: ADD_INGREDIENTS,
          updatePermission: UPDATE_INGREDIENTS,
          deletePermission: DELETE_INGREDIENTS,
          restorePermission: RESTORE_INGREDIENTS,
          viewPermission: VIEW_INGREDIENTS,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: INGREDIENTS_ADD,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate title={INGREDIENTS_TITLE} listRoute={INGREDIENTS_LIST} />
        }
        permission={ADD_INGREDIENTS}
        checkPermisson={{
          addPermission: ADD_INGREDIENTS,
          updatePermission: UPDATE_INGREDIENTS,
          deletePermission: DELETE_INGREDIENTS,
          restorePermission: RESTORE_INGREDIENTS,
          viewPermission: VIEW_INGREDIENTS,
        }}
      />
    ),
  },
  {
    path: INGREDIENTS_EDIT,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate title={INGREDIENTS_TITLE} listRoute={INGREDIENTS_LIST} />
        }
        permission={UPDATE_INGREDIENTS}
        checkPermisson={{
          addPermission: ADD_INGREDIENTS,
          updatePermission: UPDATE_INGREDIENTS,
          deletePermission: DELETE_INGREDIENTS,
          restorePermission: RESTORE_INGREDIENTS,
          viewPermission: VIEW_INGREDIENTS,
        }}
      />
    ),
  },
  {
    path: INGREDIENTS_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          addPermission: ADD_INGREDIENTS,
          updatePermission: UPDATE_INGREDIENTS,
          deletePermission: DELETE_INGREDIENTS,
          restorePermission: RESTORE_INGREDIENTS,
          viewPermission: VIEW_INGREDIENTS,
        }}
        permission={DELETE_INGREDIENTS}
      />
    ),
  },
];

export default Ingredients;
