import { Model } from "@tailflow/laravel-orion/lib/model";

export class Organization extends Model<{
  user_id: number;
  company_name: string;
  country_id: number;
  timezone: string;
  currency: string;
  currency_delimiter: string;
  currency_decimal_delimiter: string;
  is_manual_adjustment: boolean;
  dateformat: string;
  created_at: string;
  updated_at: string;
}> {
  public $resource(): string {
    return "organizations";
  }
}
