import "bootstrap/dist/css/bootstrap.min.css";
import { Attributes } from "../../../Model/Setting/Attributes";
import {
  ATTRIBUTE_LIST,
  ATTRIBUTE_TITLE,
} from "../../../Routes/Setting/Attributes";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { setToast } from "../../../Util/Toast";
import FieldDescription from "../../../Components/FieldDescription";
import { Button } from "react-bootstrap";
import Notes from "../../../Components/Notes";
import ActivityLogs from "../../../Components/ActivityLogs";
import { CapitalizeAndRemoveUnderscore } from "../../../Util/CapitalizeAndRemoveUnderscore";

const AddUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<any>({});
  const [selectedOption, setSelectedOption] = useState(formData.type);
  const [inputVisibility, setInputVisibility] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);
  const [isShows, setIsShows] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState("notes");
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status

  const formFields = [
    {
      id: "inputname",
      label: "Name*",
      type: "text",
      col: 6,
      name: "name",
      placeholder: "Enter Your Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Name is required",
        },
      ],
      description:
        "Enter a clear and descriptive name for the attribute. (Example: Product color)",
    },
    {
      id: "inputcode",
      label: "Code*",
      type: "text",
      col: 6,
      name: "code",
      placeholder: "Enter Your Code",
      disabled: true,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Code is required",
        },
      ],
      description:
        "Assign a unique identifier or code to the attribute for system integration. (Example: PRODUCT_COLOR)",
    },
    {
      id: "inputtype",
      label: "Type*",
      type: "select",
      addNewBtnStatus: false,
      col: 6,
      name: "type",
      placeholder: "Enter Your Type",
      options: [
        {
          label: "Text",
          value: "text",
        },
        { label: "Textarea", value: "textarea" },
        { label: "Price", value: "price" },
        { label: "Boolean", value: "boolean" },
        { label: "Select", value: "select" },
        { label: "Multiselect", value: "multiselect" },
        { label: "Checkbox", value: "checkbox" },
        { label: "Email", value: "email" },
        { label: "Address", value: "address" },
        { label: "Phone", value: "phone" },
        { label: "Lookup", value: "lookup" },
        { label: "Datetime", value: "datetime" },
        { label: "Date", value: "date" },
        { label: "Image", value: "image" },
        { label: "File", value: "file" },
        { label: "Decimal", value: "decimal" },
      ],
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Type is required",
        },
      ],
      description:
        "Specify the data type for the attribute in the 'Type field'. (Example: String, Integer, Boolean, etc.)",
    },
    {
      id: "inputentity_type",
      label: "Entity Type*",
      type: "select",
      addNewBtnStatus: false,
      col: 6,
      name: "entity_type",
      placeholder: "Enter Your Entity Type",
      options: [
        {
          label: "Production Process Types",
          value: "production_process_types",
        },
        { label: "Logistic Details", value: "logistic_details" },
        { label: "Unit Of Measurement", value: "unit_of_measurement" },
        { label: "Bank Details", value: "bank_details" },
        { label: "Terms And Conditions", value: "terms_and_conditions" },
        { label: "Payment Terms", value: "payment_terms" },
        { label: "Products", value: "products" },
      ],
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Entity Type is required",
        },
      ],
      description:
        "Define the entity type to which the attribute belongs. (Example: 'User,'' 'Product' etc.)",
    },
    {
      id: "inputlookup_type",
      label: "Lookup Type*",
      type: "hidden",
      col: 12,
      name: "lookup_type",
      placeholder: "Enter Your Type",
      options: [
        { label: "Counter Party Company", value: "counter_party_companies" },
        { label: "Product", value: "products" },
      ],
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Lookup Type is required",
        },
      ],
    },
    {
      id: "inputvalidation",
      label: "Validation*",
      type: "select",
      addNewBtnStatus: false,
      col: 12,
      name: "validation",
      options: [
        { label: "Numeric", value: "numeric" },
        { label: "String", value: "string" },
        { label: "Email", value: "email" },
        { label: "URL", value: "url" },
        { label: "Boolean", value: "boolean" },
        { label: "Decimal", value: "decimal" },
      ],
      placeholder: "Enter Your Validation",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Validation is required",
        },
      ],
      description: "Set validation rules to ensure data accuracy.",
    },
    {
      id: "inputsort_order",
      label: "Sort Order",
      type: "checkbox",
      col: 6,
      name: "sort_order",
      placeholder: "Enter Sort Order",
    },
    {
      id: "inputis_required",
      label: "Is Required*",
      type: "checkbox",
      col: 6,
      name: "is_required",
      placeholder: "Enter Is Required",
    },
    // {
    //   id: "inputis_unique",
    //   label: "Is Unique",
    //   type: "checkbox",
    //   col: 6,
    //   name: "is_unique",
    //   placeholder: "Enter Is Unique",
    // },
    // {
    //   id: "inputquick_add",
    //   label: "Quick Add",
    //   type: "checkbox",
    //   col: 6,
    //   name: "quick_add",
    //   placeholder: "Enter Quick Add",
    // },
    // {
    //   id: "inputis_user_defined",
    //   label: "Is User Defined",
    //   type: "checkbox",
    //   col: 12,
    //   name: "is_user_defined",
    //   placeholder: "Enter Is User Defined",
    // },
  ];

  const updatedFormFields: any = formFields.map((field) => {
    if (field.name === "lookup_type") {
      if (inputVisibility) {
        field.type = "select";
        field.validate = [
          {
            rule: (value: any) => !!value,
            message: "Lookup Type is required",
          },
        ];
      } else {
        field.type = selectedOption === "lookup" ? "select" : "hidden";
        field.validate = [];
      }
    }
    return field;
  });

  const getFormData = async (itemId: string) => {
    const item: any = await Attributes.$query().with(["options"]).find(itemId);

    return item.$attributes;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          setLoading(true);
          const itemData: any = await getFormData(id);

          let data = Object.assign({}, itemData);

          if (data["entity_type"]) {
            data["entity_type"] = {
              label: CapitalizeAndRemoveUnderscore(itemData.entity_type),
              value: itemData.entity_type,
            };
          }
          if (data["validation"]) {
            data["validation"] = {
              label: CapitalizeAndRemoveUnderscore(itemData.validation),
              value: itemData.validation,
            };
          }
          if (data["type"]) {
            data["type"] = {
              label: CapitalizeAndRemoveUnderscore(itemData.type),
              value: itemData.type,
            };
          }
          if (data["lookup_type"]) {
            data["lookup_type"] = {
              label: CapitalizeAndRemoveUnderscore(itemData.lookup_type),
              value: itemData.lookup_type,
            };
            setInputVisibility(true);
          }
          setFormData(data);
          setLoading(false);
        } catch (error: any) {
          setToast("error", error.response.data.message);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    let inputData = Object.assign({}, formData);
    inputData.entity_type = inputData.entity_type?.value;
    inputData.validation = inputData.validation?.value;
    inputData.type = inputData.type?.value;

    if (inputData.type === "lookup") {
      inputData.lookup_type = inputData.lookup_type.value;
    }

    if (
      inputData.type === "select" ||
      inputData.type === "multiselect" ||
      inputData.type === "checkbox"
    ) {
      inputData.data = [
        {
          option_type: "options",
          optionValue: Object.entries(formData.options).map(
            ([key, value]: any) => ({
              id: value.id,
              name: value.name || value,
            }),
          ),
        },
      ];
    }
    delete inputData.options;

    try {
      if (inputData.id) {
        let item: any = await Attributes.$query().find(inputData.id);

        item.$attributes = {
          ...item.$attributes,
          ...inputData,
        };

        item = await item.$save();
        setToast("success", `${ATTRIBUTE_TITLE} Successfully Updated`);
      } else {
        await Attributes.$query().store(inputData);
        setToast("success", `${ATTRIBUTE_TITLE} Successfully Added`);
      }
      navigate(`${ATTRIBUTE_LIST.toLowerCase().replace(" ", "-")}`);
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const formFieldsForFieldDescription = formFields.map((field) => ({
    label: field.label,
    description: field.description,
  }));

  formFieldsForFieldDescription.push(
    {
      label: "Checkbox",
      description:
        "Select the checkbox related to the requirement of the attribute",
    },
    {
      label: "INSTRUCTION To Choose Validation",
      description:
        "<br/>1. Numeric: Numeric data types are used to represent numbers. [Integer: 5, -10, 100, Floating-point: 3.14, -0.005, 2.0] <br/>2. String: A string is a sequence of characters. ['Hello, World!'] <br/> 3. E-mail: The email data type is used to store email addresses. ['username@example.com'.] <br/> 4. URL: The URL (Uniform Resource Locator) data type is used to represent web addresses. ['https://www.example.com'] <br/> 5. Boolean: Boolean data type has two values: true or false <br/> 6. Decimal: The decimal data type is used to represent numbers with decimal points.[3.14159,0.005,,-2.75]",
    },
  );

  return (
    <>
      <div>
        <span style={{ float: "inline-end" }}>
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>
        <AddUpdateForm
          formFields={updatedFormFields}
          model={Attributes}
          title={ATTRIBUTE_TITLE}
          listRoute={ATTRIBUTE_LIST}
          customFormSubmit={handleCustomSubmit}
          dynamicFormData={formData}
          onOptionChange={(selectedOption: any, field: any, _formData: any) => {
            if (field === "type") {
              setSelectedOption(selectedOption.value);
              setInputVisibility(selectedOption.value === "lookup");
            }
          }}
          inputVisibility={inputVisibility}
          dynamicFormError={formError}
          autoUpdateField={true}
          customSubmitting={customSubmitting}
          loadingStatus={loading}
        />
      </div>

      {id && (
        <div className="card mt-4" style={{ marginBottom: "30px" }}>
          <div className="card-header d-flex">
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "notes" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("notes")}
            >
              Notes
            </h5>
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "activityLogs" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("activityLogs")}
            >
              Activity Logs
            </h5>
          </div>

          <div
            className="card-body"
            style={{
              height: "auto",
              overflow: "auto",
              backgroundColor: "#e4e4e4",
            }}
          >
            {activeTab === "notes" && <Notes id={id} model={Attributes} />}
            {activeTab === "activityLogs" && (
              <ActivityLogs auditable_type={"ATTRIBUTES"} id={id} />
            )}
          </div>
        </div>
      )}
      <div id="form-bottom">
        <FieldDescription
          title={ATTRIBUTE_TITLE}
          formFields={formFieldsForFieldDescription}
          isShows={isShows}
        />
      </div>
    </>
  );
};

export default AddUpdate;
