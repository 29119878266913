import { useDateFormat } from "../../../Util/InputDateFormate";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import CustomButton from "../../../Components/Button/CustomButton";
import CustomAsyncSelect from "../../../Components/AsyncSelect";
import { setToast } from "../../../Util/Toast";
import { useApi } from "../../../Controller/ApiController";
import { PurchaseOrder } from "../../../Model/SalesAndPurchase/PurchaseOrder/PurchaseOrder";
import { Store } from "../../../Model/Setting/Store";
// import { TaxInvoice } from "../../../Model/SalesAndPurchase/TaxInvoice/TaxInvoice";
import { InwardDocument } from "../../../Model/SalesAndPurchase/InwardDocument/InwardDocument";
import { INWARD_DOCUMENT_TITLE } from "../../../Routes/SalesAndPurchase/InwardDocument";
import {
  DateFormatWithStartOFTheDay,
  DateString,
} from "../../../Util/DateFormat";
import { BatchManagement } from "../../../Model/SalesAndPurchase/BatchManagement/BatchManagement";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConfirmationModal from "../../../Components/ConfirmationModel";
import SignatureForDocuments from "../../../Components/SignatureForDocuments";
import AttachmentsForDocuments from "../../../Components/AttachmentsForDocuments";
import CreateRow from "../../../Components/CreateRow";
import FormSkeleton from "../../../Components/FormSkeleton";
import moment from "moment";
import AddUpdateStore from "../../Setting/Store/AddUpdate";
import AddUpdateProduct from "../../Inventory/Product/AddUpdate";
import { STORE_LIST, STORE_TITLE } from "../../../Routes/Setting/Store";
import AddressSelecter from "../../../Components/Address/Address";
import { OrganizationAddress } from "../../../Model/Setting/OrganizationAddress";
import { CounterPartyAddress } from "../../../Model/BuyersAndSuppliers/CounterPartyAddress/CounterPartyAddress";
import { showErrorsInToasts } from "../../../Util/ErrorMessageToast";
import { BuyersAndSuppliers } from "../../../Model/BuyersAndSuppliers/BuyersAndSuppliers";
import { Product } from "../../../Model/Inventory/Product";
import { PRODUCT_LIST, PRODUCT_TITLE } from "../../../Routes/Inventory/Product";
import { UnitOfMeasurement } from "../../../Model/Setting/UnitOfMeasurement";
import AddUpdateUMO from "../../Setting/UnitOfMeasurement/AddUpdate";
import {
  UNIT_OF_MEASUREMENT_LIST,
  UNIT_OF_MEASUREMENT_TITLE,
} from "../../../Routes/Setting/UnitOfMeasurement";

type ProductDetails = {
  id: string;
  delivered_now: 0;
  comments?: string | null;
  quantity: number | null;
  delivered: string;
  batches_id: string | null;
  unit_of_measurement_id: string | null;
  products_id: string | null;
  purchase_order_items_id: string;
  balance: string;
  position: number | null;
};

const AddUpdate = () => {
  const navigate = useNavigate();
  const dateFormat = useDateFormat();
  let { id, purchase_order_id }: any = useParams();
  const [selectedCompanyOption, setSelectedCompanyOption] = useState({
    label: "",
    value: "",
  });
  const [signatureId, setSignatureId] = useState("");
  const [isSignatureExist, setIsSignatureExist] = useState(false);
  const [signaturecustomSubmit, setSignaturecustomSubmit] = useState(false);
  const [selectedSignatureFile, setSelectedSignatureFile] = useState(false);
  const [attachmentSignatureError, setAttachmentSignatureError] =
    useState<any>("");
  const [showAttachment, setShowAttachment] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [showAttachmentSignature, setShowAttachmentSignature] = useState(false);
  const [store, setStore] = useState<any>({});
  const [documentDate, setDocumentDate] = useState<any>(null);
  const [inwardDocumentData, setInwardDocumentData] = useState<any>(null);
  const [attachmentId, setAttachmentId] = useState("");
  const [isAttchmentExist, setIsAttchmentExist] = useState(false);
  const [customSubmit, setcustomSubmit] = useState(false);
  const [selectedAttchmentFile, setSelectedAttchmentFile] = useState(false);
  const [attchmentTitle, setAttchmentTitle] = useState("");
  const [attachmentError, setAttachmentError] = useState<any>("");
  const [attachError, setAttachError] = useState("");
  const [products, setProducts] = useState<ProductDetails[]>([]);
  const [disableItem, setDisableItem] = useState<any>(false);
  const [buyerDeliveryError, setBuyerDeliveryError] = useState<string | null>(
    null,
  );
  const [buyerDetailsError, setBuyerDetailsError] = useState<string | null>(
    null,
  );
  useEffect(() => {
    if (isAttchmentExist) {
      setShowAttachment(true);
    }
  }, [isAttchmentExist]);
  const [documentDateError, setDocumentDateError] = useState<string | null>(
    null,
  );
  // const [deliverychallanDateError, setDeliverychallanDateError] = useState<
  //   string | null
  // >(null);
  const [transportationDocumentDateError, setTransportationDocumentDateError] =
    useState<string | null>(null);

  const [buyerDeliveryAddress, setBuyerDeliveryAddress] = useState<any>(null);
  const [buyerBillingAddress, setBuyerBillingAddress] = useState<any>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [transporterName, setTransporterName] = useState<any>(null);
  const [transportationDocumentNumber, setTransportationDocumentNumber] =
    useState<any>(null);
  const [vehicleNumber, setVehicleNumber] = useState<any>(null);
  const [transportationDocumentDate, setTransportationDocumentDate] = useState<
    any | null
  >(null);
  const [deliverychallanDate, setDeliverychallanDate] = useState<any | null>(
    null,
  );
  const [deliveryDate, setDeliveryDate] = useState<any>(null);
  const [deliveryDateError, setDeliveryDateError] = useState<string | null>(
    null,
  );
  const [itemComments, setItemComments] = useState<any>(null);
  const [
    selectedManufacturerBillingAddress,
    setSelectedManufacturerBillingAddress,
  ] = useState<any>(id ? {} : null);
  const [editManufacturerBillingAddress, setEditManufacturerBillingAddress] =
    useState<any>(id ? {} : null);
  const [
    selectedManufactureCompanyOption,
    setSelectedManufactureCompanyOption,
  ] = useState({
    label: "",
    value: "",
  });
  // const [amendment, setAmendment] = useState<any>(null);
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState<any>(null);
  const [deliveryChallanNumber, setDeliveryChallanNumber] = useState<any>(null);
  const [showOptionalFields, setShowOptionalFields] = useState(false);
  const [poDate, setPODate] = useState<any>(null);
  const [taxInvoicenumber, setTaxInvoiceNumber] = useState<any>(null);
  const [taxInvoiceDate, setTaxInvoiceDate] = useState<any | null>(null);
  // const [isUpdated, setIsUpdated] = useState(false);
  const [inwardForPOFormData, setInwardForPOFormData] = useState<any>([]);
  const [productFormErrors, setProductFormErrors] = useState<any>({});
  const [storeModalStatus, setStoreModalStatus] = useState<boolean>(false);
  // const [amendmentError, setAmendmentError] = useState("");
  const [storeError, setStoreError] = useState("");
  const [supplierAddrressError, setSupplierAddrressError] = useState<
    string | null
  >(null);
  const [formData, setFormData] = useState<any>([]);
  const [productDetails] = useState<ProductDetails>({
    id: "",
    delivered_now: 0,
    comments: "",
    quantity: 0,
    unit_of_measurement_id: "",
    delivered: "",
    batches_id: "",
    products_id: "",
    purchase_order_items_id: "",
    balance: "",
    position: 0,
  });
  useEffect(() => {
    if (isAttchmentExist) {
      setShowAttachment(true);
    }
    if (isSignatureExist) {
      setShowAttachmentSignature(true);
    }
  }, [isAttchmentExist, isSignatureExist]);
  const handelselectStore = (value: string) => {
    setStore(value);
  };
  const [status, setStatus] = useState<any>(null);
  const [productStatus, setProductStatus] = useState<boolean>(false);
  const [UMOModalStatus, setUMOModalStatus] = useState<boolean>(false);
  const [modifieldFormTableFields, setModifieldFormTableFields] = useState<any>(
    [],
  );

  const productTableFields = [
    {
      type: "CustomAsyncSelect",
      label: "product",
      headerLabel: "Item*",
      modal: Product,
      customFilter: [
        {
          field: "type",
          operator: "in",
          value: ["buy", "both"],
        },
      ],
      isMenuPortalTarget: true,
      inputField: "product_name",
      fieldName: "product_name",
      submitFieldName: "products_id",
      isMultiSelect: false,
      isNeedFullData: true,
      disabled: disableItem,
      onChangeModalStatus: (status: boolean) => setProductStatus(status),
      modalStatus: productStatus,
      widget: (
        <AddUpdateProduct
          title={PRODUCT_TITLE}
          listRoute={PRODUCT_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) => setProductStatus(status)}
        />
      ),
    },
    {
      type: "CustomAsyncSelect",
      label: "batch",
      headerLabel: "Batch*",
      modal: BatchManagement,
      isMenuPortalTarget: true,
      inputField: "batch_number",
      fieldName: "batch_number",
      submitFieldName: "batches_id",
      isMultiSelect: false,
      isNeedFullData: true,
      addNewBtnStatus: false,
    },
    {
      type: "number",
      label: "quantity",
      headerLabel: "Quantity*",
      fieldName: "quantity",
      fieldValue: (item: any) => item.quantity,
      disabled: disableItem,
    },
    {
      type: "CustomAsyncSelect",
      label: "unit_of_measurement",
      headerLabel: "Unit*",
      modal: UnitOfMeasurement,
      isMenuPortalTarget: true,
      inputField: "name",
      fieldName: "name",
      submitFieldName: "unit_of_measurement_id",
      isMultiSelect: false,
      isNeedFullData: true,
      onChangeModalStatus: (status: boolean) => setUMOModalStatus(status),
      modalStatus: UMOModalStatus,
      disabled: disableItem,
      widget: (
        <AddUpdateUMO
          title={UNIT_OF_MEASUREMENT_TITLE}
          listRoute={UNIT_OF_MEASUREMENT_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) => setUMOModalStatus(status)}
        />
      ),
    },
    {
      type: "readOnly",
      label: "delivered",
      headerLabel: "Delivered*",
      fieldName: "delivered",
      fieldValue: (item: any) => item.delivered || 0,
      // disabled: disableItem,
    },
    {
      type: "number",
      label: "Delivered Now",
      headerLabel: "Delivered Now*",
      fieldName: "delivered_now",
      submitFieldName: "delivered_now",
    },
    {
      type: "readOnly",
      label: "balance",
      headerLabel: "Balance*",
      fieldName: "balance",
      fieldValue: (item: any) => {
        return !id && purchase_order_id
          ? item.balance - item.delivered_now || 0
          : item.quantity - item.delivered - item.delivered_now || 0;
      },
    },
    {
      type: "text",
      label: "comments",
      headerLabel: "Comments",
      fieldName: "comments",
    },
  ];

  const getDataForEdit = async () => {
    try {
      let item: any = await InwardDocument.$query()
        .with([
          "stores",
          "items",
          "items.batch",
          "items.product",
          "invoiceNumber",
          "supplierBillingAddress",
          "supplierBillingAddress.counterPartyCompany",
          "buyerBillingAddress",
          "buyerDeliveryAddress",
          "purchaseOrderNumber",
          "items.unitOfMeasurement",
          "items.purchaseOrderItems.product",
          "buyerDeliveryAddress.country",
          "buyerBillingAddress.country",
          "supplierBillingAddress.country",
          "manufactureBillingAddress.country",
          "manufactureBillingAddress.counterPartyCompany",
        ])
        .find(id);

      let inputData = { ...item.$attributes };
      setInwardDocumentData(item.$attributes);
      setDisableItem(item.$attributes.purchase_order_id ? true : false);
      if (
        item.$attributes["tax_invoice_id"] ||
        item.$attributes["delivery_date"] ||
        item.$attributes["transportation_document_date"] ||
        item.$attributes["vehicle_number"] ||
        item.$attributes["transportation_document_number"] ||
        item.$attributes["transporter_name"] ||
        item.$attributes["delivery_challan_number"] ||
        item.$attributes["tax_invoice_date"] ||
        item.$attributes["delivery_challan_date"]
      ) {
        setShowOptionalFields(true);
      }

      setSelectedCompanyOption({
        label:
          item.$attributes.supplier_billing_address?.counter_party_company
            ?.company_name,
        value:
          item.$attributes.supplier_billing_address?.counter_party_company_id,
      });
      setSupplierBillingAddress(item.$attributes.supplier_billing_address);
      setEditSupplierBillingAddress(item.$attributes.supplier_billing_address);
      setBuyerDeliveryAddress(item.$attributes.buyer_delivery_address);
      setBuyerBillingAddress(item.$attributes.buyer_billing_address);
      setSelectedManufacturerBillingAddress(
        item.$attributes.manufacture_billing_address,
      );
      setEditManufacturerBillingAddress(
        item.$attributes.manufacture_billing_address,
      );
      setSignatureId(id);
      setAttachmentId(id);

      const isFieldsValueExist = (fieldName: string) => {
        setPrimaryDetails((prevState) => ({
          ...prevState,
          [fieldName]: true,
        }));
      };

      const fieldSetters = [
        { key: "status", setter: setStatus },
        // { key: "amendment", setter: setAmendment },
        { key: "delivery_challan_number", setter: setDeliveryChallanNumber },
        { key: "transporter_name", setter: setTransporterName },
        {
          key: "transportation_document_number",
          setter: setTransportationDocumentNumber,
        },
        { key: "vehicle_number", setter: setVehicleNumber },
      ];

      fieldSetters.forEach(({ key, setter }) => {
        if (item.$attributes[key]) {
          setter(item.$attributes[key]);
        }
      });

      const dateFields = [
        { key: "document_date", setter: setDocumentDate },
        { key: "delivery_date", setter: setDeliveryDate },
        { key: "purchase_order_date", setter: setPODate },
        { key: "tax_invoice_date", setter: setTaxInvoiceDate },
        { key: "delivery_challan_date", setter: setDeliverychallanDate },
        {
          key: "transportation_document_date",
          setter: setTransportationDocumentDate,
        },
      ];

      if (inputData.purchase_order_id) {
        purchase_order_id = inputData.purchase_order_id;
      }

      if (dateFormat) {
        dateFields.forEach(({ key, setter }) => {
          if (item.$attributes[key]) {
            setter(DateString(item.$attributes[key], dateFormat.split(" ")[0]));
          }
          isFieldsValueExist(key); // Set primary details for date fields
        });
      }
      if (item.$attributes.comments) {
        setShowComments(true);
        setItemComments(inputData.comments);
      }
      if (item.$attributes["purchase_order_number"]) {
        inputData["purchase_order"] = {
          label: inputData.purchase_order_number["document_number"],
          value: inputData.purchase_order_number["id"],
        };
        setModifieldFormTableFields(productTableFields);
        setPurchaseOrderDetails(inputData.purchase_order_number);
      }

      if (item.$attributes["tax_invoice_id"]) {
        inputData["tax_invoice_id"] = {
          label: inputData.tax_invoice_id["document_number"],
          value: inputData.tax_invoice_id["id"],
        };
        handelselectTaxInvoice(inputData.tax_invoice_id);
      }

      if (item.$attributes["stores"]) {
        inputData["stores"] = {
          label: inputData.stores["name"],
          value: inputData.stores["id"],
        };
        setStore(item.$attributes["stores"]);
      }

      setProducts((prevState: any) => {
        const uniqueItems = new Set(prevState?.map((item: any) => item.id));
        const returnProduct = [
          ...prevState,
          ...inputData.items
            .filter((item: any) => !uniqueItems.has(item.id))
            .map((item: any, index: number) => ({
              ...item,
              id: item.id,
              // product: item.purchase_order_items?.product,
              unit_of_measurement: item.unit_of_measurement,
              // unit_of_measurement: item.unit_of_measurement,
              delivered: item.delivered,
              purchase_order_items_data: id
                ? JSON.stringify(item.purchase_order_items)
                : null,
              position: item.position ?? index,
            })),
        ];

        return returnProduct.sort((a, b) => a.position - b.position);
      });
      const checkField = ["document_number", "purchase_order_number", "store"];

      checkField.forEach((fieldName: any) => {
        isFieldsValueExist(fieldName);
      });

      setFormData(inputData);
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };
  const GoBack = () => {
    setShowConfirmation(true);
  };
  const handleConfirmation: any = async () => {
    navigate(-1);
    setShowConfirmation(false);
  };

  const handleToggleAttachment = () => {
    setShowAttachment(!showAttachment);
  };

  useEffect(() => {
    if (showAttachment) {
      const attachmentsElement = document.getElementById("attachments");
      if (attachmentsElement) {
        attachmentsElement.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (showComments) {
      const commentsElement = document.getElementById("comments");
      if (commentsElement) {
        commentsElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [showAttachment, showComments]);

  const handleToggleComments = () => {
    setShowComments(!showComments);
  };
  const handleToggleAttachmentSignature = () => {
    setShowAttachmentSignature(!showAttachmentSignature);
  };
  const [primaryDetails, setPrimaryDetails] = useState({
    document_number: false,
    document_date: false,
    purchase_order_number: false,
    delivery_date: false,
    store: false,
  });
  const [
    loadingForPrimaryDocumentDetails,
    setLoadingForPrimaryDocumentDetails,
  ] = useState(true);
  //////////////////////////////////company details
  const [supplierBillingAddress, setSupplierBillingAddress] =
    useState<any>(null);
  const [editSupplierBillingAddress, setEditSupplierBillingAddress] =
    useState<any>(null);
  useEffect(() => {
    const allFieldsSet = Object.values(primaryDetails).every(
      (field) => field === true,
    );

    if (allFieldsSet) {
      setLoadingForPrimaryDocumentDetails(false);
    }
  }, [primaryDetails]);

  const inwardIncludeWith =
    "items,termsAndCondition,supplierBillingAddress,buyerBillingAddress,buyerDeliveryAddress,items.product,paymentTerms,items.tax,charges,charges.tax,bankDetail,logisticDetail,items.unit,stores,supplierBillingAddress.counterPartyCompany,buyerDeliveryAddress.country,buyerBillingAddress.country,supplierBillingAddress.country,manufactureBillingAddress.country,manufactureBillingAddress.counterPartyCompany";

  useEffect(() => {
    if (id) {
      // setIsUpdated(true);
      getDataForEdit();
    } else if (purchase_order_id) {
      fetchInwardData(purchase_order_id);
      // setProducts([{ ...productDetails, position: 0 }]);
    } else {
      setProducts([{ ...productDetails, position: 0 }]);
    }

    if (!purchase_order_id && !purchaseOrderDetails) {
      const filteredFields = productTableFields.filter(
        (field) =>
          field.fieldName !== "balance" &&
          field.fieldName !== "delivered" &&
          field.fieldName !== "quantity",
      );
      setModifieldFormTableFields(filteredFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, purchase_order_id, dateFormat]);

  const fetchInwardData = async (purchase_order_id: string | number) => {
    const response = await PurchaseOrder.$query()
      .with([inwardIncludeWith])
      .find(purchase_order_id);
    setDisableItem(true);
    getInwardData(response?.$attributes);
  };

  const getInwardData = async (data: any) => {
    // setProducts([{ ...productDetails, position: 0 }]);
    setProducts([]);

    let inputData = Object.assign({}, data);
    setPurchaseOrderDetails(data);

    setSelectedCompanyOption({
      label: data?.counter_party_company?.company_name,
      value: data?.counter_party_company_id,
    });

    if (
      data["document_date"] ||
      data["document_number"] ||
      data["store_id"] ||
      data["vehicle_number"]
    ) {
      setShowOptionalFields(true);
    }

    // data["amendment"] && setAmendment(data["amendment"]);

    const dateFields = [
      { key: "document_date", setter: setPODate },
      { key: "delivery_date", setter: setDeliveryDate },
    ];

    const isFieldsValueExist = (fieldName: string) => {
      setPrimaryDetails((prevState) => ({
        ...prevState,
        [fieldName]: true,
      }));
    };

    if (dateFormat) {
      dateFields.forEach(({ key, setter }) => {
        data["document_date"] &&
          setDocumentDate(DateString(new Date(), dateFormat.split(" ")[0]));
        if (data[key]) {
          setter(DateString(data[key], dateFormat.split(" ")[0]));
        }
        isFieldsValueExist(key); // Set primary details for date fields
      });
    }

    const addressSetters = [
      {
        key: "buyer_billing_address",
        setter: setBuyerBillingAddress,
        // idSetter: setBuyerBillingAddressId,
      },
      {
        key: "buyer_delivery_address",
        setter: setBuyerDeliveryAddress,
        // idSetter: setBuyerDeliveryAddressId,
      },
      {
        key: "supplier_billing_address",
        setter: setSupplierBillingAddress,
        // idSetter: setSupplierBillingAddressId,
      },
      {
        key: "supplier_billing_address",
        setter: setSupplierBillingAddress,
        // idSetter: setSupplierBillingAddressId,
      },
      {
        key: "manufacture_billing_address",
        setter: setSelectedManufacturerBillingAddress,
        // idSetter: setManufacturerBillingAddressId,
      },
    ];

    addressSetters.forEach(({ key, setter, idSetter }: any) => {
      if (data[key]) {
        setter(data[key]);
        idSetter && idSetter(data[key].id);
      }
    });

    // if (data["supplier_billing_address"]) {
    //   handleSelectChange(data["supplier_billing_address"]);
    // }

    if (data["stores"]) {
      inputData["stores"] = {
        label: inputData.stores["name"],
        value: inputData.stores["id"],
      };
      setStore(data["stores"]);
    }

    if (data["document_number"]) {
      inputData["purchase_order"] = {
        label: inputData["document_number"],
        value: inputData["id"],
      };
    }

    if (data["document_number"]) {
      inputData["document_number"] = {
        label: inputData.document_number,
        value: inputData.id,
      };
    }

    setProducts((prevState: any) => {
      const uniqueItems = new Set(prevState?.map((item: any) => item.id)); // Extract unique ids
      const returnProduct: any = [
        ...prevState,
        ...inputData?.items
          ?.filter((item: any) => !uniqueItems.has(item?.id)) // Filter out duplicates
          .map((item: any, index: number) => ({
            ...item,
            // id: products[index]?.id,
            ...(id ? { id: products[index]?.id } : {}),
            delivered_now: 0,
            comments: "",
            quantity: item.quantity,
            unit_of_measurement: item.unit,
            // unit_of_measurement_id: item.unit,
            // unit_of_measurement_id: item.unit_of_measurement_id,
            delivered: item.delivered_quantity,
            batches_id: item.batches_id,
            purchase_order_items_data: JSON.stringify(item),
            purchase_order_items_id: item.id,
            balance: item?.quantity
              ? item?.quantity - item?.delivered_quantity
              : 0,
            position: item.position ?? index,
          })),
      ];
      // {/* balance = purchaseQty - delivered */}
      delete returnProduct.id;
      return returnProduct?.sort((a: any, b: any) => a.position - b.position);
    });

    const checkField = ["document_number", "purchase_order_number", "store"];

    checkField.forEach((fieldName: any) => {
      isFieldsValueExist(fieldName);
    });
    // delete inputData.stores;
    // setStore(null);
    setInwardForPOFormData(inputData);
    return data;
  };

  //////////////////////////////////buyer details
  const { apiGet } = useApi();

  const cleanObject = (obj: any, fileds: any) => {
    for (var propName in obj) {
      if (
        !fileds.includes(propName) &&
        (obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === "")
      ) {
        delete obj[propName];
        !id && delete obj["id"];
      } else if (typeof obj[propName] === "object") {
        cleanObject(obj[propName], fileds);
      }
    }
    return obj;
  };

  const [submitStatus, setSubmitStatus] = useState("");
  const [submitEvent, setSubmitEvent] = useState<any>(null);
  const [showConfirmationForSendData, setShowConfirmationForSendData] =
    useState(false);

  const submit = async (e: any, status: any) => {
    setSubmitEvent(e);
    setSubmitStatus(status);
    if (status === "draft") {
      await handleConfirmationForSubmit(e, status);
    } else {
      setShowConfirmationForSendData(true);
    }
  };

  const handleConfirmationForSendData = () => {
    handleConfirmationForSubmit(submitEvent, submitStatus, true);
    setShowConfirmationForSendData(false);
  };

  const handleConfirmationForSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    status: any,
    fullData?: boolean,
  ) => {
    e.preventDefault();
    let isValid = true;
    let isProductValid = true;
    let isSignatureValid = true;
    let productErrors: any = {};
    let isAttchmentValid = true;
    if (!documentDate) {
      setDocumentDateError("Document Date is Required");
      isValid = false;
    } else {
      setDocumentDateError("");
    }
    if (!deliveryDate) {
      setDeliveryDateError("Expected Delivery Date is Required");
      isValid = false;
    } else {
      setDeliveryDateError("");
    }
    // if (!amendment) {
    //   setAmendmentError("Amendment Number is Required");
    //   isValid = false;
    // } else {
    //   setAmendmentError("");
    // }

    if (Object.keys(store).length === 0) {
      setStoreError("Store is Required");
      isValid = false;
    } else {
      setStoreError("");
    }
    if (
      id &&
      selectedAttchmentFile &&
      (!attchmentTitle || attchmentTitle.length < 2)
    ) {
      setAttachError("Attachment's title field must be at least 2 characters");
      isAttchmentValid = false;
      isValid = false;
    } else {
      setAttachError("");
    }
    if (!supplierBillingAddress) {
      isValid = false;
      setSupplierAddrressError("Goods Sent Location Details is Required");
    } else {
      setSupplierAddrressError("");
    }

    if (!buyerBillingAddress) {
      isValid = false;
      setBuyerDetailsError("Goods Received By Details is Required");
    } else {
      setBuyerDetailsError("");
    }

    if (!buyerDeliveryAddress) {
      isValid = false;
      setBuyerDeliveryError("Delivery Location is Required");
    } else {
      setBuyerDeliveryError("");
    }

    let rowDataArray = products.map((item) => {
      const newItem: any = { ...item };
      const fields = [
        "delivered_now",
        "batches_id",
        "products_id",
        "unit_of_measurement_id",
      ];

      if (
        // !id &&
        newItem.balance !== undefined &&
        newItem.delivered_now !== undefined
      ) {
        newItem.balance =
          parseInt(newItem.quantity || 0) -
          parseInt(newItem.delivered || 0) -
          parseInt(newItem.delivered_now || 0);
      }
      newItem.quantity = parseInt(newItem.quantity) || 0;
      newItem.delivered = parseInt(newItem.delivered) || 0;
      newItem.delivered_now = parseInt(newItem.delivered_now) || 0;
      return cleanObject(newItem, fields);
    });
    rowDataArray.every((item: any, index: number) => {
      Object.entries(item).forEach(([key, value]: [string, any]) => {
        if (key === "delivered_now" && value === 0) {
          productErrors[index] = {
            ...productErrors[index],
            [key]: "Quantity must be at least 1",
          };
        }
        if (
          !(
            value ||
            key === "comments" ||
            key === "position" ||
            key === "delivered" ||
            key === "customKey" ||
            value === 0 ||
            value === "0"
          )
        ) {
          let errorKey = key
            .replace("id", "")
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          const errorMessage = `${errorKey} is required`;
          productErrors[index] = {
            ...productErrors[index],
            [key]: errorMessage,
          };
        }
      });
      return item;
    });
    setProductFormErrors(productErrors);
    if (showAttachment) {
      if (!id) {
        if (!attchmentTitle || !(attchmentTitle.length >= 2)) {
          setAttachmentError({
            titleError:
              "Attachment's title field must be at least 2 characters",
          });
          isAttchmentValid = false;
        } else if (!selectedAttchmentFile) {
          setAttachmentError({ attachmentsError: "Attachments is Required" });
          isAttchmentValid = false;
        } else {
          setAttachmentError("");
        }
      }
    }
    if (showAttachmentSignature) {
      if (!id && !selectedSignatureFile) {
        setAttachmentSignatureError("Signature is Required");
        isSignatureValid = false;
      } else {
        setAttachmentSignatureError("");
      }
    }

    if (fullData) {
      rowDataArray = rowDataArray?.map((item) => ({
        ...item,
        products_data: JSON.stringify(item.product) ?? null,
        purchase_order_items_data: item.purchase_order_items_data ?? null,
        inward_documents_data:
          id && inwardDocumentData ? JSON.stringify(inwardDocumentData) : null,
        unit_of_measurement_data: item.unit_of_measurement
          ? JSON.stringify(item.unit_of_measurement)
          : item.unit
          ? JSON.stringify(item.unit)
          : null,
        batches_data: JSON.stringify(item.batch) ?? null,
      }));
    }

    if (
      !isValid ||
      !isAttchmentValid ||
      !isSignatureValid ||
      Object.keys(productErrors).length !== 0
    ) {
      setToast("error", "Validation failed. Please check the form fields");
      if (!isValid) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (!isProductValid) {
        window.scrollTo({ top: 500, behavior: "smooth" });
      }
      return;
    }
    const formdata: any = {
      ...(!id ? { document_number: inwardDocumentnumber } : {}),
      ...(taxInvoicenumber
        ? { tax_invoice_id: taxInvoicenumber?.data?.id }
        : {}),
      status: status,
      document_date: DateFormatWithStartOFTheDay(
        documentDate,
        dateFormat?.replace("dd", "DD"),
      ),
      purchase_order_id: purchase_order_id || purchaseOrderDetails?.id,
      // purchase_order_id: 98,
      purchase_order_date:
        poDate &&
        DateFormatWithStartOFTheDay(poDate, dateFormat?.replace("dd", "DD")),
      store_id: store?.id || store?.data?.id,
      delivery_date: DateFormatWithStartOFTheDay(
        deliveryDate,
        dateFormat?.replace("dd", "DD"),
      ),
      amendment: 0,
      supplier_billing_address_id: supplierBillingAddress?.id,
      supplier_id: supplierBillingAddress?.counter_party_company_id,
      buyer_billing_address_id: buyerBillingAddress?.id,
      buyer_delivery_address_id: buyerDeliveryAddress?.id,
      manufacture_billing_address_id: selectedManufacturerBillingAddress?.id,
      manufacture_id:
        selectedManufacturerBillingAddress?.counter_party_company?.id,
      items: rowDataArray,
      ...(fullData
        ? {
            supplier_name:
              supplierBillingAddress?.counter_party_company?.company_name,
            buyer_billing_address_data: JSON.stringify(buyerBillingAddress),
            buyer_delivery_address_data: JSON.stringify(buyerDeliveryAddress),
            supplier_billing_address_data: JSON.stringify(
              supplierBillingAddress,
            ),
            manufacture_name:
              selectedManufacturerBillingAddress?.counter_party_company
                ?.company_name,
            manufacture_billing_address_data: JSON.stringify(
              selectedManufacturerBillingAddress,
            ),
            tax_invoice_data: JSON.stringify(taxInvoicenumber?.data),
            // purchase_order_data: JSON.stringify(purchaseOrderDetails),
            ...(purchaseOrderDetails
              ? { purchase_order_data: JSON.stringify(purchaseOrderDetails) }
              : {}),
            store_data:
              store && store.data
                ? JSON.stringify(store.data)
                : JSON.stringify(store),
          }
        : {}),
    };
    formdata.comments = showComments ? itemComments : "";

    const propertiesToRemove = ["batch", "product", "Unit"];

    if (formData?.items) {
      propertiesToRemove.forEach((prop) => {
        delete formData.items[prop];
      });
    }
    if (showOptionalFields) {
      // formdata.tax_invoice_id = taxInvoicenumber;
      formdata.tax_invoice_date =
        taxInvoiceDate &&
        DateFormatWithStartOFTheDay(
          taxInvoiceDate,
          dateFormat?.replace("dd", "DD"),
        );
      formdata.delivery_challan_number = deliveryChallanNumber;
      formdata.delivery_challan_date =
        deliverychallanDate &&
        DateFormatWithStartOFTheDay(
          deliverychallanDate,
          dateFormat?.replace("dd", "DD"),
        );
      formdata.transporter_name = transporterName;
      formdata.transportation_document_number = transportationDocumentNumber;
      formdata.vehicle_number = vehicleNumber;
      formdata.transportation_document_date =
        transportationDocumentDate &&
        DateFormatWithStartOFTheDay(
          transportationDocumentDate,
          dateFormat?.replace("dd", "DD"),
        );
    }
    try {
      setSubmitting(true);
      if (id) {
        if (selectedSignatureFile && showAttachmentSignature) {
          setSignaturecustomSubmit(true);
        }
        if (selectedAttchmentFile && showAttachment) {
          setcustomSubmit(true);
        }
        await (InwardDocument.$query() as any).update(id, formdata);
        setToast("success", "Inward successfully updated");
      } else {
        const data = await (InwardDocument.$query() as any).store(formdata);
        if (selectedSignatureFile && showAttachmentSignature) {
          setSignatureId(data?.$attributes.id);
          setSignaturecustomSubmit(true);
        }
        setToast("success", "Inward successfully added");
        if (selectedAttchmentFile && showAttachment) {
          setAttachmentId(data?.$attributes.id);
          setcustomSubmit(true);
        }
      }
      navigate(-1);
    } catch (error: any) {
      if (error.response && error.response.data.errors) {
        showErrorsInToasts(error.response.data.errors);
      } else if (error.response.data.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setSubmitting(false);
    }
  };
  const handelselectTaxInvoice = (value: string) => {
    setTaxInvoiceNumber(value);
  };
  const customTheme = (theme: any) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 30,
      baseUnit: 2,
    },
  });
  const [inwardDocumentnumber, setInwardDocumentNumber] = useState<any | null>(
    null,
  );
  useEffect(() => {
    DocumentNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRegenerate = () => {
    DocumentNumber();
  };
  const DocumentNumber = async () => {
    const responce = await apiGet(`next-document-number/inward_document`);
    setInwardDocumentNumber(responce?.data?.value);
  };
  const handleSelectInputChange = (selectedOption: any, field: any) => {
    setInwardForPOFormData((prevData: any) => {
      const updatedData = {
        ...prevData,
        [field]: selectedOption,
      };
      return updatedData;
    });
    setFormData((prevData: any) => {
      const updatedData = {
        ...prevData,
        [field]: selectedOption,
      };
      return updatedData;
    });
  };

  ///////////////////////////////////////
  const handlePrimaryDeliveryDateChange = (date: any) => {
    setDeliveryDate(date);
  };
  const handlePrimaryDocumentDateChange = (date: any) => {
    setDocumentDate(date);
  };
  // const handleDeliveryChallanDateChange = (date: any) => {
  //   setDeliverychallanDate(date);
  // };
  const handleTransportationDocDateChange = (date: any) => {
    setTransportationDocumentDate(date);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h5>{INWARD_DOCUMENT_TITLE}</h5>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <CustomButton label={`Back`} variant="secondary" onClick={GoBack} />
        </div>
      </div>

      <div>
        <Row>
          <Col>
            <div
              style={{
                display: "flex",
                paddingTop: "20px",
              }}
            >
              <AddressSelecter
                title="Goods Received By*"
                isPratent={false}
                loadingStatus={purchase_order_id || id ? true : false}
                isChild={true}
                isdefaultselected={false}
                childModal={OrganizationAddress}
                addChildNewBtnStatus={true}
                childIncludeWith={"country"}
                childFieldName="supplier_billing_address"
                childFormData="formData"
                childInputfield="address_name"
                childLabel="organization_address"
                isChildMultiSelect={false}
                isChildNeedFullData={true}
                isParentMultiSelect={false}
                isParentNeedFullData={false}
                childCustomFilter={[
                  {
                    field: "address_type",
                    operator: "in",
                    value: ["billing"],
                  },
                ]}
                selectedValue={buyerBillingAddress}
                setSelectedValue={setBuyerBillingAddress}
                formTitle={"Billing Address"}
                formModal={OrganizationAddress}
                errorValues={buyerDetailsError}
                setErrorValues={setBuyerDetailsError}
                childFormAddressType={"billing"}
              />
              <AddressSelecter
                title="Delivery Location"
                loadingStatus={purchase_order_id || id ? true : false}
                isPratent={false}
                isChild={true}
                isdefaultselected={false}
                childModal={OrganizationAddress}
                addChildNewBtnStatus={true}
                childIncludeWith={"country"}
                childFieldName="supplier_billing_address"
                childFormData="formData"
                childInputfield="address_name"
                childLabel="organization_address"
                isChildMultiSelect={false}
                isChildNeedFullData={true}
                isParentMultiSelect={false}
                isParentNeedFullData={false}
                childCustomFilter={[
                  {
                    field: "address_type",
                    operator: "in",
                    value: ["delivery"],
                  },
                ]}
                selectedValue={buyerDeliveryAddress}
                setSelectedValue={setBuyerDeliveryAddress}
                formTitle={"Delivery Address"}
                formModal={OrganizationAddress}
                errorValues={buyerDeliveryError}
                setErrorValues={setBuyerDeliveryError}
                childFormAddressType={"delivery"}
              />
            </div>
            <div
              style={{
                display: "flex",
                paddingTop: "37px",
                marginBottom: "0",
              }}
            >
              <AddressSelecter
                title="Goods Sent Location*"
                isPratent={true}
                loadingStatus={
                  !supplierBillingAddress ? false : id ? true : false
                }
                isChild={true}
                parentModal={BuyersAndSuppliers}
                parentFieldName="company_name"
                parentFormData="company_name"
                parentInputfield="company_name"
                parentLabel="counter_party_company"
                addParentNewBtnStatus={false}
                childModal={CounterPartyAddress}
                setSelectedParentValue={setSelectedCompanyOption}
                selectedParentValue={selectedCompanyOption}
                addChildNewBtnStatus={true}
                childIncludeWith={"country,counterPartyCompany"}
                childFieldName="supplier_billing_address"
                childFormData="formData"
                childInputfield="address_name"
                childLabel="organization_address"
                isChildMultiSelect={false}
                isChildNeedFullData={true}
                isParentMultiSelect={false}
                isParentNeedFullData={false}
                childCustomFilter={[
                  ...(selectedCompanyOption?.value ||
                  supplierBillingAddress?.counter_party_company?.id
                    ? [
                        {
                          field: "counter_party_company_id",
                          operator: "in",
                          value: [
                            selectedCompanyOption?.value ||
                              supplierBillingAddress?.counter_party_company?.id,
                          ],
                        },
                      ]
                    : []),
                  {
                    field: "address_type",
                    operator: "in",
                    value: ["billing"],
                  },
                ]}
                isChildDisabled={selectedCompanyOption?.value === undefined}
                childFormAddressType={"billing"}
                selectedValue={supplierBillingAddress}
                setSelectedValue={setSupplierBillingAddress}
                formTitle={"Billing Address"}
                formModal={CounterPartyAddress}
                errorValues={supplierAddrressError}
                setErrorValues={setSupplierAddrressError}
                editSelected={editSupplierBillingAddress}
              />
              <AddressSelecter
                title="Manufactured by"
                isPratent={true}
                loadingStatus={
                  !selectedManufacturerBillingAddress
                    ? false
                    : id
                    ? true
                    : false
                }
                isChild={true}
                parentModal={BuyersAndSuppliers}
                parentFieldName="company_name"
                parentFormData="company_name"
                parentInputfield="company_name"
                parentLabel="counter_party_company"
                addParentNewBtnStatus={false}
                childModal={CounterPartyAddress}
                setSelectedParentValue={setSelectedManufactureCompanyOption}
                selectedParentValue={selectedManufactureCompanyOption}
                addChildNewBtnStatus={true}
                childIncludeWith={"country,counterPartyCompany"}
                childFieldName="company_name"
                childFormData="formData"
                childInputfield="address_name"
                childLabel="counter_party_address"
                isChildMultiSelect={false}
                isChildNeedFullData={true}
                isParentMultiSelect={false}
                isParentNeedFullData={false}
                childCustomFilter={[
                  ...(selectedManufactureCompanyOption?.value ||
                  selectedManufacturerBillingAddress?.counter_party_company?.id
                    ? [
                        {
                          field: "counter_party_company_id",
                          operator: "in",
                          value: [
                            selectedManufactureCompanyOption?.value ||
                              selectedManufacturerBillingAddress
                                ?.counter_party_company?.id,
                          ],
                        },
                      ]
                    : []),
                  {
                    field: "address_type",
                    operator: "in",
                    value: ["billing"],
                  },
                ]}
                isChildDisabled={
                  selectedManufactureCompanyOption?.value === undefined
                }
                childFormAddressType={"billing"}
                selectedValue={selectedManufacturerBillingAddress}
                setSelectedValue={setSelectedManufacturerBillingAddress}
                formTitle={"Manufacturer Address"}
                formModal={CounterPartyAddress}
                editSelected={editManufacturerBillingAddress}
              />
            </div>
          </Col>
          <Col>
            <Card className="mt-3" style={{ fontSize: "small" }}>
              <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                  <span style={{ fontSize: "15px" }}>
                    Primary Document Details
                  </span>
                  <button
                    className="btn btn-sm btn-light border"
                    onClick={() => setShowOptionalFields(!showOptionalFields)}
                  >
                    {showOptionalFields
                      ? "Hide Optional Fields"
                      : "Show Optional Fields"}
                  </button>
                </div>
              </Card.Header>
              {(purchase_order_id || id) && loadingForPrimaryDocumentDetails ? (
                <div style={{ padding: "1rem" }} className="pb-0">
                  <FormSkeleton />
                </div>
              ) : (
                <Card.Body>
                  <div className="row mb-2">
                    <div className="col">
                      <label
                        htmlFor="documentnumber"
                        className="d-flex justify-content-between "
                      >
                        <span>Document Number*</span>
                        <button
                          onClick={handleRegenerate}
                          className="btn btn-light border mb-1"
                          style={{ padding: "3px 5px", fontSize: "12px" }}
                        >
                          Regenerate
                        </button>
                      </label>
                      <input
                        type="text"
                        className=" form-control form-control-sm"
                        disabled
                        value={
                          id
                            ? inwardDocumentData.document_number
                            : inwardDocumentnumber
                        }
                      />
                    </div>
                    <div className="col d-flex flex-column">
                      <label htmlFor="documentdate">Document Date*</label>
                      <DatePicker
                        selected={documentDate}
                        onChange={handlePrimaryDocumentDateChange}
                        placeholderText={dateFormat.replace("dd", "DD")}
                        dateFormat={
                          dateFormat?.replace("YYYY", "yyyy").split(" ")[0]
                        }
                        showYearDropdown
                        className="w-48 form-control form-control-sm  mt-2"
                        onBlur={(e: any) => {
                          let enteredDate = e.target.value;
                          const dateFormatWithoutTime = dateFormat
                            .replace("dd", "DD")
                            .split(" ")[0];
                          enteredDate = enteredDate.replace(
                            /\b(\d{1})\b/g,
                            "0$1",
                          );
                          const isValid = moment(
                            enteredDate,
                            dateFormatWithoutTime,
                          ).isValid();
                          if (isValid) {
                            setDocumentDateError("");
                          } else {
                            setDocumentDate("");
                            if (enteredDate.length > 0) {
                              setDocumentDateError(
                                `Please select a date in the correct format (${
                                  dateFormat.replace("dd", "DD").split(" ")[0]
                                }).`,
                              );
                            } else {
                              setDocumentDateError("");
                            }
                          }
                        }}
                      />
                      {documentDateError && (
                        <div className="error-message text-danger">
                          {documentDateError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-6 d-flex flex-column">
                      <label htmlFor="deliverydate">
                        Expected Delivery Date*
                      </label>
                      <DatePicker
                        selected={deliveryDate}
                        onChange={handlePrimaryDeliveryDateChange}
                        placeholderText={dateFormat.replace("dd", "DD")}
                        dateFormat={
                          dateFormat?.replace("YYYY", "yyyy").split(" ")[0]
                        }
                        showYearDropdown
                        className="form-control form-control-sm w-100"
                        onBlur={(e: any) => {
                          let enteredDate = e.target.value;
                          const dateFormatWithoutTime = dateFormat
                            .replace("dd", "DD")
                            .split(" ")[0];
                          enteredDate = enteredDate.replace(
                            /\b(\d{1})\b/g,
                            "0$1",
                          );
                          const isValid = moment(
                            enteredDate,
                            dateFormatWithoutTime,
                          ).isValid();
                          if (isValid) {
                            setDeliveryDate(
                              DateString(enteredDate, dateFormat.split(" ")[0]),
                            );
                            setDeliveryDateError("");
                          } else {
                            setDeliveryDate("");
                            if (enteredDate.length > 0) {
                              setDeliveryDateError(
                                `Please select a date in the correct format (${
                                  dateFormat.replace("dd", "DD").split(" ")[0]
                                }).`,
                              );
                            } else {
                              setDeliveryDateError("");
                            }
                          }
                        }}
                      />
                      {deliveryDateError && (
                        <div className="error-message text-danger">
                          {deliveryDateError}
                        </div>
                      )}
                    </div>

                    <div className="col-6">
                      <label htmlFor="store">Select Store*</label>
                      <CustomAsyncSelect
                        componentKey={storeModalStatus ? 0 : 1}
                        customTheme={customTheme}
                        model={Store}
                        inputfield={"name"}
                        fieldName={"stores"}
                        isMultiSelect={false}
                        isNeedFullData={true}
                        isdisabled={
                          purchase_order_id
                            ? true
                            : purchaseOrderDetails
                            ? true
                            : false
                        }
                        // formData={inwardForPOFormData}
                        formData={
                          purchase_order_id ? inwardForPOFormData : formData
                        }
                        label={"Store"}
                        onChange={(selectedOption: any) => {
                          handelselectStore(selectedOption);
                          handleSelectInputChange(selectedOption, "stores");
                        }}
                        onChangeModalStatus={(status: boolean) => {
                          setStoreModalStatus(status);
                        }}
                        modalStatus={storeModalStatus}
                        widget={
                          <AddUpdateStore
                            title={STORE_TITLE}
                            listRoute={STORE_LIST}
                            isNotUpdate={true}
                            onChangeModalStatus={(status: boolean) =>
                              setStoreModalStatus(status)
                            }
                          />
                        }
                      />
                      {storeError && (
                        <div className="error-message text-danger">
                          {storeError}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row mb-2">
                    {/* <div className="col">
                      <label htmlFor="amendment">Amendment Number*</label>
                      <input
                        type="text"
                        className="w-48 form-control form-control-sm"
                        onChange={(e) => setAmendment(e.target.value)}
                        value={amendment}
                        disabled={purchase_order_id}
                      />
                      {amendmentError && (
                        <div className="error-message text-danger">
                          {amendmentError}
                        </div>
                      )}
                    </div> */}

                    {showOptionalFields && (
                      <div className="col">
                        <label htmlFor="purchaseordernumber">
                          Purchase Order Number
                        </label>
                        <CustomAsyncSelect
                          customTheme={customTheme}
                          model={PurchaseOrder}
                          inputfield={"document_number"}
                          fieldName={"purchase_order"}
                          isMultiSelect={false}
                          // formData={formData}
                          isNeedFullData={true}
                          formData={
                            purchase_order_id ? inwardForPOFormData : formData
                          }
                          onChange={(selectedOption: any) => {
                            handleSelectInputChange(
                              selectedOption,
                              "purchase_order",
                            );
                            fetchInwardData(selectedOption?.data.id);
                            setPODate(
                              DateString(
                                selectedOption.data.document_date,
                                dateFormat.split(" ")[0],
                              ),
                            );
                          }}
                          label={"purchaseOrder"}
                          // isdisabled={disablePonumber}
                          isdisabled={true}
                          addNewBtnStatus={false}
                        />
                      </div>
                    )}
                  </div>
                  {showOptionalFields && (
                    <>
                      <div className="row mb-2">
                        <div className="col d-flex flex-column">
                          <label htmlFor="purchaseorderdate">
                            Purchase Order Date
                          </label>
                          <DatePicker
                            selected={poDate}
                            placeholderText={dateFormat.replace("dd", "DD")}
                            disabled
                            onChange={() => {}}
                            dateFormat={
                              dateFormat?.replace("YYYY", "yyyy").split(" ")[0]
                            }
                            showYearDropdown
                            className="w-48 form-control form-control-sm"
                            onKeyDown={(e: any) => e.preventDefault()}
                          />
                        </div>

                        <div className="col">
                          <label htmlFor="vehiclenumber">Vehicle Number</label>
                          <input
                            type="text"
                            className="w-48 form-control form-control-sm"
                            value={vehicleNumber}
                            onChange={(e) => setVehicleNumber(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* <div className="row mb-2">
                        <div className="col">
                          <label htmlFor="taxinvoicenumber">
                            Invoice Number
                          </label>
                          <CustomAsyncSelect
                            customTheme={customTheme}
                            model={TaxInvoice}
                            inputfield={"document_number"}
                            fieldName={"tax_invoice_id"}
                            isMultiSelect={false}
                            formData={
                              purchase_order_id ? inwardForPOFormData : formData
                            }
                            label={"tax invoice"}
                            addNewBtnStatus={false}
                            onChange={(selectedOption: any) => {
                              handelselectTaxInvoice(selectedOption);
                              setTaxInvoiceDate(
                                DateString(
                                  selectedOption.data.document_date,
                                  dateFormat.split(" ")[0],
                                ),
                              );
                              setDeliveryDateError("");
                              handleSelectInputChange(
                                selectedOption,
                                "tax_invoice_id",
                              );
                            }}
                            isNeedFullData={true}
                          />
                        </div>
                        <div className="col d-flex flex-column">
                          <label htmlFor="TaxInvoicedate">Invoice Date</label>
                          <DatePicker
                            selected={taxInvoiceDate}
                            onChange={() => {}}
                            placeholderText={dateFormat.replace("dd", "DD")}
                            dateFormat={
                              dateFormat?.replace("YYYY", "yyyy").split(" ")[0]
                            }
                            showYearDropdown
                            className="form-control form-control-sm w-100"
                            onKeyDown={(e: any) => e.preventDefault()}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col">
                          <label htmlFor="deliverychallannumber">
                            Delivery Challan Number
                          </label>
                          <input
                            type="text"
                            className="w-48 form-control form-control-sm"
                            value={deliveryChallanNumber}
                            onChange={(e) =>
                              setDeliveryChallanNumber(e.target.value)
                            }
                          />
                        </div>
                        <div className="col d-flex flex-column">
                          <label htmlFor="deliverychallandate">
                            Delivery Challan Date
                          </label>
                          <DatePicker
                            selected={deliverychallanDate}
                            onChange={handleDeliveryChallanDateChange}
                            placeholderText={dateFormat.replace("dd", "DD")}
                            dateFormat={
                              dateFormat?.replace("YYYY", "yyyy").split(" ")[0]
                            }
                            showYearDropdown
                            className="form-control form-control-sm w-100"
                            onBlur={(e: any) => {
                              let enteredDate = e.target.value;
                              const dateFormatWithoutTime = dateFormat
                                .replace("dd", "DD")
                                .split(" ")[0];
                              enteredDate = enteredDate.replace(
                                /\b(\d{1})\b/g,
                                "0$1",
                              );
                              const isValid = moment(
                                enteredDate,
                                dateFormatWithoutTime,
                              ).isValid();
                              if (isValid) {
                                setDeliverychallanDate(
                                  DateString(
                                    enteredDate,
                                    dateFormat.split(" ")[0],
                                  ),
                                );
                                setDeliverychallanDateError("");
                              } else {
                                setDeliverychallanDate("");
                                if (enteredDate.length > 0) {
                                  setDeliverychallanDateError(
                                    `Please select a date in the correct format (${
                                      dateFormat
                                        .replace("dd", "DD")
                                        .split(" ")[0]
                                    }).`,
                                  );
                                } else {
                                  setDeliverychallanDateError("");
                                }
                              }
                            }}
                          />
                          {deliverychallanDateError && (
                            <div className="error-message text-danger">
                              {deliverychallanDateError}
                            </div>
                          )}
                        </div>
                      </div> */}
                      <div className="row mb-2">
                        <div className="col">
                          <label htmlFor="transportername">
                            Transporter Name
                          </label>
                          <input
                            type="text"
                            value={transporterName}
                            className="w-48 form-control form-control-sm"
                            onChange={(e) => setTransporterName(e.target.value)}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="transportationdocumentnumber">
                            Transportation Document Number
                          </label>
                          <input
                            type="text"
                            className="w-48 form-control form-control-sm"
                            value={transportationDocumentNumber}
                            onChange={(e) =>
                              setTransportationDocumentNumber(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col d-flex flex-column">
                          <label htmlFor="transportationdocumentdate">
                            Transportation Document Date
                          </label>
                          <DatePicker
                            selected={transportationDocumentDate}
                            onChange={handleTransportationDocDateChange}
                            placeholderText={dateFormat.replace("dd", "DD")}
                            dateFormat={
                              dateFormat?.replace("YYYY", "yyyy").split(" ")[0]
                            }
                            showYearDropdown
                            className="form-control form-control-sm w-100"
                            onBlur={(e: any) => {
                              let enteredDate = e.target.value;
                              const dateFormatWithoutTime = dateFormat
                                .replace("dd", "DD")
                                .split(" ")[0];
                              enteredDate = enteredDate.replace(
                                /\b(\d{1})\b/g,
                                "0$1",
                              );
                              const isValid = moment(
                                enteredDate,
                                dateFormatWithoutTime,
                              ).isValid();
                              if (isValid) {
                                setTransportationDocumentDate(
                                  DateString(
                                    enteredDate,
                                    dateFormat.split(" ")[0],
                                  ),
                                );
                                setTransportationDocumentDateError("");
                              } else {
                                setTransportationDocumentDate("");
                                if (enteredDate.length > 0) {
                                  setTransportationDocumentDateError(
                                    `Please select a date in the correct format (${
                                      dateFormat
                                        .replace("dd", "DD")
                                        .split(" ")[0]
                                    }).`,
                                  );
                                } else {
                                  setTransportationDocumentDateError("");
                                }
                              }
                            }}
                          />
                          {transportationDocumentDateError && (
                            <div className="error-message text-danger">
                              {transportationDocumentDateError}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </Card.Body>
              )}
            </Card>
          </Col>
        </Row>
      </div>
      <CreateRow
        FormTableFields={productTableFields}
        modifieldFormTableFields={modifieldFormTableFields}
        setCardsData={setProducts}
        cardsData={products}
        initializedProduct={productDetails}
        formErrors={productFormErrors}
        updateStatus={true}
        cardShowStatus={true}
        cardName="Product Details"
        setFormErrors={setProductFormErrors}
        addButtonStatus={!purchaseOrderDetails}
        // removeButtonStatus={false}
      />

      <Row className="mb-4">
        <Col>
          <Card className="mb-4">
            <Card.Header>
              <div className="d-flex align-items-center">
                <div className="btngroup">
                  <Button
                    type="button"
                    style={{ fontSize: "11px" }}
                    size={"sm"}
                    className={`btn me-2 btn-secondary rounded-pill ${
                      showAttachmentSignature ? "btn-success" : ""
                    }`}
                    onClick={handleToggleAttachmentSignature}
                  >
                    Attach Signature
                  </Button>
                  <Button
                    type="button"
                    style={{ fontSize: "11px" }}
                    size={"sm"}
                    className={`btn me-2 btn-secondary rounded-pill ${
                      showAttachment ? "btn-success" : ""
                    }`}
                    onClick={handleToggleAttachment}
                  >
                    Attachment
                  </Button>
                  <Button
                    type="button"
                    style={{ fontSize: "11px" }}
                    size={"sm"}
                    className={`btn me-2 btn-secondary rounded-pill ${
                      showComments ? "btn-success" : ""
                    }`}
                    onClick={handleToggleComments}
                  >
                    Add Comments
                  </Button>
                </div>
              </div>
            </Card.Header>
          </Card>
          <div className="row" style={{ marginLeft: "-8px" }}>
            <Card
              className="mb-2 mx-2 p-0"
              style={{
                width: "25%",
                fontSize: "small",
                display: showAttachmentSignature ? "block" : "none",
              }}
            >
              <Card.Header style={{ fontSize: "15px" }}>
                Attachment Signature
              </Card.Header>
              <Card.Body>
                <SignatureForDocuments
                  fieldType="inward_document_id"
                  moduleType="inward_document"
                  id={signatureId}
                  customSubmit={signaturecustomSubmit}
                  setSelectedSignatureFile={setSelectedSignatureFile}
                  attachmentSignatureError={attachmentSignatureError}
                  setIsSignatureExist={setIsSignatureExist}
                ></SignatureForDocuments>
              </Card.Body>
            </Card>
            <Card
              style={{
                display: showAttachment ? "block" : "none",
                width: "25%",
                fontSize: "small",
              }}
              className="mb-2 mx2 p-0 me-2"
              id="attachments"
            >
              <Card.Header style={{ fontSize: "15px" }}>
                Attachments
              </Card.Header>
              <Card.Body>
                <AttachmentsForDocuments
                  fieldType="inward_document_id"
                  moduleType="inward_document"
                  id={attachmentId}
                  customSubmit={customSubmit}
                  setAttchmentTitle={setAttchmentTitle}
                  setSelectedAttchmentFile={setSelectedAttchmentFile}
                  attachmentError={attachmentError}
                  attachError={attachError}
                  setIsAttchmentExist={setIsAttchmentExist}
                ></AttachmentsForDocuments>
              </Card.Body>
            </Card>
            <Card
              style={{
                display: showComments ? "block" : "none",
                fontSize: "small",
                width: "47.5%",
              }}
              className="mb-2 mx2 p-0"
              id="comments"
            >
              <Card.Header style={{ fontSize: "15px" }}>Comments</Card.Header>
              <Card.Body>
                <Form.Group controlId="comments">
                  <Form.Control
                    as="textarea"
                    size="sm"
                    rows={4}
                    placeholder="Enter your comments here"
                    onChange={(e) => setItemComments(e.target.value)}
                    value={itemComments}
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
      {/* <div className="mb-4 d-flex justify-content-end">
        <CustomButton
          type="submit"
          label={"Submit"}
          variant="primary"
          onClick={(e) => handleSubmit(e)}
          disableStatus={purchase_order_id && loadingForPrimaryDocumentDetails}
          size={"sm"}
        />
      </div> */}
      <div className="mb-4 d-flex justify-content-end">
        <span>
          <CustomButton
            type="submit"
            label={"SAVE DRAFT"}
            variant="secondary"
            onClick={(e) => submit(e, "draft")}
            size="sm"
            customeStyle={{ width: "150px", padding: "5px", fontWeight: "500" }}
            customeClass={"text-light"}
            disableStatus={
              (status && status === "sent") ||
              ((id || purchase_order_id) && loadingForPrimaryDocumentDetails) ||
              submitting
            }
            spinnerStatus={submitting && submitStatus === "draft"}
          />
        </span>

        <span>
          <CustomButton
            type="submit"
            label={"SAVE"}
            variant="primary"
            onClick={(e) => submit(e, "sent")}
            size="sm"
            customeStyle={{ width: "150px", padding: "5px", fontWeight: "500" }}
            customeClass={"text-light"}
            disableStatus={
              (status && status === "sent") ||
              ((id || purchase_order_id) && loadingForPrimaryDocumentDetails) ||
              submitting
            }
            spinnerStatus={submitting && submitStatus === "sent"}
          />
        </span>
      </div>
      <ConfirmationModal
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        title="Confirmation"
        message="Are you sure you want to go back?"
        onConfirm={handleConfirmation}
        confirmButtonColor="primary"
      />
      <ConfirmationModal
        show={showConfirmationForSendData}
        onHide={() => setShowConfirmationForSendData(false)}
        title="Confirmation"
        message="Are you sure you want to send?"
        onConfirm={handleConfirmationForSendData}
        confirmButtonColor="primary"
        spinnerStatus={submitting ? submitting : false}
      />
    </>
  );
};

export default AddUpdate;
